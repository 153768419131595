import Wrapper from './UsersHeaders/Wrapper';
import Table from './Table';

export default function () {
  return (
    <Wrapper>
      <div className="flex">
        <div className="overflow-hidden w-full">
          <Table />
        </div>
      </div>
    </Wrapper>
  );
}
