import { axiosObject } from '../setup';
import baseValidator from '../baseValidator';

const constraints = {
  otp: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your otp',
    },
    length: {
      minimum: 6,
      message: '^Invalid OTP. Please try again',
    },
  },
};

function verifyAttribute(payload) {
  return new Promise((resolve, reject) => {
    var jsValidationErrors;

    if (payload['mode'] === 'mobile') {
      payload['username'] =
        payload['mobile_isd_code'] + payload['mobile_number'];
    }

    console.log('/verify-attribute', payload);

    jsValidationErrors = baseValidator(payload, constraints);
    if (jsValidationErrors === true) {
      let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/verify-attribute`;
      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(payload);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { verifyAttribute };
