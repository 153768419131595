import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter name',
    },
  },
};

function createCategory(payload) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    jsValidationErrors = baseValidator(payload, constraints);

    if (jsValidationErrors === true) {
      let uri = '/backend-address-book/categories';

      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }

          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { createCategory };
