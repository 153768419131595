export default function formatDate(dateObj, format = 'dmy') {
    let year = dateObj.getFullYear();
    let month = (1 + dateObj.getMonth()).toString().padStart(2, '0');
    let day = dateObj.getDate().toString().padStart(2, '0');
  
    if (format === 'dmy') {
      return day + '-' + month + '-' + year;
    }
  
    if (format === 'ymd') {
      return year + '-' + month + '-' + day;
    }
  
    if (format === 'dMy') {
      const monthName = dateObj
        .toLocaleString('default', { month: 'long' })
        .split(' ')[1];
      return day + ' ' + monthName + ' ' + year;
    }
    if (format === 'dM') {
      const monthName = dateObj
        .toLocaleString('default', { month: 'long' })
        .split(' ')[1];
      return day + ' ' + monthName;
    }
  }
  