/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();
  const { accessToken } = useSelector(
    ({ auth }) => ({
      accessToken: auth.accessToken,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (accessToken) {
      return navigate('/users');
    } else {
      return navigate('/login');
    }
  }, [accessToken]);

  return (
    <div className="flex items-center justify-center text-4xl">Dashboard</div>
  );
}
