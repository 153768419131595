import TopHeader from './TopHeader';
import SecondaryHeader from './SecondaryHeader';

export default function (props) {
  return (
    <div>
      <TopHeader topBarActiveOption={props.topBarActiveOption} />
      {/* <SecondaryHeader active={props.active} /> */}
      {props.children}
    </div>
  );
}
