import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const constraints = {
  response: {
    presence: {
      allowEmpty: false,
      message: '^Please Enter Your Response',
    },
  },
};

function createResponse(payload) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    jsValidationErrors = baseValidator(payload, constraints);

    if (jsValidationErrors === true) {
      let uri = '/backend-ask-anything/response';
      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { createResponse };
