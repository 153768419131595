import { axiosObject } from '../setup';

function user(data) {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        let result = await callBackend(data);
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })();
  });
}

function callBackend(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/user`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', errors);
        reject(errors);
      });
  });
}

export { user };
