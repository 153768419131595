export const getPostFixDateString = (item) => {
  if (new Date(item?.created_at).getDate() === 1) {
    return 'st';
  } else if (new Date(item?.created_at).getDate() === 2) {
    return 'nd';
  } else if (new Date(item?.created_at).getDate() === 3) {
    return 'rd';
  } else {
    return 'th';
  }
};
