import { createSlice } from '@reduxjs/toolkit';

export const queriesSlice = createSlice({
  name: 'fmQueries',
  initialState: {
    refinance: {
      type: 'refinance_existing_loan',
      data: {},
      sellers: {
        addressbook_contacts: [
          {
            email: '',
            mobile: '',
          },
          {
            email: '',
            mobile: '',
          },
        ],
        buyer_selected_sellers: [],
        system_selected_sellers: {
          country: '',
          city: '',
        },
      },
      status: 'open',
    },
    corporateFinanceProducts: {
      uuid: '',
      type: '',
      data: {},
      status: '',
      sellers: {
        addressbook_contacts: [
          {
            email: '',
            mobile: '',
          },
          {
            email: '',
            mobile: '',
          },
        ],
        buyer_selected_sellers: [],
        system_selected_sellers: {
          country: '',
          city: '',
        },
      },
    },
  },
  reducers: {
    setRefinance: (state, action) => {
      state.refinance.uuid = action.payload.uuid;
      state.refinance.type = action.payload.type;
      state.refinance.data = action.payload.data;
      state.refinance.status = action.payload.status;
      state.refinance.sellers = action.payload.sellers;
    },
    setCorporateFinanceProducts: (state, action) => {
      state.corporateFinanceProducts.uuid = action.payload.uuid;
      state.corporateFinanceProducts.type = action.payload.type;
      state.corporateFinanceProducts.data = action.payload.data;
      state.corporateFinanceProducts.status = action.payload.status;
      state.corporateFinanceProducts.sellers = action.payload.sellers;
    },
  },
});

export const {
  setRefinance,
  setCorporateFinanceProducts,
} = queriesSlice.actions;
export default queriesSlice.reducer;
