import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// Reducers
import authReducer from './slices/authSlice.js';
import registeredUsersReducer from './slices/registeredUsersSlice.js';
import leadsReducer from './slices/leadsSlice';

let reducers = combineReducers({
  auth: authReducer,
  registeredUsers: registeredUsersReducer,
  leads: leadsReducer,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'registeredUsers'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

let webStore = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
let webPersistor = persistStore(webStore);

export { webStore, webPersistor };
