import { useState, useEffect } from 'react';
import Wrapper from '../MentorShipHeaders/Wrapper';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { getMentorshipsByUuid, getFile } from '@nx-monorepo/services';

function ViewCouncellor() {
  const [details, setDetails] = useState(null);
  const [certificateLinks, setCertificateLinks] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const getSingleMentorshipDetails = async () => {
    try {
      let response = await getMentorshipsByUuid(location?.state?.itemUuid);
      setDetails(response);
      if (response?.data?.professional_certificates) {
        response?.data?.professional_certificates.map(async (link) => {
          let obj = {};
          let response = await getFile(link);
          obj.filename = response?.file_name;
          obj.link = response?.download_url;
          setCertificateLinks((prevState) => [...prevState, obj]);
        });
      } else {
        setCertificateLinks([]);
      }
    } catch (error) {
      console.log('error in getting-single-metorship-details', error);
    }
  };

  useEffect(() => {
    getSingleMentorshipDetails();
  }, []);

  return (
    <Wrapper topBarActiveOption={'Events'} active="councellors">
      <div class="mx-5 mt-10 mb-10 overflow-hidden rounded-lg border bg-white shadow sm:rounded-lg">
        <div class="flex justify-between pr-5">
          <div className="px-4 py-5 sm:px-6">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Professional Counsellor
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Professional counsellor details and Information.
            </p>
          </div>
          <div
            className="flex cursor-pointer flex-col items-center justify-center"
            onClick={() => navigate('/professional-counsellors')}
          >
            <ArrowNarrowLeftIcon className="h-5 w-5" />
            <p>Back</p>
          </div>
        </div>
        <div class="border-t border-gray-200">
          <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Event Topic</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.topic}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Event Type</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.type}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Counsellor Name</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.name}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Counsellor Date</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.date}
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Counsellor Fee</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.counseller_fee}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                About Counsellor
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.counseller_about_you}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Company</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.counseller_company_name}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Designation</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.counseller_designation}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Status</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.status}
              </dd>
            </div>

            <div className="border-t border-gray-200">
              <dl>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Timings</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {details?.data?.date_and_time ? (
                      details?.data?.date_and_time.map((item, index) => {
                        return (
                          <div className="flex flex-col pb-3" key={index}>
                            <p>Date: {item.date}</p>
                            <p>Time: {item.time}</p>
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex flex-col pb-3">
                        <p>Date: N/A</p>
                        <p>Time: N/A</p>
                      </div>
                    )}
                  </dd>
                </div>
              </dl>
            </div>

            <div className="border-t border-gray-200">
              <dl>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Professional Certificates
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {certificateLinks.length > 0 ? (
                      certificateLinks.map((item, index) => {
                        console.log('item88888', item);
                        return (
                          <div key={index}>
                            <a
                              href={item.link}
                              className="font-medium text-blue-700"
                            >
                              {item.filename}
                            </a>
                          </div>
                        );
                      })
                    ) : (
                      <div>N/A</div>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </dl>
        </div>
      </div>
    </Wrapper>
  );
}

export default ViewCouncellor;
