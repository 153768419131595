import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const emailConstraints = {
  username: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your Email ID',
    },
    email: {
      message: '^Please enter a valid Email ID',
    },
  },
};

const mobileConstraints = {
  mobile_number: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your mobile number',
    },
  },

  username: {
    format: {
      //regex to match 8-10 numbers
      pattern:
        '^(\\+\\d{1,3}( )?)?((\\(\\d{1,3}\\))|\\d{1,3})[- .]?\\d{3,4}[- .]?\\d{4}$',
      message: '^Please enter valid mobile number',
    },
  },
};

function updateAttribute(payload) {
  return new Promise((resolve, reject) => {
    var jsValidationErrors;
    var apiPayload = {};

    if (payload['mode'] === 'mobile') {
      payload['username'] =
        payload['mobile_isd_code'] + payload['mobile_number'];

      jsValidationErrors = baseValidator(payload, mobileConstraints);

      apiPayload = payload;
    }

    if (payload['mode'] === 'email') {
      jsValidationErrors = baseValidator(payload, emailConstraints);
      apiPayload = payload;
    }

    if (jsValidationErrors === true) {
      let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/update-attribute`;
      console.log("'/update-attribute'", apiPayload);

      axiosObject
        .post(uri, apiPayload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      if (Object.keys(jsValidationErrors).includes('mobile_number')) {
        jsValidationErrors['username'] = jsValidationErrors['mobile_number'];
      }

      reject(jsValidationErrors);
    }
  });
}

export { updateAttribute };
