function getOnboardingNavigationScreen({
  profileType,
  profileStatus = 'starting',
}) {
  const fmSellerScreens = {
    starting: 'FMSellerOnboardingStepOne',
    step_1: 'FMSellerOnboardingStepTwo',
    step_2: 'FMSellerOnboardingStepThree',
    step_3: 'FMSellerOnboardingStepFour',
    step_4: 'SelectedProducts',
    step_5: 'FMSellerOnboardingStepFive',
    completed: 'FMSellerDashboard',
  };
  const fmBuyerScreens = {
    starting: 'FMBuyerOnboardingStepOne',
    step_1: 'FMBuyerOnboardingStepTwo',
    step_2: 'FMBuyerOnboardingStepTwo',
    completed: 'Dashboard',
  };
  const screens = {
    'fm-seller': fmSellerScreens,
    'fm-buyer': fmBuyerScreens,
  };
  const selectedScreen = screens[profileType];
  if (!selectedScreen || !selectedScreen[profileStatus])
    profileStatus = 'starting';

  if (selectedScreen) {
    const result = selectedScreen[profileStatus];
    return result;
  } else return '';
}

export { getOnboardingNavigationScreen };
