const filterOptions = (options, query) => {
  return options.filter(
    (option) => option.label.toLowerCase().indexOf(query.toLowerCase()) > -1
  );
};

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export { filterOptions, capitalizeFirstLetter };
