/* eslint-disable no-labels */
import { Base as DataTable } from '@reusejs/react-tables';

function EmptyState({ tableHeadColumns = [], noDataText }) {
  return (
    <DataTable
      config={{
        tableWrapperClasses: 'w-full h-96 border rounded-lg',
        tableClasses: 'w-full divide-y divide-white',
        headerClasses: 'relative px-6 py-4',
        rowEvenClasses: 'bg-gray-100',
        filterable: false,
        columns: tableHeadColumns,
      }}
      defaultSortColumn={0}
      noDataComponent={<div>{noDataText ? noDataText : `No Data`}</div>}
      dataSource={() => {}}
    />
  );
}

export default EmptyState;
