import { useState, useEffect } from 'react';
import { uuidv4 } from '@reusejs/react-utils';

export default function (
  initialItems = [],
  itemTemplate = {},
  eventListener = () => {},
  validatorConfig = {}
) {
  const [items, setItems] = useState(initialItems);

  const addNewItem = (item) => {
    let newItem = {};
    if (item) {
      newItem = {
        ...item,
      };
      setItems((currentItems) => {
        return currentItems.concat([newItem]);
      });
    } else {
      newItem = {
        uuid: uuidv4(),
        ...itemTemplate,
      };
      setItems((currentItems) => {
        return currentItems.concat([newItem]);
      });
    }

    eventListener('add.item', newItem);
  };

  const removeItem = (uuid) => {
    setItems((currentItems) => {
      return currentItems.filter((item) => item.uuid !== uuid);
    });
    eventListener('removed.item', uuid);
  };

  const updateItem = (uuid, key, value) => {
    setItems((currentItems) => {
      currentItems.forEach((item, index) => {
        if (item.uuid === uuid) {
          // Call validator, set error texts
          item[key] = value;
          currentItems[index] = item;
        }
      });

      return currentItems;
    });

    eventListener('updated.item', uuid);
  };

  return {
    items,
    setItems,
    addNewItem,
    removeItem,
    updateItem,
  };
}
