import { axiosObject } from '../setup';

function getAllTradesOfTheDay(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/trades-of-the-day/read`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function createTradeOfTheDay(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/trades-of-the-day/create`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response?.data?.statusCode === 409) {
          reject(errors.response.data);
        }
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function updateTradeOfTheDay(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/trades-of-the-day/update`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function deleteTradeOfTheDay(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/trades-of-the-day/delete/${uuid}`;

    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  getAllTradesOfTheDay,
  createTradeOfTheDay,
  updateTradeOfTheDay,
  deleteTradeOfTheDay,
};
