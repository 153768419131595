import { createSlice } from '@reduxjs/toolkit';
// import { deregisterFirebaseToken } from '@nx-monorepo/services';

export const leadsSlice = createSlice({
  name: 'leads',
  initialState: {
    leadsLength: null,
  },
  reducers: {
    setLeadsLength: (state, action) => {
      state.leadsLength = action.payload;
    },
  },
});

export const { setLeadsLength } = leadsSlice.actions;

export default leadsSlice.reducer;
