import { axiosObject } from '../setup';

function getContacts({
  perPage = 10,
  page = 1,
  search = '',
  categoryUuid = '',
}) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-address-book/contacts';

    let params = {
      per_page: perPage,
      page: page,
      search: search,
    };

    if (categoryUuid) {
      params['category_uuid'] = categoryUuid;
    }

    axiosObject
      .get(uri, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getContact = ({ contactUuid }) => {
  return new Promise((resolve, reject) => {
    let uri = `/backend-address-book/contacts/${contactUuid}`;
    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getContacts, getContact };
