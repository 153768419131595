const rangeInr = [
  // In Crores
  { label: '<25', value: '<25', min_value: '0', max_value: '250000000' },
  {
    label: '25-100',
    value: '25-100',
    min_value: '250000000',
    max_value: '1000000000',
  },
  {
    label: '101-250',
    value: '101-250',
    min_value: '1010000000',
    max_value: '2500000000',
  },
  {
    label: '251-500',
    value: '251-500',
    min_value: '2510000000',
    max_value: '5000000000',
  },
  {
    label: '501-750',
    value: '501-750',
    min_value: '5010000000',
    max_value: '7500000000',
  },
  {
    label: '751-1500',
    value: '751-1500',
    min_value: '7510000000',
    max_value: '15000000000',
  },
  {
    label: '1501-2500',
    value: '1501-2500',
    min_value: '15010000000',
    max_value: '25000000000',
  },
  {
    label: '2501-5000',
    value: '2501-5000',
    min_value: '25010000000',
    max_value: '50000000000',
  },
  {
    label: '5001-7500',
    value: '5001-7500',
    min_value: '50010000000',
    max_value: '75000000000',
  },
  {
    label: '7501-20000',
    value: '7501-20000',
    min_value: '75010000000',
    max_value: '200000000000',
  },
  {
    label: '20001-40000',
    value: '20001-40000',
    min_value: '200010000000',
    max_value: '4000000000000',
  },
  {
    label: '>400000',
    value: '>400000',
    min_value: '4000000000000',
    max_value: '4000000000000',
  },
];
const rangeNonInr = [
  // In Millions
  {
    label: '< 5 ',
    value: '< 5 ',
    min_value: '0',
    max_value: '5000000',
  },
  {
    label: '5-10',
    value: '5-10',
    min_value: '5000000',
    max_value: '10000000',
  },
  {
    label: '11-25',
    value: '11-25',
    min_value: '11000000',
    max_value: '25000000',
  },
  {
    label: '26-50',
    value: '26-50',
    min_value: '26000000',
    max_value: '50000000',
  },
  {
    label: '51-100',
    value: '51-100',
    min_value: '51000000',
    max_value: '100000000',
  },
  {
    label: '101-200',
    value: '101-200',
    min_value: '101000000',
    max_value: '200000000',
  },
  {
    label: '201-350',
    value: '201-350',
    min_value: '201000000',
    max_value: '350000000',
  },
  {
    label: '351-500',
    value: '351-500',

    min_value: '351000000',
    max_value: '500000000',
  },
  {
    label: '501-750',
    value: '501-750',
    min_value: '501000000',
    max_value: '750000000',
  },
  {
    label: '751-1000',
    value: '751-1000',
    min_value: '751000000',
    max_value: '1000000000',
  },
  {
    label: '> 1 Billion',
    value: '> 1 Billion',
    min_value: '1000000000',
    max_value: '1000000000',
  },
];

function getTurnoverRanges(currency) {
  if (currency === 'INR') return rangeInr;
  else return rangeNonInr;
}

export { getTurnoverRanges };
