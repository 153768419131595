import TopHeader from './TopHeader';

export default function (props) {
  return (
    <div>
      <TopHeader
        topBarActiveOption={props.topBarActiveOption}
        breadCrumbsProp={props.breadCrumbsProp}
      />
      {props.children}
    </div>
  );
}
