import * as axios from 'axios';
import { mobileStore, webStore } from '@nx-monorepo/store';
import { Simple as SimpleAlert } from '@reusejs/react-alerts';
var axiosObject = axios.create();

if (process.env.NX_APP_TYPE !== 'web') {
  console.log('not web app');
  import('react-native-config').then((Config) => {
    axiosObject.defaults.baseURL = Config.API_URL;
  });

  axiosObject.defaults.headers.common = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  axiosObject.interceptors.request.use(
    async function (config) {
      console.log(
        'LOG-Request:',
        `${config.method} - ${config.baseURL}${config.url
        }?${new URLSearchParams(config.params).toString()}`
      );


      if (mobileStore.getState().auth.accessToken) {
        config.headers['AccessToken'] = `${mobileStore.getState().auth.accessToken
          }`;
        config.headers['IdToken'] = `${mobileStore.getState().auth.idToken}`;
      } else {
        console.log('Unauthenticated Request');
      }

      return config;
    },
    function (error) {
      console.log('intercept error', error);
      return Promise.reject(error);
    }
  );

  axiosObject.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
} else {
  console.log('web app', process.env.NX_API_URL);

  axiosObject.defaults.baseURL = process.env.NX_API_URL;

  axiosObject.defaults.headers.common = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  axiosObject.interceptors.request.use(
    async function (config) {

      config.headers['Authorization'] = `Bearer ${webStore.getState().auth.accessToken}`

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axiosObject.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.message.includes('500')) {
        SimpleAlert({
          message: 'Server Error',
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      } else if (error.message.includes('403')) {
        localStorage.clear();
      }
      return Promise.reject(error);
    }
  );
}

export { axiosObject };
