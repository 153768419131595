import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const mentorConstraints = {
  mentor_topic: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Topic',
    },
  },

  fee: {
    presence: {
      allowEmpty: false,
      message: '^Please enter fee',
    },
  },
  company_name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter company name',
    },
  },
  date: {
    presence: {
      allowEmpty: false,
      message: '^Please enter Date',
    },
  },
  time: {
    presence: {
      allowEmpty: false,
      message: '^Please enter time',
    },
  },
  designation: {
    presence: {
      allowEmpty: false,
      message: '^Please enter designation',
    },
  },

  about: {
    presence: {
      allowEmpty: false,
      message: '^Please enter about you',
    },
  },
};

const menteeconstraints = {
  mentee_topic: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Topic',
    },
  },

  type_of_mentoring: {
    presence: {
      allowEmpty: false,
      message: '^Please Select type of mentoring',
    },
  },
  mentee_date: {
    presence: {
      allowEmpty: false,
      message: '^Please enter date',
    },
  },
};

const consellerConstraints = {
  counseller_topic: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Topic',
    },
  },

  counseller_fee: {
    presence: {
      allowEmpty: false,
      message: '^Please enter fee',
    },
  },
  counseller_company_name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter company name',
    },
  },
  counseller_designation: {
    presence: {
      allowEmpty: false,
      message: '^Please enter designation',
    },
  },
  date: {
    presence: {
      allowEmpty: false,
      message: '^Please enter Date',
    },
  },
  time: {
    presence: {
      allowEmpty: false,
      message: '^Please enter time',
    },
  },
  counseller_about_you: {
    presence: {
      allowEmpty: false,
      message: '^Please enter about you',
    },
  },
};

function createMentorship(payload) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    if (payload.type === 'mentor') {
      jsValidationErrors = baseValidator(
        {
          ...payload.data,
          mentor_topic: payload.topic,
        },
        mentorConstraints
      );
    }
    if (payload.type === 'mentee') {
      jsValidationErrors = baseValidator(
        {
          ...payload.data,
          mentee_topic: payload.topic,
        },
        menteeconstraints
      );
    }
    if (payload.type === 'professional_counseller') {
      jsValidationErrors = baseValidator(
        {
          ...payload.data,
          counseller_topic: payload.topic,
        },
        consellerConstraints
      );
    }

    if (jsValidationErrors === true) {
      let uri = '/backend-learn-earn/mentorships';

      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}
function updateMentorship(payload, mentorshipUuid) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    if (payload.type === 'mentor') {
      jsValidationErrors = baseValidator(
        {
          ...payload.data,
          mentor_topic: payload.topic,
        },
        mentorConstraints
      );
    }
    if (payload.type === 'mentee') {
      jsValidationErrors = baseValidator(
        {
          ...payload.data,
          mentee_topic: payload.topic,
        },
        menteeconstraints
      );
    }
    if (payload.type === 'professional_counseller') {
      jsValidationErrors = baseValidator(
        {
          ...payload.data,
          counseller_topic: payload.topic,
        },
        consellerConstraints
      );
    }

    if (jsValidationErrors === true) {
      let uri = `/backend-learn-earn/mentorships/${mentorshipUuid}`;

      axiosObject
        .put(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function getMentorships(params) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/mentorships';

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function updateMentorshipStatus(id, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/mentorships/${id}`;
    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function getMentorMentee(type) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/all-mentorships';

    let params = {
      type: type,
    };

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getMentorshipsByUuid(mentorshipUuid) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/mentorships/${mentorshipUuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  createMentorship,
  getMentorships,
  getMentorMentee,
  getMentorshipsByUuid,
  updateMentorship,
  updateMentorshipStatus,
};
