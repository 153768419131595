import { axiosObject } from '../setup';
import baseValidator from '../baseValidator';

const constraints = {
  password: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your password',
    },
  },
  confirm_password: {
    presence: {
      allowEmpty: false,
      message: '^Please re-enter password',
    },
    equality: 'password',
  },
};

function resetPassword(payload) {
  return new Promise((resolve, reject) => {
    //var apiPayload = {};
    let jsValidationErrors;
    jsValidationErrors = baseValidator(payload, constraints);

    const apiPayload = {
      mode: payload['mode'],
      username: payload['username'],
      otp: payload['otp'],
      password: payload['password'],
    };

    if (jsValidationErrors === true) {
      let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/reset-password`;

      axiosObject
        .post(uri, apiPayload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}
export { resetPassword };
