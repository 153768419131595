import { useState, useEffect } from 'react';
import Wrapper from '../MentorShipHeaders/Wrapper';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { getMentorshipsByUuid } from '@nx-monorepo/services';

function ViewMentor() {
  const [details, setDetails] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const getSingleMentorshipDetails = async () => {
    try {
      let response = await getMentorshipsByUuid(location?.state?.itemUuid);
      setDetails(response);
    } catch (error) {
      console.log('error in getting-single-metorship-details', error);
    }
  };

  useEffect(() => {
    getSingleMentorshipDetails();
  }, []);

  return (
    <Wrapper topBarActiveOption={'Events'} active="mentors">
      <div class="mx-5 mt-10 mb-10 overflow-hidden rounded-lg border bg-white shadow sm:rounded-lg">
        <div class="flex justify-between pr-5">
          <div className="px-4 py-5 sm:px-6">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Mentor</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Mentor details and Information.
            </p>
          </div>
          <div
            className="flex cursor-pointer flex-col items-center justify-center"
            onClick={() => navigate('/mentors')}
          >
            <ArrowNarrowLeftIcon className="h-5 w-5" />
            <p>Back</p>
          </div>
        </div>
        <div class="border-t border-gray-200">
          <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Event Topic</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.topic ? details?.topic : '-'}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Event Type</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.type ? details?.type : '-'}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Mentor Name</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.name ? details?.data?.name : '-'}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Mentor Date</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.date ? details?.data?.date : '-'}
              </dd>
            </div>

            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Mentor Fee</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.fee ? details?.data?.fee : '-'}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">About Mentor</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.about ? details?.data?.about : '-'}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Company</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.company_name
                  ? details?.data?.company_name
                  : '-'}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Designation</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.data?.designation ? details?.data?.designation : '-'}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Status</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {details?.status ? details?.status : '-'}
              </dd>
            </div>

            <div className="border-t border-gray-200">
              <dl>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Timings</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {details?.data?.date_and_time ? (
                      details?.data?.date_and_time.map((item) => {
                        return (
                          <div className="flex flex-col pb-3">
                            <p>Date: {item.date}</p>
                            <p>Time: {item.time}</p>
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex flex-col pb-3">
                        <p>Date: N/A</p>
                        <p>Time: N/A</p>
                      </div>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </dl>
        </div>
      </div>
    </Wrapper>
  );
}

export default ViewMentor;
