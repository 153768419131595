import React, { useState } from 'react';
import Wrapper from './BorrowingCostRatesHeader/Wrapper';
import { useNavigate, useParams } from 'react-router-dom';

export default function () {
  let { uuid } = useParams();
  const [mode, setMode] = useState(uuid === undefined ? 'list' : 'single');
  const [action, setAction] = useState('view');

  const [iframeDimensions, setIframeDimensions] = React.useState({
    height: 0,
    width: 0,
  });

  React.useEffect(() => {
    const div = document.getElementById('iframe-container');
    if (div) {
      if (div?.clientHeight && div?.clientWidth) {
        setIframeDimensions({
          height: div.clientHeight,
          width: div.clientWidth,
        });
      }
    }
  }, []);

  return (
    <Wrapper topBarActiveOption={'Borrowing_Cost_Rates'}>
      <div id="iframe-container" className="h-full w-[98%] px-1">
        <iframe
          src={
            'https://docs.google.com/spreadsheets/d/1yEQi2GmW0_lu80UJbUaNnU2UZz837m4ZEaPPszU6gmw/edit#gid=744533265'
          }
          height={iframeDimensions.height || 600}
          width={iframeDimensions.width || 670}
        />
      </div>
    </Wrapper>
  );
}
