import React from 'react';
import { SwitchHorizontalIcon } from '@heroicons/react/solid';
import { classNames } from '@reusejs/react-utils';
import { BlueButton } from '@reusejs/react-buttons';
import {
  publishBorrowingRates,
  publishBorrowingRatesStaging,
} from '@nx-monorepo/services';

const TopHeader = () => {
  const [busy, setBusy] = React.useState(false);

  const handlePublishingRates = async () => {
    try {
      setBusy(true);
      const response = await publishBorrowingRates();
      if (response) {
        alert('New Rates Published to Production');
      }
    } catch (error) {
      console.log(
        'Error while Publishing Borrowing Cost Rates! Please Trying Again.',
        error
      );
      alert(
        'Error while Publishing Borrowing Cost Rates! Please Trying Again.'
      );
    } finally {
      setBusy(false);
    }
  };

  const handlePublishingRatesStaging = async () => {
    try {
      setBusy(true);
      const response = await publishBorrowingRatesStaging();
      if (response) {
        alert('New Rates Published to Staging');
      }
    } catch (error) {
      console.log(
        'Error while Publishing Borrowing Cost Rates! Please Trying Again.',
        error
      );
      alert(
        'Error while Publishing Borrowing Cost Rates! Please Trying Again.'
      );
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className="z-0 flex w-full items-center border-b p-0">
      <div
        className={classNames(
          'pointer-events-none relative flex w-64 items-center border-r bg-white !py-6 !pl-6 text-lg text-gray-900'
        )}
      >
        Borrowing Cost Rates
        <SwitchHorizontalIcon
          className={classNames('ml-3 h-4 w-4 text-blue-500')}
        />
      </div>
      <BlueButton
        busy={busy}
        widthClasses="w-56 ml-4"
        fontClasses="text-sm font-semibold"
        borderClasses="rounded-lg"
        paddingClasses="px-4 py-3"
        busyText="Processing"
        onClick={handlePublishingRates}
      >
        Publish Rates(Production)
      </BlueButton>
      <BlueButton
        busy={busy}
        widthClasses="w-56 ml-4"
        fontClasses="text-sm font-semibold"
        borderClasses="rounded-lg"
        paddingClasses="px-4 py-3"
        busyText="Processing"
        onClick={handlePublishingRatesStaging}
      >
        Publish Rates(Staging)
      </BlueButton>
    </div>
  );
};

export default TopHeader;
