import React, { useRef } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import SectionLoader from '../components/reusables/SectionLoader';
import EmptyState from '../components/reusables/EmptyState';
import { getAllProducts } from '@nx-monorepo/services';

const Data = ({ response }) => {
  const tableRef = useRef(null);

  const fetchProducts = async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let _response = response.data;
        resolve(_response);
      } catch (error) {
        reject(error);
      }
    });
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden mx-5 border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Product Name',
        identifier: 'productname',
        resolver: (d) => {
          return <div>{d?.name}</div>;
        },
        sortable: false,
      },
      {
        label: 'Category Name',
        identifier: 'categories',
        resolver: (d) => {
          return <div>{d?.categories[0]?.name}</div>;
        },
        sortable: false,
      },
    ],
  };

  return (
    <div className="mt-10">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async (params) => {
          try {
            let response = await fetchProducts();
            return {
              data: response,
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function () {
  const orchestratorRef = useRef(null);

  const handler = async () => {
    try {
      let result = await getAllProducts();
      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }
      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  const emptyConfigHeaderCols = [
    {
      label: 'Product Name',
      identifier: 'productname',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Category Name',
      identifier: 'categories',
      resolver: (d) => {},
      sortable: false,
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data {...props} />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
}
