/* eslint-disable no-labels */
import React, { useState, useEffect } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { getEventByUuid } from '@nx-monorepo/services';
import SingleEvent from './SingleEventView';

function EmptyState({ tableHeadColumns = [], noDataText, eventId }) {
  const [eventDetails, setEventDetails] = useState({});
  const [eventProfilePic, setEventProfilePic] = useState(null);

  const getEventDetails = async () => {
    let response = await getEventByUuid(eventId);
    let eventObj = {
      'Event Name': response?.data?.name ? response?.data?.name : '-',
      'Event Type': response?.type ? response?.type : '-',
      'Advance Fee': response?.data?.advance_fee
        ? response?.data?.advance_fee
        : '-',
      Description: response?.data?.description
        ? response?.data?.description
        : '-',
      'Speaker Name': response?.data?.speaker_name
        ? response?.data?.speaker_name
        : '-',
      'About Speaker': response?.data?.about_speaker
        ? response?.data?.about_speaker
        : '-',
      'Company Name': response?.data?.company_name
        ? response?.data?.company_name
        : '-',
      Designation: response?.data?.designation
        ? response?.data?.designation
        : '-',
      'Ticket Price': response?.data?.ticket_price
        ? response?.data?.ticket_price
        : '-',
    };
    setEventDetails(eventObj);
    setEventProfilePic(response?.data?.profile_picture);
  };

  useEffect(() => {
    getEventDetails();
  }, []);
  return (
    <div>
      <SingleEvent eventDetails={eventDetails} />
      <div className="mx-4 mt-10 rounded-tl-lg rounded-tr-lg border-t border-r border-l border-gray-200 px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Registered Users
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          List of registered users for this Event.
        </p>
      </div>
      <div className="mx-6 pb-10">
        <DataTable
          config={{
            tableWrapperClasses: 'w-full h-96 border rounded-bl-lg rounded-br-lg',
            tableClasses: 'w-full divide-y divide-white',
            headerClasses: 'relative px-6 py-4',
            rowEvenClasses: 'bg-gray-100',
            filterable: false,
            columns: tableHeadColumns,
          }}
          defaultSortColumn={0}
          noDataComponent={<div>{noDataText ? noDataText : `No Data`}</div>}
          dataSource={() => {}}
        />
      </div>
    </div>
  );
}

export default EmptyState;
