import { createSlice } from '@reduxjs/toolkit';
// import { deregisterFirebaseToken } from '@nx-monorepo/services';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: '',
    refreshToken: '',
    expiredIn: '',
    currentapp: '',
    isUserLoggedIn: false
  },
  reducers: {
    setUser: (state, action) => {
      // console.log('check here payload:', action.payload);
      state.user = action.payload;
    },

    setUserLoggedIn: (state, action) => {
      state.isUserLoggedIn = action.payload;
    },

    setTokens: (state, action) => {
      state.accessToken = action.payload.access_token
        ? action.payload.access_token
        : action.payload.accessToken;
      state.refreshToken = action.payload.refresh_token
        ? action.payload.refresh_token
        : action.payload.refreshToken;
      state.expiredIn = action.payload.expires_in
        ? action.payload.expires_in
        : action.payload.expiresIn;
    },

    setApp: (state, action) => {
      state.currentapp = action.payload;
    },

    clearAuth: (state) => {
      state.user = null;
      state.currentapp = null;
      state.accessToken = null;
      state.expiredIn = null;
      state.refreshToken = null;
      state.isUserLoggedIn = false;
    },
  },
});

export const { clearAuth, setUser, setUserLoggedIn, setApp, setTokens } = authSlice.actions;

export default authSlice.reducer;
