import { useEffect, useState } from 'react';
import { BaseInput } from '@reusejs/react-text-inputs';
import useBetaForm from '@reusejs/react-form-hook';
import AuthFormsHeadingSection from './AuthFormHeading';
import { useSelector, shallowEqual } from 'react-redux';
import { Simple as SimpleAlert } from '@reusejs/react-alerts';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTokens, setUserLoggedIn } from '@nx-monorepo/store';
import { login } from '@nx-monorepo/services';

const Login = () => {
  return (
    <div className="flex min-h-screen w-full items-center justify-center bg-blue-200">
      <LoginWithEmail />
    </div>
  );
};

const LoginWithEmail = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const user_param = params?.get('user');

  const { isUserLoggedIn } = useSelector(
    ({ auth }) => ({
      isUserLoggedIn: auth.isUserLoggedIn,
    }),
    shallowEqual
  );

  const loginForm = useBetaForm({
    mode: 'email',
    email: user_param || '',
    password: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let response = await login(loginForm);
      if (response) {
        dispatch(setTokens(response));
        dispatch(setUserLoggedIn(true));
        navigate(`/users`);
      }
    } catch (error) {
      console.log('error in login', error);
      if (error.password && error.username) {
        SimpleAlert({
          message: 'Please enter Email and Password',
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      } else if (error.password) {
        SimpleAlert({
          message: error.password,
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      } else if (error.username) {
        SimpleAlert({
          message: error.username,
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      } else {
        SimpleAlert({
          message: error?.message || 'Error! Please try again later.',
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      return navigate('/users');
    }
  }, [isUserLoggedIn]);

  return (
    <div className="items-center justify-center rounded bg-white p-16 shadow-2xl">
      <form>
        <div className="flex-col">
          <AuthFormsHeadingSection
            sectionHeading={'Admin Login'}
            formHeading={'Welcome to WiredUp'}
            formSubHeading={'Login to access your WiredUp admin dashboard'}
          />
          <div className="mb-6">
            <div className="w-full">
              <BaseInput
                label="Email/Mobile Number"
                placeholder="Enter Email/Mobile number"
                defaultValue={loginForm.getField('email')}
                onChange={(val) => loginForm.setField('email', val)}
                errorText={loginForm.errors.get('username')}
              />
            </div>
          </div>

          <div className="mb-10">
            <div className="relative w-full">
              <div
                className="absolute top-0 right-0 cursor-pointer"
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
              >
                {showPassword ? 'Hide' : 'Show'}
              </div>
              <BaseInput
                label="Password"
                type={showPassword ? '' : 'password'}
                placeholder="Enter Password"
                defaultValue={loginForm.getField('password')}
                onChange={(val) => loginForm.setField('password', val)}
                errorText={loginForm.errors.get('password')}
              />
            </div>
          </div>
          <button
            className="block w-full rounded bg-blue-600 p-3 text-white transition duration-300 hover:bg-blue-700 disabled:cursor-progress disabled:bg-gray-300 disabled:hover:bg-gray-500"
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
