import { axiosObject } from '../setup';

function getUnassignedQueries() {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/get-unassigned-responses`;
    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', errors);
        reject(errors);
      });
  });
}

export { getUnassignedQueries };
