import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const constraints = {
  date: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Date',
    },
    type: 'string',
  },
  time: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Time',
    },
    type: 'string',
  },

  gst_number: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your gst number',
    },
    format: {
      pattern: '^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{2})$',
      message: '^Please enter a valid GST Number',
    },
  },
  company_address: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your  company address',
    },
    type: 'string',
  },
};

const updateOrderconstraints = {
  gst_number: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your gst number',
    },
    format: {
      pattern: '^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{2})$',
      message: '^Please enter a valid GST Number',
    },
  },
  company_address: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your  company address',
    },
    type: 'string',
  },
};

const constraintsWithoutGST = {
  date: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Date',
    },
    type: 'string',
  },
  time: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Time',
    },
    type: 'string',
  },
};

function bookTickets(payload) {
  // console.log('payload:', payload.order_info);
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    if (payload.order_info.gst_invoice) {
      jsValidationErrors = baseValidator(payload.order_info, constraints);
    } else {
      jsValidationErrors = baseValidator(
        payload.order_info,
        constraintsWithoutGST
      );
    }

    if (jsValidationErrors === true) {
      let uri = '/backend-learn-earn/orders';

      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function bookMoreTickets(orderUuid, payload) {
  // console.log('orderUuid:', orderUuid);

  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    if (payload.order_info.gst_invoice) {
      jsValidationErrors = baseValidator(
        payload.order_info,
        updateOrderconstraints
      );
    } else {
      jsValidationErrors = baseValidator(payload.order_info, {});
    }

    if (jsValidationErrors === true) {
      let uri = `/backend-learn-earn/orders/${orderUuid}`;

      axiosObject
        .put(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { bookTickets, bookMoreTickets };
