import { axiosObject } from '../../setup';

function getProfiles() {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profiles`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function getProfileByType(profileType) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profiles?type=${profileType}`;

    axiosObject
      .get(uri)
      .then((response) => {
        if (response.data.length === 0) resolve({});
        resolve(response.data[0]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getProfilesBasedOnLocation(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/all-profiles`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getProfileById(id) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profile/${id}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getBuyerProfileForProduct(product) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/buyer-profiles?product=${product}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export {
  getProfileByType,
  getProfiles,
  getProfilesBasedOnLocation,
  getProfileById,
  getBuyerProfileForProduct,
};
