import React, { useEffect } from 'react';
import { Navigate, RouteProps, useNavigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import Layout from '../Layout';
// import {useAuth} from './useAuth'

export default function ProtectedRoute({
  children,
  activeTab,
  dontCloseCollapseSideBar,
  ...restOfProps
}) {
  const { accessToken } = useSelector(
    ({ auth }) => ({
      accessToken: auth.accessToken,
    }),
    shallowEqual
  );

  return accessToken ? (
    <Layout
      {...restOfProps}
      activeTab={activeTab}
      dontCloseCollapseSideBar={dontCloseCollapseSideBar}
    >
      {children}
    </Layout>
  ) : (
    <Navigate to={'/login'} />
  );
}
