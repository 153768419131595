import { axiosObject } from '../setup';
import baseValidator from '../baseValidator';

const interestRatesConstraints = {
  date: {
    presence: {
      allowEmpty: false,
      message: '^Please select date',
    },
  },
  tenor: {
    presence: {
      allowEmpty: false,
      message: '^Please enter tenor',
    },
  },
  usd_interest_rates: {
    presence: {
      allowEmpty: false,
      message: '^Please enter usd_interest_rates',
    },
  },
  spread: {
    presence: {
      allowEmpty: false,
      message: '^Please enter spread',
    },
  },
  usd_net_rate: {
    presence: {
      allowEmpty: false,
      message: '^Please enter usd_net_rate',
    },
  },
};

function addInterestRates(payload) {
  return new Promise((resolve, reject) => {
    // let jsValidationErrors;
    // jsValidationErrors = baseValidator(payload, interestRatesConstraints);

    // if (jsValidationErrors === true) {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/interest-rates`;
    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
    // } else {
    //   reject(jsValidationErrors);
    // }
  });
}

function updateInterestRates(payload, id) {
  return new Promise((resolve, reject) => {
    // let jsValidationErrors;
    // jsValidationErrors = baseValidator(payload, interestRatesConstraints);
    // if (jsValidationErrors === true) {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/interest-rates/${id}`;
    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
    // } else {
    //   reject(jsValidationErrors);
    // }
  });
}

function getAllInterestRates() {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/interest-rates`;
    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

function getInterestRates(id) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/interest-rates/${id}`;
    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

function deleteInterestRates(id) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/interest-rates/${id}`;
    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}
export {
  addInterestRates,
  updateInterestRates,
  getInterestRates,
  getAllInterestRates,
  deleteInterestRates,
};
