import React, { useRef, useState, useEffect } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import { Simple } from '@reusejs/react-modals';
import { Loader } from '@reusejs/react-loaders';
import { BlueButton } from '@reusejs/react-buttons';

import {
  getCategoryRequests,
  updateCategoryRequest,
} from '@nx-monorepo/services';
import EmptyState from '../components/reusables/EmptyState';
import DeletePrompt from '../components/reusables/DeletePrompt';

const Data = ({ response, reloadAfterReject = () => {} }) => {
  const tableRef = useRef(null);

  const fetchProfiles = () => {
    try {
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const handleAcceptCategory = async (uuid) => {
    const payload = {
      status: 'accepted',
    };
    await updateCategoryRequest(uuid, payload);
    reloadAfterReject(Date.now());
  };

  const handleRejectCategory = async (uuid) => {
    const payload = {
      status: 'rejected',
    };
    await updateCategoryRequest(uuid, payload);
    reloadAfterReject(Date.now());
  };

  const openModal = async (item) => {
    await Simple({
      ModalContent: item,
    });
  };

  const openPopUp = (uuid, type) => {
    openModal(
      React.forwardRef((props, ref) => (
        <span ref={ref} className="flex h-screen w-full items-center">
          <DeletePrompt
            message={`Are you sure you want to ${type} this Category?`}
            deleteCallback={() => {
              if (type === 'accept') handleAcceptCategory(uuid);
              if (type === 'reject') handleRejectCategory(uuid);
            }}
            {...props}
          />
        </span>
      ))
    );
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden mx-5 border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Category Name',
        identifier: 'category_name',
        resolver: (d) => {
          return <div>{d.category_name ? d.category_name : '-'}</div>;
        },
      },
      {
        label: 'User Name',
        identifier: 'user_name',
        resolver: (d) => {
          return (
            <div className="text-column-blue">
              {d.full_name ? d.full_name : '-'}
            </div>
          );
        },
      },
      {
        label: 'User Email',
        identifier: 'email',
        resolver: (d) => {
          return (
            <div className="text-column-blue">{d.email ? d.email : '-'}</div>
          );
        },
      },
      {
        label: 'Action',
        identifier: '',
        resolver: (d) => {
          return (
            d.status === 'pending' && (
              <div className="flex justify-center">
                <BlueButton
                  type="submit"
                  widthClasses="w-40"
                  fontClasses="text-sm font-semibold"
                  borderClasses="rounded-lg"
                  onClick={() => handleAcceptCategory(d.uuid)}
                  // onClick={() => openPopUp(d.uuid, 'accept')}
                >
                  Accept
                </BlueButton>
                <div className="ml-2">
                  <BlueButton
                    type="submit"
                    widthClasses="w-40 bg-red-500 hover:bg-red-600"
                    fontClasses="text-sm font-semibold"
                    borderClasses="rounded-lg"
                    onClick={() => handleRejectCategory(d.uuid)}
                    // onClick={() => openPopUp(d.uuid, 'reject')}
                  >
                    Reject
                  </BlueButton>
                </div>
              </div>
            )
          );
        },
        sortable: false,
      },
    ],
  };

  return (
    <div className="mt-10">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async () => {
          try {
            let response = await fetchProfiles();

            return {
              data: response.reverse(),
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const SectionLoader = () => {
  return (
    <div className="flex h-96 items-center justify-center">
      <Loader />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function () {
  const [reloadAgain, setReloadAgain] = useState(false);

  const orchestratorRef = useRef(null);

  const handler = async () => {
    try {
      let result = await getCategoryRequests();

      if (result.length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }

      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  useEffect(() => {
    orchestratorRef.current.reload();
  }, [reloadAgain]);

  const emptyConfigHeaderCols = [
    {
      label: 'Category Name',
      identifier: 'category_name',
      resolver: () => {},
    },
    {
      label: 'User Name',
      identifier: 'user_name',
      resolver: () => {},
    },
    {
      label: 'User Email',
      identifier: 'email',
      resolver: () => {},
    },
    {
      label: 'Action',
      identifier: '',
      resolver: () => {},
      sortable: false,
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data reloadAfterReject={(val) => setReloadAgain(val)} {...props} />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
}
