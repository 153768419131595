import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const constraints = {
  query_category: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Query Category',
    },
  },
  question: {
    presence: {
      allowEmpty: false,
      message: '^Please Enter Your Question',
    },
  },
  country: {
    presence: {
      allowEmpty: false,
      message: '^Please Enter Your Country',
    },
  },
};

function createQuestion(payload) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    jsValidationErrors = baseValidator(payload, constraints);

    if (jsValidationErrors === true) {
      let uri = '/backend-ask-anything/question';
      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { createQuestion };
