import Wrapper from './HostedSessionsHeaders/Wrapper';
import Table from './Table';
import { useNavigate } from 'react-router-dom';

export default function () {
  let navigate = useNavigate();

  return (
    <Wrapper topBarActiveOption={'Events'} active="Events">
      <div>
        <div>
          <Table
            callAction={(d, action) => {
              navigate(`/registered-sessions/${d.uuid}`, {
                state: {
                  itemUuid: d?.uuid,
                  eventTopic: d?.data?.name,
                },
              });
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}
