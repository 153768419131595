/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useRef, useState, useEffect } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import SectionLoader from '../../components/reusables/SectionLoader';
import { useLocation } from 'react-router-dom';
import Wrapper from './HostedSessionsViewHeaders/Wrapper';
import { getOrdersByEventId, getEventByUuid } from '@nx-monorepo/services';
import formatDate from '../../utils/formatDate';
import SingleEvent from '../../components/reusables/SingleEventView';
import EventEmptyState from '../../components/reusables/EventEmptyState';

const Data = ({ response, eventProfilePic, eventDetails, breadCrumbsName }) => {
  const tableRef = useRef(null);
  const [singleEventDetails, setSingleEventDetails] = useState({});

  useEffect(() => {
    let eventObj = {
      'Event Name': eventDetails?.data?.name ? eventDetails?.data?.name : '-',
      'Event Type': eventDetails?.type ? eventDetails?.type : '-',
      'Advance Fee': eventDetails?.data?.advance_fee
        ? eventDetails?.data?.advance_fee
        : '-',
      Description: eventDetails?.data?.description
        ? eventDetails?.data?.description
        : '-',
      'Speaker Name': eventDetails?.data?.speaker_name
        ? eventDetails?.data?.speaker_name
        : '-',
      'About Speaker': eventDetails?.data?.about_speaker
        ? eventDetails?.data?.about_speaker
        : '-',
      'Company Name': eventDetails?.data?.company_name
        ? eventDetails?.data?.company_name
        : '-',
      Designation: eventDetails?.data?.designation
        ? eventDetails?.data?.designation
        : '-',
      'Ticket Price': eventDetails?.data?.ticket_price
        ? eventDetails?.data?.ticket_price
        : '-',
    };
    setSingleEventDetails(eventObj);
  }, []);

  const fetchProducts = async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let _response = response.data;
        resolve(_response);
      } catch (error) {
        reject(error);
      }
    });
  };

  const config = {
    tableWrapperClasses:
      'overflow-hidden mx-5 border rounded-bl-lg rounded-br-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Registered User',
        identifier: 'registered_user',
        resolver: (d) => {
          return <div>{d?.order_info?.registered_user}</div>;
        },
        sortable: false,
      },
      {
        label: 'Email',
        identifier: 'email',
        resolver: (d) => {
          return <div>{d?.order_info?.email ? d?.order_info?.email : '-'}</div>;
        },
        sortable: false,
      },
      {
        label: 'Mobile',
        identifier: 'mobile',
        resolver: (d) => {
          return (
            <div>{d?.order_info?.mobile ? d?.order_info?.mobile : '-'}</div>
          );
        },
        sortable: false,
      },
      {
        label: 'Amount Paid',
        identifier: 'amount',
        resolver: (d) => {
          console.log('amountHere', typeof d.amount);
          return <div>{d?.amount === 'NaN' ? '0' : d?.amount}</div>;
        },
        sortable: false,
      },
      {
        label: 'Registered On',
        identifier: 'createdAt',
        resolver: (d) => {
          return <div>{formatDate(new Date(d.createdAt), 'dmy')}</div>;
        },
        sortable: false,
      },
      {
        label: 'Payment Status',
        identifier: 'status',
        resolver: (d) => {
          return <div>{d?.status}</div>;
        },
        sortable: false,
      },
    ],
  };

  return (
    <Wrapper breadCrumbsProp={breadCrumbsName}>
      <SingleEvent
        eventDetails={singleEventDetails}
        eventProfilePic={eventProfilePic}
        dateAndTime={eventDetails?.data?.date_and_time}
      />

      <div className="mt-10 pb-10">
        <div className="mx-3 rounded-tl-lg rounded-tr-lg border-t border-r border-l border-gray-200 px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Registered Users
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            List of registered users for this Event.
          </p>
        </div>
        <DataTable
          ref={tableRef}
          config={config}
          defaultSortColumn={0}
          perPage={5}
          dataSource={async () => {
            try {
              let response = await fetchProducts();
              return {
                data: response,
                pagination: {
                  total: 0,
                },
              };
            } catch (error) {
              console.log(error);
            }
          }}
        />
      </div>
    </Wrapper>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function ({ callAction }) {
  const orchestratorRef = useRef(null);
  const location = useLocation();
  const [eventDetails, setEventDetails] = useState(null);
  const [eventProfilePic, setEventProfilePic] = useState(null);

  let breadCrumbsName = location?.state?.eventTopic;
  let singleUuid = location?.state?.itemUuid;

  const getEventDetails = async () => {
    let response = await getEventByUuid(location?.state?.itemUuid);
    setEventDetails(response);
    setEventProfilePic(response?.data?.profile_picture);
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  const handler = async () => {
    try {
      let result = await getOrdersByEventId(singleUuid);
      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }
      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  const emptyConfigHeaderCols = [
    {
      label: 'Event Topic',
      identifier: 'event_topic',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Event Type',
      identifier: 'event_type',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Created By',
      identifier: 'username',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Created On',
      identifier: 'created_at',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Registrations',
      identifier: 'registrations',
      resolver: (d) => {},
      sortable: false,
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data
              eventDetails={eventDetails}
              eventProfilePic={eventProfilePic}
              breadCrumbsName={breadCrumbsName}
              {...props}
            />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <Wrapper breadCrumbsProp={breadCrumbsName}>
            <span className="mt-10" ref={ref}>
              <EventEmptyState
                tableHeadColumns={emptyConfigHeaderCols}
                eventId={location?.state?.itemUuid}
                noDataText={`No registrations for this Event yet`}
                {...props}
              />
            </span>
          </Wrapper>
        )),
        error: ErrorState,
      }}
    />
  );
}
