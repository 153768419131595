import { SwitchHorizontalIcon } from '@heroicons/react/solid';
import { classNames } from '@reusejs/react-utils';

const TopHeader = () => {
  return (
    <div className="z-0 flex w-full items-center border-b p-0">
      <div
        className={classNames(
          'pointer-events-none relative flex w-64 items-center border-r bg-white !py-6 !pl-6 text-lg text-gray-900'
        )}
      >
        Loan Hedged Specs
        <SwitchHorizontalIcon
          className={classNames('ml-3 h-4 w-4 text-blue-500')}
        />
      </div>
    </div>
  );
};

export default TopHeader;
