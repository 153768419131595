import { axiosObject } from '../setup';

function registerFirebaseToken(token, device) {
  console.log('token, device', token, device);

  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/register-firebase-token`;
    axiosObject
      .post(uri, { token, device })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function deregisterFirebaseToken(token) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/deregister-firebase-token`;
    axiosObject
      .post(uri, { token })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export { registerFirebaseToken, deregisterFirebaseToken };
