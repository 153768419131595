import TopHeader from './TopHeader';

export default function (props) {
  return (
    <div>
      <TopHeader topBarActiveOption={props.topBarActiveOption} />
      {props.children}
    </div>
  );
}
