const currency_pairs = [
  'USD/INR',
  'EUR/JPY',
  'USD/AED',
  'EUR/CAD',
  'THB/INR',
  'USD/SGD',
  'USD/RON',
  'HKD/INR',
  'USD/KRW',
  'USD/TWD',
  'SGD/JPY',
  'EUR/SEK',
  'AUD/SGD',
  'USD/CHF',
  'AED/JPY',
  'USD/HKD',
  'AUD/INR',
  'USD/NOK',
  'EUR/AUD',
  'USD/CNY',
  'BRL/INR',
  'KRW/INR',
  'EUR/NOK',
  'USD/ZAR',
  'EUR/DKK',
  'NZD/SGD',
  'RUB/INR',
  'AUD/AED',
  'USD/CLP',
  'USD/TRY',
  'USD/QAR',
  'EUR/CHF',
  'PHP/INR',
  'MYR/INR',
  'USD/DKK',
  'USD/SEK',
  'USD/BRL',
  'USD/IDR',
  'CHF/AED',
  'EUR/INR',
  'CAD/AED',
  'USD/CZK',
  'HUF/INR',
  'SEK/INR',
  'CAD/INR',
  'GBP/USD',
  'USD/RUB',
  'EUR/SGD',
  'NZD/USD',
  'NOK/INR',
  'PLN/INR',
  'DKK/INR',
  'TRY/INR',
  'EUR/USD',
  'NZD/INR',
  'AUD/USD',
  'SGD/INR',
  'IDR/INR',
  'USD/PLN',
  'CAD/SGD',
  'USD/JPY',
  'USD/MXN',
  'USD/PHP',
  'CHF/INR',
  'CLP/INR',
  'TWD/INR',
  'USD/CAD',
  'RON/INR',
  'GBP/AED',
  'USD/MYR',
  'CNY/INR',
  'ZAR/INR',
  'JPY/INR',
  'GBP/SGD',
  'VND/INR',
  'USD/SAR',
  'MXN/INR',
  'EUR/GBP',
  'EUR/AED',
  'USD/THB',
  'CZK/INR',
  'USD/CNH',
  'USD/HUF',
  'CNH/INR',
  'ZAR/SGD',
  'EUR/PLN',
  'SAR/INR',
  'GBP/INR',
  'USD/VND',
  'AED/INR',
];

export { currency_pairs };
