import { axiosObject } from '../setup';

function updateSellerQueries(id, payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/response/${id}/quote`;
    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', errors);
        reject(errors);
      });
  });
}

export { updateSellerQueries };
