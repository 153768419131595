import { filterOptions } from '../utils';

const getCoverageVerticle = (q = '') => {
  let data = [
    { label: 'Retail', value: 'Retail' },
    { label: 'Business Banking', value: 'Business Banking' },
    { label: 'Commercial Banking', value: 'Commercial Banking' },
    {
      label: 'MSME',
      value: 'MSME',
    },
    {
      label: 'Small Sized Corporates',
      value: 'Small Sized Corporates',
    },
    { label: 'Mid-Sized Corporates', value: 'Mid-Sized Corporates' },
    {
      label: 'Large Corporates',
      value: 'Large Corporates',
    },
    {
      label: 'Multi National Corporates',
      value: 'Multi National Corporates',
    },
    { label: 'Public Sector Enterprises', value: 'Public Sector Enterprises' },
    {
      label: 'Global Conglomerates',
      value: 'Global Conglomerates',
    },
    { label: 'Financial Institutions', value: 'Financial Institutions' },
    { label: 'Mutual Fund', value: 'Mutual Fund' },
    { label: 'Hedge Fund', value: 'Hedge Fund' },
    {
      label: 'Family owned Office',
      value: 'Family owned Office',
    },
    {
      label: 'Foreign Instituional Investors',
      value: 'Foreign Instituional Investors',
    },
    { label: 'Brokerages', value: 'Brokerages' },
    { label: 'Private Equity', value: 'Private Equity' },
    { label: 'Fund Houses', value: 'Fund Houses' },
    { label: 'Agri Business', value: 'Agri Business' },
    { label: 'Distressed Assets', value: 'Distressed Assets' },
  ];

  if (q !== '') {
    return filterOptions(data, q);
  } else {
    return data;
  }
};

export { getCoverageVerticle };
