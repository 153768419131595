import { useNavigate } from 'react-router-dom';
const tabs = [
  { tabKey: 'invoices', name: 'Invoices', href: '/business/invoices' },
  { tabKey: 'bills', name: 'Bills', href: '/business/bills' },
  {
    tabKey: 'transactions',
    name: 'Transactions',
    href: '/business/transactions',
  },
  // { tabKey: 'reconciliation', name: 'Reconciliation', href: '/business' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example({ active = null }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="border-b">
        <div className='flex'>
          <nav className="flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={classNames(
                  'w-48 bg-white py-5 text-center text-sm relative flex justify-center',
                  tab.tabKey === active
                    ? 'border-blue-500 border-b-2 text-blue-700'
                    : 'border-b'
                )}
                onClick={() => {
                  navigate(tab.href);
                }}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
