import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const emailConstraints = {
  username: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your Email ID',
    },
    email: {
      message: '^Please enter a valid Email ID',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your Password',
    },
  },
};

const mobileConstraints = {
  username: {
    format: {
      pattern: '^\\+[1-9]\\d{1,14}$',
      message: '^Please enter a valid mobile number',
    },
  },

  password: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your Password',
    },
  },
};

function isMobileNumber(number) {
  const pattern = /^\d{10}$/;
  return pattern.test(number);
}

function login(form) {
  let payload = form.value;
  return new Promise((resolve, reject) => {
    var jsValidationErrors;
    var apiPayload = {};

    const mode = isMobileNumber(payload['email']) ? 'mobile' : 'email';

    if (mode === 'email') {
      payload['username'] = payload['email'];

      jsValidationErrors = baseValidator(payload, emailConstraints);
      apiPayload = {
        mode: 'email',
        username: payload['email'],
        password: payload['password'],
        is_admin_login_attempt: true,
      };
    }

    if (mode === 'mobile') {
      const username = '+91' + payload['email'];
      const mobile_login_payload = {
        mode: 'mobile',
        username: username,
        password: payload['password'],
        is_admin_login_attempt: true,
      };
      jsValidationErrors = baseValidator(
        mobile_login_payload,
        mobileConstraints
      );
      apiPayload = mobile_login_payload;
    }
    form.startProcessing();

    if (jsValidationErrors === true) {
      form.startProcessing();
      form.setErrors({});
      let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/login`;

      axiosObject
        .post(uri, apiPayload)
        .then((response) => {
          form.finishProcessing();
          resolve(response.data);
        })
        .catch((errors) => {
          console.log('Errors', JSON.stringify(errors));
          form.finishProcessing();
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          if (errors.response.status === 401) {
            reject({
              password: 'Incorrect Username or Password',
            });
          }
          if (errors.response.status === 403) {
            reject({
              message: 'Permission Denied!! Contact Admin.',
            });
          }
          reject(errors);
        });
    } else {
      form.finishProcessing();
      form.setErrors(jsValidationErrors);
      console.log('jsValidationErrors8888', jsValidationErrors);
      if (Object.keys(jsValidationErrors).includes('mobile_number')) {
        jsValidationErrors['username'] = jsValidationErrors['mobile_number'];
      }
      reject(jsValidationErrors);
    }
  });
}

export { login };
