import React, { useEffect } from 'react';
import { BlueButton } from '@reusejs/react-buttons';
import { BaseInput } from '@reusejs/react-text-inputs';
import CloseIcon from '../assets/CloseIcon';
import useBetaForm from '@reusejs/react-form-hook';
import { addMarketView, updateMarketView } from '@nx-monorepo/services';
import { classNames } from '@reusejs/react-utils';
import { marked } from 'marked';
import TurndownService from 'turndown';
import 'github-markdown-css';

const PopupForm = ({
  submitOnRefresh = (initialState = false) => initialState,
  mode,
  data,
  ...props
}) => {
  const turndownService = new TurndownService();

  const addMarketViewForm = useBetaForm({
    title: mode === 'edit' ? data?.title : '',
    description: mode === 'edit' ? '' : '',
    status: mode === 'edit' ? data?.status : '',
  });

  const processDescription = (text) => {
    return marked(text);
  };

  const handleSubmit = async (e) => {
    addMarketViewForm.startProcessing();
    e.preventDefault();
    try {
      const processedData = {
        ...addMarketViewForm.value,
        description: processDescription(addMarketViewForm.value.description),
      };

      if (mode === 'edit') {
        await updateMarketView(processedData, data?.uuid);
      } else {
        await addMarketView(processedData);
      }

      submitOnRefresh(true);
      submitOnRefresh(false);
      props.resolveModal(false);
      addMarketViewForm.finishProcessing();
    } catch (error) {
      addMarketViewForm.setErrors(error);
      return error;
    }
  };

  useEffect(() => {
    if (mode === 'edit' && data?.description) {
      const markdownContent = turndownService.turndown(data.description);
      addMarketViewForm.setField('description', markdownContent);
    }
  }, [mode, data]);

  return (
    <form
      className="min-w-screen inline-block flex transform flex-col overflow-hidden rounded-lg px-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
      style={{
        fontSize: '10px',
        maxHeight: '685px',
        padding: '0',
        backgroundColor: '#FCFCFC',
        position: 'relative',
        zIndex: '0',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          width: '35px',
          height: '35px',
          borderRadius: '10px',
          backgroundColor: '#ececec',
        }}
        className="flex items-center justify-center"
        onClick={() => props.resolveModal(false)}
      >
        <CloseIcon />
      </div>

      <div style={{ padding: '40px' }}>
        <h3 className="text-base">
          {mode === 'edit' ? `Edit Market View` : `Add Market View`}
        </h3>
        <div className="mt-5 flex flex-col">
          <div>
            <BaseInput
              label="Title"
              noErrorClasses="rounded-md border border-gray-300 text-black placeholder-gray-500 placeholder-opacity-50 focus:outline-none"
              placeholder="Enter title"
              defaultValue={addMarketViewForm.getField('title')}
              onChange={(val) => addMarketViewForm.setField('title', val)}
              errorText={addMarketViewForm.errors.get('title')}
            />
          </div>

          <div className="mt-5 flex flex-col">
            <div className="flex items-center justify-between">
              <p
                className={classNames(
                  'text-sm',
                  addMarketViewForm.errors.get('description')
                    ? 'text-red-600'
                    : ''
                )}
              >
                Description <span className='italic text-gray-500 text-xs'>(Use Markdown syntax)</span>
              </p>
            </div>
            <textarea
              value={addMarketViewForm.getField('description')}
              rows="6"
              placeholder="Enter description using Markdown syntax..."
              onChange={(e) => {
                addMarketViewForm.setField('description', e.target.value);
              }}
              className={classNames(
                'mt-1 rounded-md border border-gray-300 p-3 text-sm shadow-lg focus:outline-none focus:ring-1 focus:ring-blue-400',
                addMarketViewForm.errors.get('description')
                  ? 'border-none placeholder-red-300 ring-1 ring-red-600'
                  : ''
              )}
            ></textarea>
            {addMarketViewForm.errors.get('description') ? (
              <p className="mt-2 text-sm text-red-600">
                {addMarketViewForm.errors.get('description')}
              </p>
            ) : null}
          </div>
          <div></div>
        </div>
      </div>

      <div className="visible flex items-center justify-end border p-3">
        <div className="mr-3 cursor-pointer hover:text-blue-600 md:w-auto">
          <BlueButton
            type="submit"
            widthClasses="w-full"
            fontClasses="text-sm font-semibold"
            borderClasses="rounded-lg"
            onClick={(e) => {
              addMarketViewForm.value.status = 'drafted';
              handleSubmit(e);
            }}
          >
            Save as Draft
          </BlueButton>
        </div>
        <div className="mr-3 cursor-pointer hover:text-blue-600 md:w-auto">
          <BlueButton
            type="submit"
            widthClasses="w-full"
            fontClasses="text-sm font-semibold"
            borderClasses="rounded-lg"
            onClick={(e) => {
              addMarketViewForm.value.status = 'published';
              handleSubmit(e);
            }}
            busy={addMarketViewForm.busy}
            busyText={'Processing..'}
          >
            Publish
          </BlueButton>
        </div>
        <div className="mr-3 cursor-pointer md:w-auto">
          <BlueButton
            type="button"
            widthClasses="w-full bg-red-500 hover:bg-red-600"
            fontClasses="text-sm font-semibold"
            borderClasses="rounded-lg"
            busyText="Processing"
            onClick={() => props.resolveModal()}
          >
            Cancel
          </BlueButton>
        </div>
      </div>
    </form>
  );
};

export default PopupForm;
