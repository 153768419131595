import { axiosObject } from '../setup';

function getAllSubscriptions(params) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_PAYMENTS_API_URL}/subscriptions`;

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function updateTrailPeriod(id, payload) {
  console.log('chillBro7777', id, payload);
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_PAYMENTS_API_URL}/subscription/${id}`;
    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { getAllSubscriptions, updateTrailPeriod };
