import { axiosObject } from '../setup';
import baseValidator from '../baseValidator';

// const userConstraints = {
//   name: {
//     presence: {
//       allowEmpty: false,
//       message: '^Please enter your full name',
//     },
//     type: 'string',
//   },
// };

function updateUser(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/update-user`;
    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export { updateUser };
