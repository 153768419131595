import { axiosObject } from '../setup';

function getResponseByQueryId(queryId) {

    return new Promise((resolve, reject) => {
      let uri = `/backend-discuss/responses/${queryId}`;
  
      axiosObject
        .get(uri)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    });
    
  } 

  export {getResponseByQueryId};