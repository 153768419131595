import React, { useRef, useState, useMemo } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import { Loader } from '@reusejs/react-loaders';
import { BlueButton } from '@reusejs/react-buttons';
import { Simple } from '@reusejs/react-modals';
import PopupForm from './PopupForm';
import SectionLoader from '../components/reusables/SectionLoader';
import EmptyState from '../components/reusables/EmptyState';
import { getAllSubscriptions } from '@nx-monorepo/services';
import formatDate from '../utils/formatDate';

const convertDateToJs = (timeStamp) => {
  let date = new Date(parseInt(timeStamp));
  let formattedDate = formatDate(new Date(date), 'dmy');
  return formattedDate;
};

const Data = ({ response, submitOnRefresh = () => {} }) => {
  const tableRef = useRef(null);

  const fetchProfiles = () => {
    try {
      return response.data;
    } catch (error) {
      return error;
    }
  };
  const openModal = async (item) => {
    await Simple({
      ModalContent: item,
    });
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden mx-5 border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'User Name',
        identifier: 'user_name',
        resolver: (d) => {
          return (
            <div
              onClick={() => {
                return;
              }}
            >
              {d?.data?.full_name ? d?.data?.full_name : 'N/A'}
            </div>
          );
        },
      },
      {
        label: 'Email/Mobile',
        identifier: 'email',
        resolver: (d) => {
          return (
            <div>
              {d?.data?.email ||
                d?.data?.phone_number ||
                d?.data?.customer_details?.email ||
                d?.data?.email_id ||
                'N/A'}
            </div>
          );
        },
      },

      // {
      //   label: 'Active Plan',
      //   identifier: 'plan_name',
      //   resolver: (d) => {
      //     return (
      //       <div className="text-column-blue">
      //         {d.plan_name ? d.plan_name : '-'}
      //       </div>
      //     );
      //   },
      // },
      {
        label: 'Starts At',
        identifier: 'starts_at',
        resolver: (d) => {
          return <div>{d.starts_at ? convertDateToJs(d.starts_at) : '-'}</div>;
        },
      },
      {
        label: 'Ends At',
        identifier: 'ends_at',
        resolver: (d) => {
          return <div>{convertDateToJs(d.ends_at)}</div>;
        },
      },
      // {
      //   label: 'Payment Status',
      //   identifier: 'payment_status',
      //   resolver: (d) => {
      //     return (
      //       <div>
      //         {d?.stripe_subscription_id && d?.status === 'active'
      //           ? 'paid'
      //           : 'unpaid'}
      //       </div>
      //     );
      //   },
      // },
      {
        label: 'Action',
        identifier: '',
        resolver: (d) => {
          return (
            <div className="flex justify-center">
              <BlueButton
                type="button"
                widthClasses="w-40"
                fontClasses="text-sm font-semibold"
                borderClasses="rounded-lg"
                busyText="Processing"
                onClick={() =>
                  openModal(
                    React.forwardRef((props, ref) => {
                      return (
                        <span
                          ref={ref}
                          className="flex h-screen w-full items-center justify-center"
                        >
                          <PopupForm
                            submitOnRefresh={(cond) => submitOnRefresh(cond)}
                            subscriptionId={d.uuid}
                            {...props}
                          />
                        </span>
                      );
                    })
                  )
                }
              >
                Manage Trail Period
              </BlueButton>
            </div>
          );
        },
        sortable: false,
      },
    ],
  };

  return (
    <div className="mt-10">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async (params) => {
          try {
            let response = await fetchProfiles(params);
            response = response.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            return {
              data: response,
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function ({ mode, callAction, filterBy, submitOnRefresh }) {
  const orchestratorRef = useRef(null);
  const [reload, setReload] = useState(false);

  const handler = async () => {
    try {
      let result = await getAllSubscriptions();

      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }

      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  useMemo(() => {
    if (reload || submitOnRefresh) {
      orchestratorRef.current.reload();
    }
  }, [reload, submitOnRefresh]);

  const emptyConfigHeaderCols = [
    {
      label: 'User Name',
      identifier: 'user_name',
      resolver: () => {},
    },
    {
      label: 'Active Plan',
      identifier: 'plan_name',
      resolver: () => {},
    },
    {
      label: 'Starts At',
      identifier: 'ends_at',
      resolver: () => {},
    },
    {
      label: 'Payment Status',
      identifier: 'payment_status',
      resolver: () => {},
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data
              mode={mode}
              callAction={callAction}
              filterBy={filterBy}
              submitOnRefresh={(cond) => setReload(cond)}
              {...props}
            />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
}
