import { axiosObject } from '../setup';

function initiateSocialLogin(provider = 'google', action = 'redirect') {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/initiate-social-login/${provider}?action=${action}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', JSON.stringify(errors));
        reject(errors);
      });
  });
}

function tokenSocialLogin(provider = 'google', params) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/token-social-login/${provider}`;

    axiosObject
      .get(uri, {
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', errors.response);
        reject(errors);
      });
  });
}

export { initiateSocialLogin, tokenSocialLogin };
