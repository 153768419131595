import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from 'react-router-dom';
import ProtectedRoute from './lib/Routers/Protected';
import Home from './lib/Dashboard/Home';
import UsersList from './lib/Users/UsersList';
import TradesOfTheDayList from './lib/TradesOfTheDay/TradesOfTheDaysList';
import ProfilesList from './lib/Profiles/ProfileList';
import { useSelector, shallowEqual } from 'react-redux';
import ViewProfile from './lib/Profiles/ViewProfile';
import Login from '../src/lib/Auth/Login';
import ProductsList from './lib/Products/ProductsList';
import ProductRequestsList from './lib/Products/ProductRequestsList';
import MarketViewsList from './lib/MarketingViews/List';
import LoanHedgedSpecsList from './lib/LoanHedgedSpecs/List';
import BorrowingCostRatesList from './lib/BorrowingCostRates/List';
import ViewMarketView from './lib/MarketingViews/ViewMarketingView';
import SubscriptionsList from './lib/Subscriptions/SubscriptionList';
import HostedSessions from './lib/Events/HostedSessions/List';
import CorporateList from './lib/Events/Corporate/List';
import ViewCorporate from './lib/Events/Corporate/ViewCorporate';
import ViewRegisteredSession from './lib/Events/HostedSessions/View';
import MenteesList from './lib/Events/MentorShip/Mentees/List';
import ViewMentee from './lib/Events/MentorShip/Mentees/ViewMentee';
import MentorsList from './lib/Events/MentorShip/Mentors/List';
import ViewMentor from './lib/Events/MentorShip/Mentors/ViewMentor';
import CouncellorsList from './lib/Events/MentorShip/Councellors/List';
import ViewCouncellor from './lib/Events/MentorShip/Councellors/ViewCouncellor';
import LeadsList from './lib/Leads/LeadsList';
import CategoryRequestList from './lib/CategoryRequests/CategoryRequestList';

export function App() {
  const { accessToken } = useSelector(
    ({ auth }) => ({
      accessToken: auth.accessToken,
    }),
    shallowEqual
  );

  return (
    <Router>
      <Routes>
        <Route path={'/login'} element={<Login />} />
        <Route
          path={'/'}
          element={
            <ProtectedRoute activeTab={'Dashboard'}>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/users'}
          element={
            <ProtectedRoute activeTab={'Users'}>
              <UsersList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/trades-of-the-day'}
          element={
            <ProtectedRoute activeTab={'trades-of-the-day'}>
              <TradesOfTheDayList />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path={'/profiles'}
          element={
            <ProtectedRoute activeTab={'Profiles'}>
              <ProfilesList />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path={'/profiles/:uuid'}
          element={
            <ProtectedRoute activeTab={'Profiles'}>
              <ViewProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/products'}
          element={
            <ProtectedRoute activeTab={'Products'}>
              {/* <ProductsList /> */}
            </ProtectedRoute>
          }
        />
        <Route
          path={'/product-requests'}
          element={
            <ProtectedRoute activeTab={'Products'}>
              <ProductRequestsList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/marketing-views'}
          element={
            <ProtectedRoute activeTab={'Marketing_Views'}>
              <MarketViewsList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/marketing-views/:id'}
          element={
            <ProtectedRoute activeTab={'Marketing_Views'}>
              <ViewMarketView />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/interest-rates'}
          element={
            <ProtectedRoute activeTab={'Loan_Hedged_Specs'}>
              <LoanHedgedSpecsList />
            </ProtectedRoute>
          }
        />
        {/* TODO ADD FOR BORROWING COST RATES */}
        <Route
          path={'/borrowing-rates'}
          element={
            <ProtectedRoute activeTab={'Borrowing_Cost_Rates'}>
              <BorrowingCostRatesList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/subcriptions'}
          element={
            <ProtectedRoute activeTab={'Subscriptions'}>
              <SubscriptionsList />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/registered-sessions'}
          element={
            <ProtectedRoute
              activeTab={'HostedSessions'}
              dontCloseCollapseSideBar={true}
            >
              <HostedSessions />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/registered-sessions/:uuid'}
          element={
            <ProtectedRoute
              activeTab={'HostedSessions'}
              dontCloseCollapseSideBar={true}
            >
              <ViewRegisteredSession />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/corporate'}
          element={
            <ProtectedRoute
              activeTab={'Corporate'}
              dontCloseCollapseSideBar={true}
            >
              <CorporateList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/corporate/:uuid'}
          element={
            <ProtectedRoute
              activeTab={'Corporate'}
              dontCloseCollapseSideBar={true}
            >
              <ViewCorporate />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/mentees'}
          element={
            <ProtectedRoute
              activeTab={'Mentorship'}
              dontCloseCollapseSideBar={true}
            >
              <MenteesList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/mentees/:uuid'}
          element={
            <ProtectedRoute
              activeTab={'Mentorship'}
              dontCloseCollapseSideBar={true}
            >
              <ViewMentee />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/mentors'}
          element={
            <ProtectedRoute
              activeTab={'Mentorship'}
              dontCloseCollapseSideBar={true}
            >
              <MentorsList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/mentors/:uuid'}
          element={
            <ProtectedRoute
              activeTab={'Mentorship'}
              dontCloseCollapseSideBar={true}
            >
              <ViewMentor />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/professional-counsellors'}
          element={
            <ProtectedRoute
              activeTab={'Mentorship'}
              dontCloseCollapseSideBar={true}
            >
              <CouncellorsList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/professional-counsellors/:uuid'}
          element={
            <ProtectedRoute
              activeTab={'Mentorship'}
              dontCloseCollapseSideBar={true}
            >
              <ViewCouncellor />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            accessToken ? (
              <Navigate to={'/users'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path={'/leads'}
          element={
            <ProtectedRoute activeTab={'Leads'}>
              <LeadsList />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/category-requests'}
          element={
            <ProtectedRoute activeTab={'Category Requests'}>
              <CategoryRequestList />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
