import { axiosObject } from '../setup';
import { filterOptions } from '../utils';

function getAllCountries(q = '') {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/countries`;
    axiosObject
      .get(uri)
      .then((response) => {
        let countries = response.data.map((country) => {
          return {
            label: country.name,
            value: country.isoCode,
          };
        });

        if (q !== '') {
          countries = filterOptions(countries, q);
        }

        resolve(countries);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function getAllCitiesByCountryCode(countryCode, q) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/countries/${countryCode}/cities`;
    axiosObject
      .get(uri)
      .then((response) => {
        let cityOptions = response.data.map((city) => {
          return city.name;
        });

        let uniqueCities = [...new Set(cityOptions)];

        cityOptions = uniqueCities.map((city) => {
          return {
            label: city,
            value: city,
          };
        });

        if (q !== '') {
          cityOptions = filterOptions(cityOptions, q);
        }

        resolve(cityOptions);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export { getAllCountries, getAllCitiesByCountryCode };
