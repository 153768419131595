import React, { useState } from 'react';
import { BlueButton } from '@reusejs/react-buttons';
import CloseIcon from '../assets/CloseIcon';
import { Simple as SimpleAlert } from '@reusejs/react-alerts';
import {
  deleteTradeOfTheDay,
  capitalizeFirstLetter,
} from '@nx-monorepo/services';

const DeletePopUp = ({
  submitOnRefresh = (initialState = false) => initialState,
  mode,
  data,
  ...props
}) => {
  const handleSubmit = async (form_values) => {
    const uuid = form_values.uuid;
    if (uuid) {
      const response = await deleteTradeOfTheDay(uuid);
      if (response?.success) {
        SimpleAlert({
          message: 'Trade of the day successfully deleted',
          borderClasses: 'border-b-2 border-green-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
        submitOnRefresh(true);
        submitOnRefresh(false);
        props.resolveModal(false);
      } else {
        SimpleAlert({
          message: 'Deletion of Record failed!!',
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      }
    } else {
      SimpleAlert({
        message: 'UUID for Record not found',
        borderClasses: 'border-b-2 border-red-400',
        wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
        alignmentClasses: 'w-full items-center',
      });
      submitOnRefresh(true);
      submitOnRefresh(false);
      props.resolveModal(false);
    }
  };

  return (
    <form
      className="min-w-screen inline-block transform overflow-hidden rounded-lg px-4 pt-5 text-left text-[0.625rem] shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
      style={{
        fontSize: '10px',
        maxHeight: '685px',
        padding: '0',
        backgroundColor: '#FCFCFC',
        position: 'relative',
        zIndex: '0',
      }}
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const values = Object.fromEntries(formData.entries());

        handleSubmit(values);
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          width: '35px',
          height: '35px',
          borderRadius: '10px',
          backgroundColor: '#ececec',
        }}
        className="flex items-center justify-center"
        onClick={() => props.resolveModal(false)}
      >
        <CloseIcon />
      </div>

      <div style={{ padding: '40px' }}>
        <h3 className="text-lg font-bold">Delete Trade of the Day</h3>
        <div className="mt-5 flex flex-col gap-y-2">
          <input type="hidden" name="uuid" value={data?.uuid || ''} />
          <div className="flex flex-col gap-y-2">
            <label className="text-lg">
              Are you sure you want to delete this trade of the day? This action
              cannot be undone.
            </label>
            <span>
              <label className="text-base font-semibold">Trade Pair: </label>
              <label className="text-base">{data.currency_pair}</label>
            </span>
            <span>
              <label className="text-base font-semibold">Type : </label>
              <label
                className={
                  data?.type === 'buy'
                    ? 'w-fit rounded-md bg-green-700 py-1 px-2 text-center text-base font-semibold text-white'
                    : 'w-fit rounded-md bg-yellow-700 py-1 px-2 text-center text-base font-semibold text-white'
                }
              >
                {capitalizeFirstLetter(data?.type) || 'N/A'}
              </label>
            </span>

            <label className="text-base">
              Please confirm if you'd like to proceed with the deletion.
            </label>
          </div>
        </div>
      </div>

      <div
        className="flex items-center justify-end"
        style={{
          height: '60px',
          marginTop: '',
          borderTop: '1px solid #EDEDED',
          backgroundColor: '#FFF',
        }}
      >
        <div className="mr-3 cursor-pointer md:w-auto">
          <BlueButton
            type="submit"
            widthClasses="w-30 bg-red-600 hover:bg-red-700"
            fontClasses="text-sm font-semibold"
            borderClasses="rounded-lg"
          >
            Delete
          </BlueButton>
        </div>
      </div>
    </form>
  );
};

export default DeletePopUp;
