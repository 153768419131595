function setFormFieldsFromData(form, data, fields) {
  let filledFields = {};

  if (data) {
    fields.forEach((field) => {
      if (data[field]) {
        filledFields[field] = data[field];
        form.setField(field, data[field]);
      }
    });
  }

  return filledFields;
}

export { setFormFieldsFromData };
