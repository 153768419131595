import { createSlice } from '@reduxjs/toolkit';

export const quoraSlice = createSlice({
  name: 'quora',
  initialState: {
    categories: [],
  },
  reducers: {
    setCategory: (state, action) => {
      console.log('setCategory', action.payload);
      state.categories = action.payload;
    },
  },
});

export const { setCategory } = quoraSlice.actions;

export default quoraSlice.reducer;
