import { useState, useEffect } from 'react';
import ViewDetailsCard from '../../components/reusables/ViewDetailsCard';
import Wrapper from './CorporateHeaders/Wrapper';
import { useLocation } from 'react-router-dom';
import { getEnquerybyUuid } from '@nx-monorepo/services';

function ViewCorporate() {
  const [details, setDetails] = useState({});
  const location = useLocation();

  const getSingleCorporateDetails = async () => {
    try {
      let response = await getEnquerybyUuid(location?.state?.itemUuid);
      let singleCorporateRecord = {
        'Event Type': response?.type,
        Description: response?.data?.description
          ? response?.data?.description
          : '-',
        'Session Fee':
          response?.data?.fee || response?.data?.session_fee
            ? response?.data?.fee || response?.data?.session_fee
            : '-',
        Date:
          response?.data?.date || response?.data?.selected_date
            ? response?.data?.date || response?.data?.selected_date
            : '-',
        Time:
          response?.data?.time || response?.data?.selected_time
            ? response?.data?.time || response?.data?.selected_time
            : '-',
        'Event Topic':
          response?.data?.topic?.label || response?.data?.topic
            ? response?.data?.topic?.label || response?.data?.topic
            : '-',
        'Registered User': response?.data?.registered_user,
        Email: response?.data?.email,
        'Number of Participants': response?.data?.number_of_participants
          ? response?.data?.number_of_participants
          : '-',
        Mobile: response?.data?.mobile ? response?.data?.mobile : '-',
      };
      setDetails(singleCorporateRecord);
    } catch (error) {
      console.log('error in getting-single-metorship-details', error);
    }
  };

  useEffect(() => {
    getSingleCorporateDetails();
  }, []);

  return (
    <Wrapper topBarActiveOption={'Events'}>
      <ViewDetailsCard
        heading={'Corporate Enquery'}
        description={'Enquery details and Information'}
        primaryDetails={details}
        navigationLink={'/corporate'}
      />
    </Wrapper>
  );
}

export default ViewCorporate;
