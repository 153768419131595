import { axiosObject } from '../setup';



function createCriteria(payload) {
    return new Promise((resolve, reject) => {
        let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/auto-assign-conditions`;

        axiosObject
            .post(uri, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errors) => {
                if (errors.response.status === 422) {
                    reject(errors.response.data.errors);
                }

                reject(errors);
            });

    });
}

export { createCriteria };
