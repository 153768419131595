/* eslint-disable no-unreachable */
import React from 'react';

function OfferedProducts({ heading, data, emptyMessage, ...props }) {
  return (
    <div>
      <div className="bg-behind-template-bg flex w-full justify-center p-5">
        <div className="border-1 template-border-2 mt-7 block min-h-[38.1875rem] w-3/5 min-w-[45.3125rem] border bg-white py-1 pl-10 pt-10 pb-10">
          <h2 className="mt-10 pb-5">{heading}</h2>
          <div className="pt-[0.625rem]">
            {data?.length > 0 ? (
              data.map((item) => {
                return (
                  <div className="pb-[0.3125rem]">
                    <div className="flex py-1">
                      <p className="text-value-paragraph-color flex-1 text-xs">
                        {item.label}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-empty-data-color text-xs">{emptyMessage}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferedProducts;
