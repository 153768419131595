import { axiosObject } from '../../setup';

function markProfileCompleted(type) {
  return new Promise((resolve, reject) => {
    const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profiles`;
    const payload = {
      type: type,
      status: 'completed',
      data: {},
    };
    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

export { markProfileCompleted };
