import { axiosObject } from '../setup';

function getPairs() {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/currency-pairs`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', errors);
        reject(errors);
      });
  });
}

function getAllRatesForPair(pair) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/all-rates?pair=${pair}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', errors);
        reject(errors);
      });
  });
}

export { getPairs, getAllRatesForPair };
