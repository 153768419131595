import Wrapper from './ProductsHeaders/Wrapper';
import Table from './ProductsTable';
import { useNavigate } from 'react-router-dom';

export default function () {
  let navigate = useNavigate();

  return (
    <Wrapper topBarActiveOption={'Products'} active="products">
      <div className="flex">
        <div className="w-full overflow-hidden">
          <Table
            callAction={(d, action) => {
              navigate(`/profiles/${d.uuid}`, {
                state: {
                  itemUuid: d?.uuid,
                },
              });
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}
