const fmSellerConstraints = {
  currency_type: {
    presence: {
      allowEmpty: false,
      message: '^Please select a currency type',
    },
  },
  range: {
    presence: {
      allowEmpty: false,
      message: '^Please select a range',
    },
  },
  product_segment: {
    presence: {
      allowEmpty: false,
      message: '^Please select a Product Segment',
    },
  },

  designation: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your designation',
    },
    type: 'string',
  },
};

const fmBuyerConstraints = {
  designation: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your designation',
    },
  },
};

function getProfileConstraints(type = '') {
  let constraints = {};
  switch (type) {
    case 'fm-seller':
      constraints = fmSellerConstraints;
      break;
    case 'fm-buyer':
      constraints = fmBuyerConstraints;
    default:
      break;
  }
  return constraints;
}

export default getProfileConstraints;
