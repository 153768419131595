import Wrapper from './LeadsHeaders/Wrapper';
import Table from './Table';

export default function () {
  return (
    <Wrapper>
      <div className="flex">
        <div className="w-full overflow-hidden">
          <Table />
        </div>
      </div>
    </Wrapper>
  );
}
