import { createSlice } from '@reduxjs/toolkit';

export const fmProfileSlice = createSlice({
  name: 'fmProfiles',
  initialState: {
    fmSeller: {
      uuid: '',
      type: '',
      data: {},
      status: '',
    },
    fmBuyer: {
      uuid: '',
      type: '',
      data: {},
      status: '',
    },
  },
  reducers: {
    setFmSellerProfile: (state, action) => {
      state.fmSeller.uuid = action.payload.uuid;
      state.fmSeller.type = action.payload.type;
      state.fmSeller.data = action.payload.data;
      state.fmSeller.status = action.payload.status;
    },
    setFmBuyerProfile: (state, action) => {
      state.fmBuyer.uuid = action.payload.uuid;
      state.fmBuyer.type = action.payload.type;
      state.fmBuyer.data = action.payload.data;
      state.fmBuyer.status = action.payload.status;
    },

    clearProfile: (state) => {
      state.fmSeller.uuid = null;
      state.fmSeller.type = null;
      state.fmSeller.data = null;
      state.fmSeller.status = null;
      state.fmBuyer.uuid = null;
      state.fmBuyer.type = null;
      state.fmBuyer.data = null;
      state.fmBuyer.status = null;
    },
  },
});

export const {
  setFmSellerProfile,
  setFmBuyerProfile,
  clearProfile,
} = fmProfileSlice.actions;

export default fmProfileSlice.reducer;
