import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { classNames } from '@reusejs/react-utils';

export default function ViewDetailsCard({
  heading,
  description,
  primaryDetails,
  secondaryDetails,
  navigationLink,
}) {
  const navigate = useNavigate();
  return (
    <div className="mx-5 mt-10 mb-10 overflow-hidden rounded-lg border bg-white shadow sm:rounded-lg">
      <div class="flex justify-between pr-5">
        <div className="px-4 py-5 sm:px-6">
          <h3 class="text-lg font-medium leading-6 text-gray-900">{heading}</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
        </div>
        <div
          className="flex cursor-pointer flex-col items-center justify-center"
          onClick={() => navigate(navigationLink)}
        >
          <ArrowNarrowLeftIcon className="h-5 w-5" />
          <p>Back</p>
        </div>
      </div>

      {Object.entries(primaryDetails).map((arr, index) => {
        return (
          <div
            key={index}
            className={classNames(
              index === 0 ? 'border-t border-gray-200' : ''
            )}
          >
            <dl>
              <div
                className={classNames(
                  index % 2 !== 0
                    ? 'bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'
                    : 'bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'
                )}
              >
                <dt className="text-sm font-medium text-gray-500">{arr[0]}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {arr[1]}
                </dd>
              </div>
            </dl>
          </div>
        );
      })}

      {/* {Object.entries(secondaryDetails).map((arr) => {
        return (
          <div className="border-t border-gray-200">
            <dl>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{arr[0]}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {arr[1]}
                </dd>
              </div>
            </dl>
          </div>
        );
      })} */}
    </div>
  );
}
