import React, { useEffect, useState } from 'react';
import { getFile } from '@nx-monorepo/services';

function SellerCompanyDetails({
  details,
  locationDetails,
  topBuyerData,
  financialPartnersData,
  profilePicUrl,
  ...props
}) {
  const [profilePicURL, setProfilePicURL] = useState(null);

  const getProfilePicURL = async () => {
    if (profilePicUrl && (profilePicUrl !== '' || undefined || null)) {
      let response = await getFile(profilePicUrl);
      setProfilePicURL(response?.download_url);
    } else {
      setProfilePicURL('https://saltadorarchitects.com/images/team/01.jpg');
    }
  };

  useEffect(() => {
    getProfilePicURL();
  }, []);

  return (
    <div>
      <div className="bg-behind-template-bg flex w-full justify-center p-5">
        <div className="border-1 border-template-border-2 mt-7 block w-3/5 border bg-white py-1 pl-10 pt-10 pb-10">
          <div className="flex justify-center">
            <img
              src={profilePicURL}
              alt={profilePicURL}
              className="h-28 w-28 rounded-[100vh]"
            />
          </div>
          <h2 className="mt-10 pb-5">Company Details</h2>

          {Object.entries(details).map((arr) => {
            return (
              <div className="flex py-1">
                <p className="text-key-paragraph-color flex-1 text-xs">
                  {arr[0]}
                </p>
                <p className="text-value-paragraph-color flex-1 text-xs font-medium">
                  {arr[1]}
                </p>
              </div>
            );
          })}

          {locationDetails['Company Address'] !== undefined ||
          null ||
          ('' && locationDetails['Company Website'] !== undefined) ||
          null ||
          ('' && locationDetails['Company Description'] !== undefined) ||
          null ||
          '' ? (
            <h2 className="mt-10 pb-5">Additional company details</h2>
          ) : null}
          {topBuyerData.length > 0 || financialPartnersData.length > 0
            ? locationDetails['Company Address'] !== undefined ||
              null ||
              ('' && locationDetails['Company Website'] !== undefined) ||
              null ||
              ('' && locationDetails['Company Description'] !== undefined) ||
              null ||
              ''
              ? Object.entries(locationDetails).map((arr) => {
                  return (
                    <div className="flex py-1">
                      <p className="text-key-paragraph-color flex-1 text-xs">
                        {arr[0]}
                      </p>
                      <p className="text-value-paragraph-color flex-1 text-xs font-medium">
                        {arr[1]}
                      </p>
                    </div>
                  );
                })
              : null
            : null}

          {topBuyerData.length > 0 ? (
            <h2 className="mt-10 pb-5">Top Buyers: </h2>
          ) : null}
          {topBuyerData.length > 0 ? (
            <div className="pt-[0.625rem]">
              {topBuyerData?.length > 0 ? (
                topBuyerData.map((item) => {
                  return (
                    <div className="pb-[0.3125rem]">
                      <div className="flex py-1">
                        <p className="text-value-paragraph-color flex-1 text-xs font-medium">
                          {item}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-empty-data-color text-xs">No Buyer Data.</p>
              )}
            </div>
          ) : null}

          {financialPartnersData.length > 0 ? (
            <h2 className="mt-10 pb-5">Financial Partners: </h2>
          ) : null}
          {financialPartnersData.length > 0 ? (
            <div className="pl-5">
              {financialPartnersData?.length > 0 ? (
                financialPartnersData.map((item) => {
                  return (
                    <div className="pb-[0.3125rem]">
                      <div className="flex py-1">
                        <p className="text-value-paragraph-color flex-1 text-xs font-medium">
                          {item}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-empty-data-color text-xs">
                  No Partners Data.
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SellerCompanyDetails;
