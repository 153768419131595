import React, { useState } from 'react';
import Wrapper from './CorporateHeaders/Wrapper';
import Table from './Table';
import { useNavigate, useParams } from 'react-router-dom';
import ViewCorporate from './ViewCorporate';

function List() {
  let { uuid } = useParams();
  const [mode, setMode] = useState(uuid === undefined ? 'list' : 'single');
  const [action, setAction] = useState('view');
  let navigate = useNavigate();
  return (
    <Wrapper topBarActiveOption={'Corporate'}>
      <div className="flex">
        <div className="w-full overflow-hidden">
          <Table
            mode={mode}
            callAction={(d, action) => {
              setMode('single');
              setAction(action);
              navigate(`/corporate/${d.uuid}`, {
                state: {
                  itemUuid: d?.uuid,
                },
              });
            }}
          />
          {mode === 'single' && (
            <div className="h-96 w-2/3 bg-gray-50">
              {action === 'view' && <ViewCorporate />}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default List;
