const fmSellerConstraints = {
  company_name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter company name',
    },
  },

  country: {
    presence: {
      allowEmpty: false,
      message: '^please select country',
    },
  },

  city: {
    presence: {
      allowEmpty: false,
      message: '^please select city ',
    },
  },
  industry_classification: {
    presence: {
      allowEmpty: false,
      message: '^please select entity status ',
    },
  },
};

const fmBuyerConstraints = {
  company_name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter company name',
    },
  },

  country: {
    presence: {
      allowEmpty: false,
      message: '^Please select country',
    },
  },

  city: {
    presence: {
      allowEmpty: false,
      message: '^Please select city ',
    },
  },

  currency_type: {
    presence: {
      allowEmpty: false,
      message: '^Please select currency type ',
    },
  },
  range: {
    presence: {
      allowEmpty: false,
      message: '^Please select Company Turnover ',
    },
  },
};
function getCompanyConstrains(type = '') {
  let constraints = {};
  switch (type) {
    case 'fm-seller':
      constraints = fmSellerConstraints;
      break;
    case 'fm-buyer':
      constraints = fmBuyerConstraints;
    default:
      break;
  }
  return constraints;
}
export default getCompanyConstrains;
