import { axiosObject } from '../setup';
import baseValidator from '../baseValidator';

function requestOTP(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/request-verify`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        // console.log('request otp response:', response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

const constraints = {
  otp: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your otp',
    },
    length: {
      minimum: 6,
      message: '^Invalid OTP. please try again',
    },
  },
};

function verifyOTP(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/verify-reset-password`;

    var jsValidationErrors;

    jsValidationErrors = baseValidator(payload, constraints);

    if (jsValidationErrors === true) {
      axiosObject
        .post(uri, payload)
        .then((response) => {
          console.log();
          resolve(payload);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { requestOTP, verifyOTP };
