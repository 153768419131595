import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const eventConstraints = {
  type: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Type of event',
    },
  },
  name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter name',
    },
  },

  date: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Date',
    },
  },
  time: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Time',
    },
  },

  speaker_name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter speaker name',
    },
  },
  ticket_price: {
    presence: {
      allowEmpty: false,
      message: '^Please enter ticket price',
    },
  },
  company_name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter company name',
    },
  },
  designation: {
    presence: {
      allowEmpty: false,
      message: '^Please enter designation',
    },
  },
  description: {
    presence: {
      allowEmpty: false,
      message: '^Please enter event description',
    },
  },

  about_speaker: {
    presence: {
      allowEmpty: false,
      message: '^Please enter about speaker',
    },
  },
};

function validateEvent(payload) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    jsValidationErrors = baseValidator(payload, eventConstraints);

    if (jsValidationErrors === true) {
      resolve(jsValidationErrors);
    } else {
      reject(jsValidationErrors);
    }
  });
}

function createEvent(payload) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/events';
    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        // console.log('backend errors:', errors);
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getEvents(params) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/events';

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getEventByUuid(eventUuid = '') {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/events/${eventUuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getAllEvents(withRegistrationCount = true) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/all-events';

    let params = {
      withRegistrationCount: withRegistrationCount,
    };

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getOrdersByEventId(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/all-orders/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function updateEvent(id, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/events/${id}`;
    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getOrders(params) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/orders';

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}
function createOrder(payload) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/orders';
    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        // console.log('backend errors:', errors);
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getOrdersByUuid(orderUuid = '') {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/orders/${orderUuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}
function updateOrder(id, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/orders/${id}`;
    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export {
  validateEvent,
  createEvent,
  getEvents,
  updateEvent,
  getAllEvents,
  getOrders,
  getOrdersByUuid,
  updateOrder,
  getEventByUuid,
  createOrder,
  getOrdersByEventId,
};
