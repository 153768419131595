import { useNavigate } from 'react-router-dom';
import { classNames } from '@reusejs/react-utils';
const tabs = [
  { tabKey: 'mentees', name: 'Mentees', href: '/mentees' },
  {
    tabKey: 'mentors',
    name: 'Mentors',
    href: '/mentors',
  },
  {
    tabKey: 'councellors',
    name: 'Professional Councellors',
    href: '/professional-counsellors',
  },
];

export default function Example({ active = null }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="border-b">
        <div>
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={classNames(
                  'relative flex w-48 justify-center bg-white py-5 text-center text-sm',
                  tab.tabKey === active
                    ? 'border-b-2 border-blue-500 text-blue-700'
                    : 'border-b'
                )}
                onClick={() => {
                  navigate(tab.href);
                }}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
