export default function ({ deleteCallback = () => {}, ...props }) {
    return (
      <div className="z-[1000] relative pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:max-w-lg sm:mx-auto sm:rounded-lg sm:px-10 text-m text-white-600 mb-1 font-medium">
        <div>
          <p>{props.message}</p>
        </div>
        <div className="flex justify-center mt-3.5">
          <button
            type="submit"
            className="p-2 rounded h-auto w-auto bg-blue-500 text-white"
            onClick={() => {
              deleteCallback();
              props.resolveModal(false);
            }}
          >
            Confirm
          </button>
          <button
            type="submit"
            className="p-2 rounded h-auto w-auto ml-3.5 bg-gray-300 text-white"
            onClick={() => props.resolveModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
  