import { axiosObject } from '../setup';
import baseValidator from '../baseValidator';

const marketViewConstraints = {
  title: {
    presence: {
      allowEmpty: false,
      message: '^Please enter title',
    },
  },
  description: {
    presence: {
      allowEmpty: false,
      message: '^Please enter description',
    },
  },
};

function addMarketView(payload) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;
    jsValidationErrors = baseValidator(payload, marketViewConstraints);

    if (jsValidationErrors === true) {
      let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/market-views`;
      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function updateMarketView(payload, id) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;
    jsValidationErrors = baseValidator(payload, marketViewConstraints);
    if (jsValidationErrors === true) {
      let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/market-views/${id}`;
      axiosObject
        .put(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function getMarketViews() {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/market-views`;
    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

function getMarketView(id) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/market-views/${id}`;
    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

function deleteMarketView(id) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/market-views/${id}`;
    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}
export {
  addMarketView,
  updateMarketView,
  getMarketViews,
  getMarketView,
  deleteMarketView,
};
