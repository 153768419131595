import { marked } from 'marked';
import { classNames } from '@reusejs/react-utils';

marked.setOptions({
    gfm: true,
    breaks: true,
    headerIds: false,
  });

const MarkdownPreview = ({ content = "" , previewBoxClass=""}) => {
    return (
      <div className="markdown-preview-container mt-4 flex relative">
        <div 
          className={classNames("markdown-body github-markdown-body p-4 border rounded-md bg-white overflow-auto",previewBoxClass)}
          dangerouslySetInnerHTML={{ __html: marked(content) }}
        />
      </div>
    );
  };

  export default MarkdownPreview;