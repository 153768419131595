import { axiosObject } from '../../setup';

const updateProductRequest = (uuid, form) => {
  return new Promise((resolve, reject) => {
    let payload = form.value;
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/product-request/${uuid}`;

    form.startProcessing();
    form.setErrors({});
    axiosObject
      .put(uri, payload)
      .then((response) => {
        form.finishProcessing();
        resolve(response.data);
      })
      .catch((errors) => {
        form.setErrors(errors);
        form.finishProcessing();
        reject(errors);
      });
  });
};

export { updateProductRequest };
