import baseValidator from '../../baseValidator';
import { axiosObject } from '../../setup';
import getProfileConstraints from '../Constraints/profileConstraints';
import { mobileStore } from '@nx-monorepo/store';

/**
{
    full_name: 'Rajiv',
    mobile_number: '9191919191',
    email: 'rajiv@betalectic.com',
    designation: 'CEO',
    currency_type: '',
    range: '',
    product_segment: '',
}
 */

//ui-validation.
function createProfile({ profileData = {}, status = '', type = '' }) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;
    const constraints = getProfileConstraints(type);
    jsValidationErrors = baseValidator(profileData, constraints);
    if (jsValidationErrors === true) {
      const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profiles`;

      profileData['full_name'] = mobileStore.getState().auth.user.name
        ? mobileStore.getState().auth.user.name
        : '';
      profileData['profile_picture'] = mobileStore.getState().auth.user
        .profile_picture
        ? mobileStore.getState().auth.user.profile_picture
        : '';
      profileData['email'] = mobileStore.getState().auth.user.email
        ? mobileStore.getState().auth.user.email
        : '';
      profileData['mobile'] = mobileStore.getState().auth.user.mobile
        ? mobileStore.getState().auth.user.mobile
        : '';

      if (type === 'fm-seller') {
        profileData['assign_wiredup_leads_to'] = 'me';
      }

      const payload = {
        type: type,
        data: profileData,
        status: status,
      };
      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { createProfile };
