import { createSlice } from '@reduxjs/toolkit';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    MessagesList: {
      messages: [],
    },
  },
  reducers: {
    setMessagesList: (state, action) => {
      state.MessagesList.messages = action.payload;
    },
  },
});

export const { setMessagesList } = chatSlice.actions;

export default chatSlice.reducer;
