import { axiosObject } from '../setup';
import baseValidator from '../baseValidator';

function createEnquiry({ type = '', data = {}, status = 'open' }) {
  return new Promise((resolve, reject) => {
    var jsValidationErrors;

    if (type === 'for_partner') {
      jsValidationErrors = true;
    }
    if (type === 'for_product') {
      jsValidationErrors = true;
    }
    if (jsValidationErrors === true) {
      let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/enquiries`;

      const payload = {
        type: type,
        data: data,
        status: status,
      };

      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { createEnquiry };
