import { createSlice } from '@reduxjs/toolkit';
// import { deregisterFirebaseToken } from '@nx-monorepo/services';

export const registeredUsersSlice = createSlice({
    name: 'registeredUsers',
    initialState: {
        usersLength: null
    },
    reducers: {
        setUsersLength: (state, action) => {
            state.usersLength = action.payload;
        },
    }
});

export const { setUsersLength } = registeredUsersSlice.actions;

export default registeredUsersSlice.reducer;
