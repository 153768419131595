import { useEffect, useState } from 'react';
import { getFile } from '@nx-monorepo/services';

function DataPaper({ heading, profileType, details, profilePicUrl, ...props }) {
  const [profilePicURL, setProfilePicURL] = useState(null);

  const getProfilePicURL = async () => {
    if (profilePicUrl && (profilePicUrl !== '' || undefined || null)) {
      let response = await getFile(profilePicUrl);
      setProfilePicURL(response?.download_url);
    } else {
      setProfilePicURL('https://saltadorarchitects.com/images/team/01.jpg');
    }
  };

  useEffect(() => {
    getProfilePicURL();
  }, []);

  return (
    <div>
      <div className="bg-behind-template-bg flex w-full justify-center p-5">
        <div className="border-1 template-border-2 mt-7 block w-3/5 border bg-white py-1 pl-10 pt-10 pb-10">
          <div className="flex justify-center">
            <img
              src={profilePicURL}
              alt={profilePicURL}
              className="h-28 w-28 rounded-[100vh]"
            />
          </div>
          <h2 className="mt-10 pb-5">{heading}</h2>

          {Object.entries(details).map((arr) => {
            return (
              <div className="flex py-1">
                <p className="text-key-paragraph-color flex-1 text-xs">
                  {arr[0]}
                </p>
                <p className="text-value-paragraph-color flex-1 text-xs font-medium">
                  {arr[1]}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DataPaper;
