import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

function getTopics(params) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/topics';

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        // console.log('response=====', response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export { getTopics };
