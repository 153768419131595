import { createStore } from 'redux';
import { combineReducers } from 'redux';
import authReducer from './slices/authSlice.js';
import themeReducer from './slices/themeSlice.js';
import fmProfileReducer from './slices/fmProfileSlice.js';
import chatReducer from './slices/chatSlice';
import fmQueriesReducer from './slices/queriesSlice';
import categoryReducer from './slices/quoraSlice';

let rootReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  fmProfiles: fmProfileReducer,
  chat: chatReducer,
  fmQueries: fmQueriesReducer,
  categories: categoryReducer,
});

let mobileStore = createStore(rootReducer);
export { mobileStore };
