import { axiosObject } from '../../setup';

function getContactUsLeads() {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/contact-us-leads`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { getContactUsLeads };
