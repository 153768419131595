import { axiosObject } from '../../setup';

function getAllProducts( perPage, page, search = '', categoryUuid ) {
  // console.log('category_uuid:', categoryUuid);
  return new Promise((resolve, reject) => {
    // const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/products?categories=${category_uuid}`;

    // let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/products?categories=${categoryUuid}&${
    //   search ? 'search=' + search : ''
    // }`;
    let uri = categoryUuid
      ? `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/products?categories=${categoryUuid}&${
          search ? 'search=' + search : ''
        }`
      : `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/products?${search ? 'search=' + search : ''}`;

    axiosObject
      .get(uri)
      .then((response) => {
        // console.log('response:', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function getProductListForProfile(type) {
  return new Promise((resolve, reject) => {
    const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profiles?type=${type}`;
    axiosObject
      .get(uri)
      .then((response) => {
        const data = response.data[0]['data'];
        resolve(data.offered_products);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function addProduct(form) {
  return new Promise((resolve, reject) => {
    let payload = form.value;
    const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/products`;

    form.startProcessing();
    form.setErrors({});
    axiosObject
      .post(uri, payload)
      .then((response) => {
        form.finishProcessing();
        console.log('products response:', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        form.setErrors(error);
        form.finishProcessing();
        reject(error);
      });
  });
}

function setProductListForProfile(products, type, status) {
  return new Promise((resolve, reject) => {
    const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profiles`;
    const payload = {
      type: type,
      data: {
        offered_products: products,
      },
      status: status,
    };
    console.log('payload', payload);
    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}
function getProductCategories(search = '') {
  return new Promise((resolve, reject) => {
    const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/categories?${
      search ? 'search=' + search : ''
    }`;
    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export {
  getAllProducts,
  addProduct,
  getProductCategories,
  getProductListForProfile,
  setProductListForProfile,
};
