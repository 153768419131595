import React, { useState } from 'react';
import { BlueButton } from '@reusejs/react-buttons';
import CloseIcon from '../assets/CloseIcon';
import { Simple as SimpleAlert } from '@reusejs/react-alerts';
import { currency_pairs } from './CurrencyPairs';
import baseValidator from 'libs/services/src/baseValidator';
import { createTradeOfTheDay } from '@nx-monorepo/services';

const constraints = {
  currency_pair: {
    presence: { allowEmpty: false, message: 'Currency pair is required' },
  },
  type: {
    presence: { allowEmpty: false, message: 'Type is required' },
    inclusion: {
      within: ['buy', 'sell'],
      message: "must be 'buy' or 'sell'",
    },
  },
  type_value: {
    presence: { allowEmpty: false, message: 'Type value is required' },
    numericality: {
      greaterThan: 0,
      message: 'must be > 0',
    },
  },
  take_profit_value: {
    presence: { allowEmpty: false, message: 'Take profit value is required' },
    numericality: {
      greaterThan: 0,
      message: 'must be > 0',
    },
  },
  stop_loss_value: {
    presence: { allowEmpty: false, message: 'Stop loss value is required' },
    numericality: {
      greaterThan: 0,
      message: 'must be > 0',
    },
  },
};

const showError = (error, id) => {
  if (error && error[id]) {
    return <label className="text-base text-red-400">{error[id]}</label>;
  }
  return null;
};

const CreatePopUp = ({
  submitOnRefresh = (initialState = false) => initialState,
  mode,
  ...props
}) => {
  const [error, setErrors] = useState(false);

  const handleSubmit = async (form_values) => {
    try {
      const jsValidationErrors = baseValidator(form_values, constraints);

      if (Object.keys(jsValidationErrors)?.length > 0) {
        setErrors(jsValidationErrors);
        SimpleAlert({
          message: 'Please check the input values',
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      } else {
        setErrors(false);
        const response = await createTradeOfTheDay([form_values]);
        submitOnRefresh(true);
        submitOnRefresh(false);
        props.resolveModal(false);
        if (response?.success) {
          SimpleAlert({
            message: 'Trade of the day Created Successfully',
            borderClasses: 'border-b-2 border-green-400',
            wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
            alignmentClasses: 'w-full items-center',
          });
        } else {
          SimpleAlert({
            message: 'Error Creating Trade of the Day value',
            borderClasses: 'border-b-2 border-red-400',
            wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
            alignmentClasses: 'w-full items-center',
          });
        }
      }
    } catch (error) {
      if (error.message)
        SimpleAlert({
          message: error.message,
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
    }
  };

  return (
    <form
      className="min-w-screen inline-block transform overflow-hidden rounded-lg px-4 pt-5 text-left text-[0.625rem] shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
      style={{
        fontSize: '10px',
        maxHeight: '685px',
        padding: '0',
        backgroundColor: '#FCFCFC',
        position: 'relative',
        zIndex: '0',
      }}
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const values = Object.fromEntries(formData.entries());

        handleSubmit(values);
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          width: '35px',
          height: '35px',
          borderRadius: '10px',
          backgroundColor: '#ececec',
        }}
        className="flex items-center justify-center"
        onClick={() => props.resolveModal(false)}
      >
        <CloseIcon />
      </div>

      <div style={{ padding: '40px' }}>
        <h3 className="text-lg font-bold">Create a new Trade of the Day</h3>
        <div className="mt-5 flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <label className="text-base font-semibold">Currency Pair</label>
            <select
              className="block w-full rounded border border-gray-400 bg-white px-3 py-2 text-sm text-black shadow outline-none focus:border-blue-400 focus:ring-blue-400 dark:border-gray-50 dark:bg-black dark:text-white"
              name="currency_pair"
              id="currency_pair"
            >
              {currency_pairs.map((currency_pair) => {
                const [baseCurrency, quoteCurrency] = currency_pair.split('/');
                return (
                  <option
                    key={currency_pair}
                    value={currency_pair}
                  >{`${baseCurrency} → ${quoteCurrency}`}</option>
                );
              })}
            </select>
            {showError(error, 'currency_pair')}
          </div>
          <div className="flex flex-col gap-y-1">
            <label className="text-base font-semibold">Type</label>
            <div className="flex w-full gap-x-2">
              <select
                className="block w-3/5 rounded border border-gray-400 bg-white px-3 py-2 text-sm text-black shadow outline-none focus:border-blue-400 focus:ring-blue-400 dark:border-gray-50 dark:bg-black dark:text-white"
                name="type"
                id="type"
              >
                <option value={'buy'}>Buy</option>
                <option value={'sell'}>Sell</option>
              </select>
              <input
                className="w-full rounded border border-gray-400 !bg-white px-3 py-2 text-base shadow outline-none focus:border-blue-400 focus:ring-blue-400 dark:border-gray-50 dark:bg-black dark:text-white"
                type="text"
                name="type_value"
                placeholder="Enter Value"
              />
            </div>
            {showError(error, 'type_value')}
            {showError(error, 'type')}
          </div>
          <div className="flex flex-col gap-y-1">
            <label className="text-base font-semibold">Take Profit</label>
            <input
              className="w-full rounded border border-gray-400 !bg-white px-3 py-2 text-base shadow outline-none focus:border-blue-400 focus:ring-blue-400 dark:border-gray-50 dark:bg-black dark:text-white"
              type="text"
              name="take_profit_value"
              placeholder="Enter Take Profit Value"
            />
            {showError(error, 'take_profit_value')}
          </div>
          <div className="flex flex-col gap-y-1">
            <label className="text-base font-semibold">Stop Loss</label>
            <input
              className="w-full rounded border border-gray-400 !bg-white px-3 py-2 text-base shadow outline-none focus:border-blue-400 focus:ring-blue-400 dark:border-gray-50 dark:bg-black dark:text-white"
              type="text"
              name="stop_loss_value"
              placeholder="Enter Stop Loss Value"
            />
            {showError(error, 'stop_loss_value')}
          </div>
        </div>
      </div>

      <div
        className="flex items-center justify-end"
        style={{
          height: '60px',
          marginTop: '',
          borderTop: '1px solid #EDEDED',
          backgroundColor: '#FFF',
        }}
      >
        <div className="mr-3 cursor-pointer hover:text-blue-600 md:w-auto">
          <BlueButton
            type="submit"
            widthClasses="w-30"
            fontClasses="text-sm font-semibold"
            borderClasses="rounded-lg"
          >
            Create
          </BlueButton>
        </div>
      </div>
    </form>
  );
};

export default CreatePopUp;
