import { axiosObject } from '../../setup';

function getAllUsers() {
    return new Promise((resolve, reject) => {
        let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/users`;

        axiosObject
            .get(uri)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export { getAllUsers };
