import React, { useState } from 'react';
import Wrapper from '../MentorShipHeaders/Wrapper';
import { useNavigate, useParams } from 'react-router-dom';
import Table from './MentorsTable';
import ViewMentor from './ViewMentor';

export default function () {
  let { uuid } = useParams();
  const [mode, setMode] = useState(uuid === undefined ? 'list' : 'single');
  const [action, setAction] = useState('view');
  let navigate = useNavigate();
  return (
    <Wrapper topBarActiveOption={'Events'} active="mentors">
      <div className="flex">
        <div className="w-full overflow-hidden">
          <Table
            mode={mode}
            callAction={(d, action) => {
              setMode('single');
              setAction(action);
              navigate(`/mentors/${d.uuid}`, {
                state: {
                  itemUuid: d?.uuid,
                },
              });
            }}
          />
          {mode === 'single' && (
            <div className="h-96 w-2/3 bg-gray-50">
              {action === 'view' && <ViewMentor mentorUuid={d.uuid} />}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
