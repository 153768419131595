function sanitizeDetails(additionalCompanyDetails) {
    const result = {}
    Object.keys(additionalCompanyDetails).forEach(
        key => {

            if (additionalCompanyDetails[key] instanceof Array) {
                const res = additionalCompanyDetails[key].filter(element => {
                    return (element &&
                        (
                            (typeof element === 'object' && !(element instanceof Array)
                                && Object.keys(element).some(k => element[k])))
                        || (typeof element === 'string' || element instanceof Array)
                    )
                });
                if (res.length > 0) result[key] = res;
            }
            else if (additionalCompanyDetails[key])
                result[key] = additionalCompanyDetails[key];
        }
    )
    return result;
}
export default sanitizeDetails;