import React, { useRef, useState } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
// import { useItemSelector } from '@reusejs/react-utils';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import { Loader } from '@reusejs/react-loaders';
import { getContactUsLeads } from '@nx-monorepo/services';
import formatDate from '../utils/formatDate';
import { setLeadsLength } from '@nx-monorepo/store';
import { useDispatch } from 'react-redux';
import EmptyState from '../components/reusables/EmptyState';
import moment from 'moment';

const Data = ({ response }) => {
  const tableRef = useRef(null);

  const fetchProfiles = () => {
    try {
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden mx-5 border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Name',
        identifier: 'name',
        resolver: (d) => {
          return (
            <div
              onClick={() => {
                return;
              }}
            >
              {d.name ? d.name : '-'}
            </div>
          );
        },
      },
      {
        label: 'Email',
        identifier: 'email',
        resolver: (d) => {
          return (
            <div className="text-column-blue">{d.email ? d.email : '-'}</div>
          );
        },
      },
      {
        label: 'Mobile',
        identifier: 'mobile_number',
        resolver: (d) => {
          return d.mobile_number ? d.mobile_number : '-';
        },
      },

      {
        label: 'Company',
        identifier: 'company_name',
        resolver: (d) => {
          return d.company_name ? d.company_name : '-';
        },
      },
      {
        label: 'Contacted At',
        identifier: 'created_at',
        resolver: (d) => {
          return d.createdAt
            ? moment(d.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
            : '-';
        },
      },
    ],
  };

  return (
    <div className="mt-10">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async (params) => {
          try {
            let response = await fetchProfiles();

            return {
              data: response.reverse(),
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const SectionLoader = () => {
  return (
    <div className="flex h-96 items-center justify-center">
      <Loader />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function () {
  const orchestratorRef = useRef(null);
  let dispatch = useDispatch();

  const handler = async () => {
    try {
      let result = await getContactUsLeads();
      console.log('result-123', result);

      if (result.length > 0) {
        dispatch(setLeadsLength(result.length));
      }

      if (result.length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }

      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  const emptyConfigHeaderCols = [
    {
      label: 'Name',
      identifier: 'name',
      resolver: () => {},
    },
    {
      label: 'Email',
      identifier: 'email',
      resolver: () => {},
    },
    {
      label: 'Mobile',
      identifier: 'mobile_number',
      resolver: () => {},
    },
    {
      label: 'Company',
      identifier: 'company_name',
      resolver: () => {},
    },
    {
      label: 'Contacted At',
      identifier: 'created_at',
      resolver: () => {},
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data {...props} />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
}
