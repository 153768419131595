import { axiosObject } from '../setup';

function reAssignQuery(payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/re-assign`;
    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        // console.log('errr', errors);
        reject(errors);
      });
  });
}

export { reAssignQuery };
