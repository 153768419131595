import { filterOptions } from '../utils';

const getProductSegments = (q = '') => {
  let data = [
    { label: 'Foreign Bank', value: 'Foreign Bank' },
    { label: 'Private Sector Bank', value: 'Private Sector Bank' },
    { label: 'Public Sector Bank', value: 'Public Sector Bank' },
    { label: 'Central Bank', value: 'Central Bank' },
    {
      label: 'Co-operative Bank',
      value: 'Co-operative Bank',
    },
    {
      label: 'Non-Bank Financial Company ( NBFC)',
      value: 'Non-Bank Financial Company ( NBFC)',
    },
    { label: 'Money Changers', value: 'Money Changers' },
    {
      label: 'Brokerage House',
      value: 'Brokerage House',
    },
    {
      label: 'Wealth Management Segment',
      value: 'Wealth Management Segment',
    },
    { label: 'Professional Services', value: 'Professional Services' },
    {
      label: 'Insurance',
      value: 'Insurance',
    },
    { label: 'Tax/Audit Consulting', value: 'Tax/Audit Consulting' },
    { label: 'Mutual Funds', value: 'Mutual Funds' },
    { label: 'Stock Market', value: 'Stock Market' },
    {
      label: 'PayTm Bank',
      value: 'PayTm Bank',
    },
    { label: 'Mortgage Segment', value: 'Mortgage Segment' },
    { label: 'Rating Agency', value: 'Rating Agency' },
    { label: 'Consulting', value: 'Consulting' },
    { label: 'Outsourcing Operations', value: 'Outsourcing Operations' },
    { label: 'Payment Gateways', value: 'Payment Gateways' },
    { label: 'Media and Communications', value: 'Media and Communications' },
    { label: 'Others', value: 'Others' },
  ];

  if (q !== '') {
    return filterOptions(data, q);
  } else {
    return data;
  }
};

export { getProductSegments };
