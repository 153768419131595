import React, { useState } from 'react';
import Wrapper from './MarketingViewsHeaders/Wrapper';
import Table from './Table';
import ViewMarketingView from './ViewMarketingView';
import { useNavigate, useParams } from 'react-router-dom';
import { BlueButton } from '@reusejs/react-buttons';
import PopupForm from './PopupForm';
import { Simple } from '@reusejs/react-modals';
import { classNames } from '@reusejs/react-utils';

export default function () {
  let { uuid } = useParams();
  const [mode, setMode] = useState(uuid === undefined ? 'list' : 'single');
  const [action, setAction] = useState('view');
  const [submitOnRefresh, setSubmitOnRefresh] = useState(false);
  let navigate = useNavigate();

  const openModal = async (item) => {
    await Simple({
      ModalContent: item,
    });
  };

  return (
    <Wrapper topBarActiveOption={'Marketing_Views'}>
      <div className="my-5 flex items-center justify-between px-5">
        <div className="flex">
          <div className="w-45 cursor-pointer hover:text-blue-600">
            <BlueButton
              widthClasses="w-full"
              fontClasses="text-sm font-semibold"
              borderClasses="rounded-lg"
              paddingClasses="px-4 py-3"
              busyText="Processing"
              onClick={() =>
                openModal(
                  React.forwardRef((props, ref) => {
                    return (
                      <span
                        ref={ref}
                        className="flex h-screen w-full items-center justify-center"
                      >
                        <PopupForm
                          submitOnRefresh={(cond) => setSubmitOnRefresh(cond)}
                          {...props}
                        />
                      </span>
                    );
                  })
                )
              }
            >
              + Add Market View
            </BlueButton>
          </div>
        </div>
      </div>
      <div className="flex">
        <div
          className={classNames(
            'my-5 overflow-hidden px-5',
            mode === 'list' ? 'w-full' : 'h-screen w-1/3 overflow-scroll'
          )}
        >
          <Table
            mode={mode}
            submitOnRefresh={submitOnRefresh}
            callAction={(d, action) => {
              setMode('single');
              setAction(action);
              navigate(`/marketing-views/${d.id}`);
            }}
          />
        </div>

        {mode === 'single' && (
          <div className="h-96 w-2/3 bg-gray-50">
            {action === 'view' && <ViewMarketingView />}
          </div>
        )}
      </div>
    </Wrapper>
  );
}
