import { axiosObject } from '../setup';

function getCategories() {
  return new Promise((resolve, reject) => {
    let uri = '/backend-address-book/categories';

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { getCategories };
