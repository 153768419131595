import { axiosObject } from '../setup';
import baseValidator from '../baseValidator';
const constraints = {
  topic: {
    presence: {
      allowEmpty: false,
      message: '^Please select your topic',
    },
  },
  date: {
    presence: {
      allowEmpty: false,
      message: '^Please select date',
    },
  },

  time: {
    presence: {
      allowEmpty: false,
      message: '^Please select time',
    },
  },
};
function corporateTraining(payload) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/enqueries';

    var jsValidationErrors;

    jsValidationErrors = baseValidator(payload.data, constraints);
    if (jsValidationErrors === true) {
      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function getEnqueries(params) {
  return new Promise((resolve, reject) => {
    let uri = '/backend-learn-earn/enqueries';

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getEnquerybyUuid(enqueryUuid) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/enqueries/${enqueryUuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getAllTopics(topicType) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-learn-earn/topics?topic_type=${topicType}`;
    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export { corporateTraining, getAllTopics, getEnqueries, getEnquerybyUuid };
