import baseValidator from '../../baseValidator';
import { axiosObject } from '../../setup';
/**
{
    full_name: 'Rajiv',
    mobile_number: '9191919191',
    email: 'rajiv@betalectic.com',
    designation: 'CEO',
    currency_type: '',
    range: '',
    product_segment: '',
}
 */

function updateProfileWithoutValidation({
  profileData = {},
  status = '',
  type = '',
  constraints,
}) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;
    jsValidationErrors = baseValidator(profileData, constraints);
    if (jsValidationErrors === true) {
      const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profiles`;
      const payload = {
        type: type,
        data: profileData,
        status: status,
      };
      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { updateProfileWithoutValidation as updateProfile };
