/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import { getFile } from '@nx-monorepo/services';

function CertificatesList({ heading, data, emptyMessage, ...props }) {
  const [files, setFiles] = useState(null);

  const getFilesByUuid = async () => {
    if (data.length > 0) {
      let filesArray = [];
      data.map(async (fileUuid) => {
        let categoryObj = {};
        let response = await getFile(fileUuid);
        categoryObj.download_url = response.download_url;
        categoryObj.file_name = response.file_name;
        filesArray.push(categoryObj);
        setFiles(filesArray);
      });
    } else {
      setFiles([]);
    }
  };

  useEffect(() => {
    getFilesByUuid();
  }, []);

  return (
    <div>
      <div className="bg-behind-template-bg flex w-full justify-center p-5">
        <div className="border-1 border-template-border-2 mt-7 block min-h-[38.1875rem] w-3/5 min-w-[45.3125rem] border bg-white py-1 pl-10 pt-10 pb-10">
          <h2 className="mt-10 pb-5">{heading}</h2>
          <div className="pt-[0.625rem]">
            {files?.length > 0 ? (
              files.map((item) => {
                return (
                  <div className="pb-[0.3125rem]">
                    <div className="flex py-1">
                      <a
                        className="flex-1 text-xs font-medium text-blue-700"
                        href={item.download_url}
                      >
                        {item.file_name}
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-empty-data-color text-xs">{emptyMessage}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CertificatesList;
