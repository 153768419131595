import { axiosObject } from '../setup';

function deleteCriteria(id) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/auto-assign-conditions/${id}`;
    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

export { deleteCriteria };
