import { axiosObject } from '../setup';

function getFavouriteCurrencyPairs() {
  return new Promise((resolve, reject) => {
    let uri = `/backend-fx/api/user_settings/favourites`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', errors);
        reject(errors);
      });
  });
}

function addFavourites(payload) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-fx/api/user_settings/favourites`;
    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        // console.log('backend errors:', errors);
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export { getFavouriteCurrencyPairs, addFavourites };
