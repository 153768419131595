import { ChevronDoubleRightIcon } from '@heroicons/react/solid';
import { classNames } from '@reusejs/react-utils';
import { useNavigate } from 'react-router-dom';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';

const TopHeader = (props) => {
  const navigate = useNavigate();
  return (
    <div className="z-0 flex w-full items-center justify-between border-b pr-10">
      <div>
        <div
          className={classNames(
            'pointer-events-none relative flex w-full items-center border-r bg-white !py-6 !pl-6 text-lg text-gray-900'
          )}
        >
          Hosted Sessions
          <ChevronDoubleRightIcon
            className={classNames('ml-3 h-4 w-4 text-blue-500')}
          />
          <div className="ml-3 !pr-6">{props.breadCrumbsProp}</div>
        </div>
      </div>
      <div
        className="flex cursor-pointer flex-col items-center justify-center"
        onClick={() => navigate('/registered-sessions')}
      >
        <ArrowNarrowLeftIcon className="h-5 w-5" />
        <p>Back</p>
      </div>
    </div>
  );
};

export default TopHeader;
