import TopHeader from './TopHeader';

export default function (props) {
  return (
    <div>
      <TopHeader
        topBarActiveOption={props.topBarActiveOption}
        showButton={props.showButton}
        buttonClickCallback={props.buttonClickCallback}
      />
      {props.children}
    </div>
  );
}
