const list = [
  { label: 'Agribusiness', value: 'Agribusiness' },
  { label: 'Automobiles', value: 'Automobiles' },
  { label: 'Capital Goods', value: 'Capital Goods' },
  { label: 'Chemicals', value: 'Chemicals' },
  {
    label: 'Construction and Building Products',
    value: 'Construction and Building Products',
  },
  {
    label: 'Education and Training',
    value: 'Education and Training',
  },
  {
    label: 'Energy & Power',
    value: 'Energy & Power',
  },
  {
    label: 'Food and Beverages',
    value: 'Food and Beverages',
  },

  {
    label: 'Forest, Paper, and Packaging Products',
    value: 'Forest, Paper, and Packaging Products',
  },
  {
    label: 'Healthcare',
    value: 'Healthcare',
  },
  {
    label: 'Household Personal, Durable & Leisure Products',
    value: 'Household Personal, Durable & Leisure Products',
  },
  {
    label: 'Media & Entertainment',
    value: 'Media & Entertainment',
  },
  {
    label: 'Metals & Mining',
    value: 'Metals & Mining',
  },
  {
    label: 'Pharmaceuticals',
    value: 'Pharmaceuticals',
  },
  {
    label: 'Professional Services',
    value: 'Professional Services',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Technology',
    value: 'Technology',
  },
  {
    label: 'Telecom',
    value: 'Telecom',
  },
  {
    label: 'Textiles & Apparel',
    value: 'Textiles & Apparel',
  },
  {
    label: 'Transportation and Logistics',
    value: 'Transportation and Logistics',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Aerospace',
    value: 'Aerospace',
  },
  {
    label: 'IT/ITES',
    value: 'IT/ITES',
  },
  {
    label: 'Hospitality',
    value: 'Hospitality',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Music',
    value: 'Music',
  },
  {
    label: 'Packaging',
    value: 'Packaging',
  },
  {
    label: 'Electronics',
    value: 'Electronics',
  },
  {
    label: 'E-Commerce',
    value: 'E-Commerce',
  },

  {
    label: 'Banking',
    value: 'Banking',
  },
  {
    label: 'Auto Components',
    value: 'Auto Components',
  },
  {
    label: 'Aviation',
    value: 'Aviation',
  },
  {
    label: 'Biotechnology',
    value: 'Biotechnology',
  },
  {
    label: 'Cement',
    value: 'Cement',
  },
  {
    label: 'Consumer durables',
    value: 'Consumer durables',
  },
  {
    label: 'Defence Manufacturing',
    value: 'Defence Manufacturing',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'FMCG',
    value: 'FMCG',
  },
  {
    label: 'Gems and Jewellery',
    value: 'Gems and Jewellery',
  },
  {
    label: 'Infrastructure',
    value: 'Infrastructure',
  },
  {
    label: 'Insurance',
    value: 'Insurance',
  },
  {
    label: 'BPO',
    value: 'BPO',
  },
  {
    label: 'Medical Devices',
    value: 'Medical Devices',
  },
  {
    label: 'MSME',
    value: 'MSME',
  },
  {
    label: 'Oil and Gas',
    value: 'Oil and Gas',
  },
  {
    label: 'Ports',
    value: 'Ports',
  },
  {
    label: 'Power',
    value: 'Power',
  },
  {
    label: 'Railways',
    value: 'Railways',
  },
  {
    label: 'Renewable Energy',
    value: 'Renewable Energy',
  },
  {
    label: 'Steel',
    value: 'Steel',
  },
  //   {
  //     label: 'Textiles & Apparel',
  //     value: 'Textiles & Apparel',
  //   },
  {
    label: 'Tourism',
    value: 'Tourism',
  },
  {
    label: 'Dairy',
    value: 'Dairy',
  },
  {
    label: 'Fashion',
    value: 'Fashion',
  },
  {
    label: 'Food Processing',
    value: 'Food Processing',
  },
  {
    label: 'Garments',
    value: 'Garments',
  },
  {
    label: 'Jute',
    value: 'Jute',
  },
  {
    label: 'Leather',
    value: 'Leather',
  },
  {
    label: 'Plastics',
    value: 'Plastics',
  },
  {
    label: 'Polymers',
    value: 'Polymers',
  },
  {
    label: 'Rubber',
    value: 'Rubber',
  },
  {
    label: 'Shipping',
    value: 'Shipping',
  },
  {
    label: 'Solar',
    value: 'Solar',
  },
  {
    label: 'Sugar',
    value: 'Sugar',
  },
  {
    label: 'Tobacco',
    value: 'Tobacco',
  },
  {
    label: 'Toy',
    value: 'Toy',
  },
  {
    label: 'Tractor',
    value: 'Tractor',
  },
  {
    label: 'Others, Pls specify',
    value: 'Others, Pls specify',
  },
];
function getBuyerSegment() {
  return list;
}

export { getBuyerSegment };
