import { axiosObject } from '../../setup';

function updateCategoryRequest(uuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/gm-category-requests/${uuid}`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { updateCategoryRequest };
