import React, { useRef } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import SectionLoader from '../components/reusables/SectionLoader';
import EmptyState from '../components/reusables/EmptyState';
import { getProfilesBasedOnLocation } from '@nx-monorepo/services';

const Data = ({ response, callAction }) => {
  const tableRef = useRef(null);

  const fetchProfiles = () => {
    return response.data;
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden mx-5 border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Name',
        identifier: 'name',
        resolver: (d) => {
          return (
            <div
              className="cursor-pointer"
              onClick={() => callAction(d, 'view')}
            >
              {d.type === 'fx-live'
                ? d.data.name || d.data.username || d.data.full_name
                : d.data.full_name}
            </div>
          );
        },
        sortable: false,
      },
      {
        label: 'Email',
        identifier: 'email',
        resolver: (d) => {
          return (
            <div
              className="text-column-blue cursor-pointer"
              onClick={() => callAction(d, 'view')}
            >
              {d.type === 'fx-live'
                ? d.data.email
                  ? d.data.email
                  : '-'
                : d.data.email
                ? d.data.email
                : '-'}
            </div>
          );
        },
        sortable: false,
      },
      {
        label: 'Mobile',
        identifier: 'mobile',
        resolver: (d) => {
          return d.type === 'fx-live'
            ? d.data.mobile_number || d.data.mobile
              ? d.data.mobile_number || d.data.mobile
              : '-'
            : d.data.mobile
            ? d.data.mobile
            : '-';
        },
        sortable: false,
      },
      {
        label: 'User Type',
        identifier: 'user_type',
        resolver: (d) => {
          return d.type;
        },
        sortable: false,
      },
      {
        label: 'Onboarding Status',
        identifier: 'user_type',
        resolver: (d) => {
          return d.status;
        },
        sortable: false,
      },
    ],
  };

  return (
    <div className="mt-10">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async (params) => {
          try {
            let response = await fetchProfiles();

            return {
              data: response,
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function ({ callAction }) {
  const orchestratorRef = useRef(null);

  const handler = async () => {
    try {
      let result = await getProfilesBasedOnLocation();

      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }
      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  const emptyConfigHeaderCols = [
    {
      label: 'Name',
      identifier: 'name',
      resolver: () => {},
      sortable: false,
    },
    {
      label: 'Email',
      identifier: 'email',
      resolver: () => {},
      sortable: false,
    },
    {
      label: 'Mobile',
      identifier: 'mobile',
      resolver: () => {},
      sortable: false,
    },
    {
      label: 'User Type',
      identifier: 'user_type',
      resolver: () => {},
      sortable: false,
    },
    {
      label: 'Onboarding Status',
      identifier: 'user_type',
      resolver: () => {},
      sortable: false,
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data callAction={callAction} {...props} />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
}
