import { Fragment, useState, useEffect } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { RiVipCrownLine } from 'react-icons/ri';
import {
  FolderIcon,
  HomeIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  ShoppingBagIcon,
  ClipboardListIcon,
  ChartBarIcon,
  ChevronDownIcon,
  CalendarIcon,
  TicketIcon,
  OfficeBuildingIcon,
  UserGroupIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import { classNames } from '@reusejs/react-utils';
import UserIcon from '../lib/assets/UserIcon';
import { DropdownMenu } from '@reusejs/react-dropdowns';
import WiredUpLogo from './WireupLogo';

const userNavigation = [{ name: 'Sign out', href: '/login' }];

const DefaultLink = (props) => {
  let { href, children, ...rest } = props;
  return (
    <a href={href} {...rest}>
      {children}
    </a>
  );
};

export default function (props) {
  const navigation = [
    {
      name: 'Dashboard',
      // href: '/',
      href: null,
      icon: HomeIcon,
      current: props.activeTab === 'Dashboard' ? true : false,
    },
    {
      name: 'Users',
      href: '/users',
      // href: null,
      icon: UsersIcon,
      current: props.activeTab === 'Users' ? true : false,
    },
    // {
    //   name: 'Profiles',
    //   href: '/profiles',
    //   icon: FolderIcon,
    //   current: props.activeTab === 'Profiles' ? true : false,
    // },
    {
      name: 'Leads',
      href: '/leads',
      // href: null,
      icon: UsersIcon,
      current: props.activeTab === 'Leads' ? true : false,
    },
    // {
    //   name: 'Products',
    //   href: '/products',
    //   // href: null,
    //   icon: ShoppingBagIcon,
    //   current: props.activeTab === 'Products' ? true : false,
    // },
    // {
    //   name: 'Category Requests',
    //   href: '/category-requests',
    //   icon: ShoppingBagIcon,
    //   current: props.activeTab === 'Category Requests' ? true : false,
    // },
    // {
    //   name: 'Events',
    //   href: null,
    //   icon: CalendarIcon,
    //   collapse: true,
    //   collapsedHref: [
    //     {
    //       name: 'Hosted Sessions',
    //       href: '/registered-sessions',
    //       icon: TicketIcon,
    //       current: props.activeTab === 'HostedSessions' ? true : false,
    //     },
    //     {
    //       name: 'Corporate',
    //       href: '/corporate',
    //       // href: null,
    //       icon: OfficeBuildingIcon,
    //       current: props.activeTab === 'Corporate' ? true : false,
    //     },
    //     {
    //       name: 'Mentorship',
    //       href: '/mentees',
    //       // href: null,
    //       icon: UserGroupIcon,
    //       current: props.activeTab === 'Mentorship' ? true : false,
    //     },
    //   ],
    //   current: props.activeTab === 'Events' ? true : false,
    // },
    {
      name: 'Market Views',
      href: '/marketing-views',
      // href: null,
      icon: ClipboardListIcon,
      current: props.activeTab === 'Marketing_Views' ? true : false,
    },
    {
      name: 'Loan Hedged Specs',
      href: '/interest-rates',
      // href: null,
      icon: ChartBarIcon,
      current: props.activeTab === 'Loan_Hedged_Specs' ? true : false,
    },
    {
      name: 'Borrowing Cost Rates',
      href: '/borrowing-rates',
      // href: null,
      icon: ChartBarIcon,
      current: props.activeTab === 'Borrowing_Cost_Rates' ? true : false,
    },
    {
      name: 'Subscriptions',
      href: '/subcriptions',
      // href: null,
      icon: RiVipCrownLine,
      current: props.activeTab === 'Subscriptions' ? true : false,
    },
    {
      name: 'Trades of the Day',
      href: '/trades-of-the-day',
      // href: null,
      icon: OfficeBuildingIcon,
      current: props.activeTab === 'trades-of-the-day' ? true : false,
    },
    // {
    //   name: 'Queries',
    //   // href: '/queries',
    //   href: null,
    //   icon: XIcon,
    //   current: props.activeTab === 'Queries' ? true : false,
    // },
    // {
    //   name: 'Other Services',
    //   href: null,
    //   icon: MenuAlt2Icon,
    //   current: props.activeTab === 'OtherServices' ? true : false,
    // },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapseSubSidebar, setCollapseSubSidebar] = useState(false);

  useEffect(() => {
    if (props.dontCloseCollapseSideBar) {
      setCollapseSubSidebar(props.dontCloseCollapseSideBar);
    }
  }, [props.dontCloseCollapseSideBar]);

  return (
    <div className="z-[20]">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="z-1 fixed inset-0 flex md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="block w-full flex-shrink-0">
                {navigation.map((item) =>
                  item.collapse ? (
                    <div>
                      <DefaultLink
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current === props.activeTab || item.current
                            ? 'border-r-2 border-blue-500 bg-blue-100 text-blue-800'
                            : 'text-gray-600',
                          'group flex items-center py-4 pl-8 text-sm font-medium'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current === props.activeTab || item.current
                              ? 'text-blue-800'
                              : 'text-gray-400 group-hover:text-gray-400',
                            'mr-3 h-6 w-6 flex-shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                        {collapseSubSidebar ? (
                          <ChevronUpIcon
                            className="ml-1 h-5 w-6 flex-shrink-0 cursor-pointer"
                            onClick={() => {
                              setCollapseSubSidebar((prevState) => !prevState);
                            }}
                          />
                        ) : (
                          <ChevronDownIcon
                            className="ml-1 h-5 w-6 flex-shrink-0 cursor-pointer"
                            onClick={() => {
                              setCollapseSubSidebar((prevState) => !prevState);
                            }}
                          />
                        )}
                      </DefaultLink>
                      {collapseSubSidebar && (
                        <div className="flex h-full w-full flex-col pl-14">
                          {item?.collapsedHref.map((subSideBar) => {
                            return (
                              <div className="mb-5 ml-2 cursor-pointer">
                                <DefaultLink
                                  key={subSideBar.name}
                                  href={subSideBar.href}
                                  className={classNames(
                                    subSideBar.current === props.activeTab ||
                                      subSideBar.current
                                      ? 'text-blue-800'
                                      : 'text-gray-600',
                                    'group flex text-[0.8425rem] font-medium'
                                  )}
                                >
                                  <subSideBar.icon
                                    className={classNames(
                                      subSideBar.current === props.activeTab ||
                                        subSideBar.current
                                        ? 'text-blue-800'
                                        : 'text-gray-400 group-hover:text-gray-400',
                                      'mr-3 h-6 w-6 flex-shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {subSideBar.name}
                                </DefaultLink>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  ) : (
                    <DefaultLink
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current === props.activeTab || item.current
                          ? 'border-r-2 border-blue-500 bg-blue-100 text-blue-800'
                          : 'text-gray-600',
                        'group flex items-center py-4 pl-8 text-sm font-medium'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current === props.activeTab || item.current
                            ? 'text-blue-800'
                            : 'text-gray-400 group-hover:text-gray-400',
                          'mr-3 h-6 w-6 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </DefaultLink>
                  )
                )}
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden overflow-x-auto md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col border-r border-gray-200 bg-white">
          <div className="flex h-16 flex-shrink-0 border-b border-gray-200">
            <div className="pt-6 pl-7">
              <WiredUpLogo />
            </div>
          </div>
          <div className="flex flex-col"></div>

          <div className="block w-full flex-shrink-0 ">
            <div className="py-2">
              {navigation.map((item) =>
                item.collapse ? (
                  <div>
                    <DefaultLink
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current === props.activeTab || item.current
                          ? 'border-r-2 border-blue-500 bg-blue-100 text-blue-800'
                          : 'text-gray-600',
                        'group flex items-center py-2 pl-8 text-sm font-medium'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current === props.activeTab || item.current
                            ? 'text-blue-800'
                            : 'text-gray-400 group-hover:text-gray-400',
                          'mr-3 h-6 w-6 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                      {collapseSubSidebar ? (
                        <ChevronUpIcon
                          className="ml-1 h-5 w-6 flex-shrink-0 cursor-pointer"
                          onClick={() => {
                            setCollapseSubSidebar((prevState) => !prevState);
                          }}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-1 h-5 w-6 flex-shrink-0 cursor-pointer"
                          onClick={() => {
                            setCollapseSubSidebar((prevState) => !prevState);
                          }}
                        />
                      )}
                    </DefaultLink>
                    {collapseSubSidebar && (
                      <div className="flex h-full w-full flex-col pl-14">
                        {item?.collapsedHref.map((subSideBar) => {
                          return (
                            <div className="mb-5 ml-2 cursor-pointer">
                              <DefaultLink
                                key={subSideBar.name}
                                href={subSideBar.href}
                                className={classNames(
                                  subSideBar.current === props.activeTab ||
                                    subSideBar.current
                                    ? 'border-r-2 border-blue-600 text-blue-800'
                                    : 'text-gray-600',
                                  'group flex text-[0.8425rem] font-medium'
                                )}
                              >
                                <subSideBar.icon
                                  className={classNames(
                                    subSideBar.current === props.activeTab ||
                                      subSideBar.current
                                      ? 'text-blue-800'
                                      : 'text-gray-400 group-hover:text-gray-400',
                                    'mr-3 h-6 w-6 flex-shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {subSideBar.name}
                              </DefaultLink>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  <DefaultLink
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current === props.activeTab || item.current
                        ? 'border-r-2 border-blue-500 bg-blue-100 text-blue-800'
                        : 'text-gray-600',
                      'group flex items-center py-4 pl-8 text-sm font-medium'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current === props.activeTab || item.current
                          ? 'text-blue-800'
                          : 'text-gray-400 group-hover:text-gray-400',
                        'mr-3 h-6 w-6 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </DefaultLink>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4">
            <div className="flex flex-1">
              <div className="flex w-full md:ml-0">
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-cursor absolute inset-y-0 left-0 flex items-center">
                    {/* <ChevronDoubleLeftIcon
                        className="w-5 h-5"
                        aria-hidden="true"
                        onClick={() => alert('What to do?')}
                      /> */}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <DropdownMenu
                sections={[
                  {
                    items: [
                      {
                        label: 'Transfer Funds',
                        href: '/banking/transfer-funds',
                        active: false,
                      },
                      {
                        label: 'Add Invoice',
                        href: '/business/add-invoice',
                        active: false,
                      },
                      {
                        label: 'Add Bill',
                        href: '/business/add-bill',
                        active: false,
                      },
                      {
                        label: 'Add Transaction',
                        href: '/business/add-transaction',
                        active: false,
                      },
                    ],
                  },
                ]}
              />
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <button
                type="button"
                className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">View notifications</span>
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none">
                    <UserIcon />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                            onClick={() => {
                              if (item.name === 'Sign out') {
                                localStorage.clear();
                              }
                            }}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <main className="flex-1">{props.children}</main>
      </div>
    </div>
  );
}
