import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your name',
    },
    type: 'string',
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your email address',
    },
    email: {
      message: '^Please enter a valid email address',
    },
    type: 'string',
  },
  mobile: {
    presence: {
      allowEmpty: false,
      message: '^Please enter the mobile number',
    },
    format: {
      pattern: '^\\+[1-9]\\d{1,14}$',
      message: '^Please enter in +<isd><number> format',
    },
    numericality: {
      message: '^Please enter the valid number',
    },
    type: 'string',
  },
  organization: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your organization',
    },
    type: 'string',
  },
  designation: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your designation',
    },
    type: 'string',
  },
  department: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your department',
    },
    type: 'string',
  },
};

function createContact(payload) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    jsValidationErrors = baseValidator(payload, constraints);

    if (jsValidationErrors === true) {
      let uri = '/backend-address-book/contacts';

      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function validateContact(payload) {
  console.log('validate Contact:', payload);
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    jsValidationErrors = baseValidator(payload, constraints);

    if (jsValidationErrors === true) {
      resolve(jsValidationErrors);
    } else {
      reject(jsValidationErrors);
    }
  });
}

const updateContact = (uuid, payload) => {
  return new Promise((resolve, reject) => {
    let uri = `/backend-address-book/contacts/${uuid}`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
};

export { createContact, validateContact, updateContact };
