import React, { useRef, useEffect, useState } from 'react';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import SectionLoader from '../components/reusables/SectionLoader';
import EmptyState from '../components/reusables/EmptyState';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProfileById } from '@nx-monorepo/services';
import CloseIcon from '../assets/CloseIcon';
import UserIcon from '../assets/UserIcon';
import DataPaper from '../components/reusables/DataPaper';
import OfferedProducts from '../components/reusables/OfferedProducts';
import SellerCompanyDetails from '../components/reusables/SellerCompanyDetails';
import BuyerCompanyDetails from '../components/reusables/BuyerCompanyDetails';
import CertificatesList from '../components/reusables/CertificatesList';

const Data = ({ response }) => {
  const [sellerPersonalDetails, setSellerPersonalDetails] = useState({});
  const [buyerPersonalDetails, setBuyerPersonalDetails] = useState({});
  const [sellerCompanyDetails, setSellerCompanyDetails] = useState({});
  const [buyerCompanyDetails, setBuyerCompanyDetails] = useState({});
  const [
    sellerCompanyLocationDetails,
    setSellerCompanyLocationDetails,
  ] = useState({});
  const [
    buyerCompanyLocationDetails,
    setBuyerCompanyLocationDetails,
  ] = useState({});
  const [fxLiveDetails, setFxLiveDetails] = useState({});
  const navigate = useNavigate();

  let singleProfileData = response.data[0].data;
  let responseDetails = response.data[0];

  useEffect(() => {
    let sellerPersonalDetails = {
      'Full name': singleProfileData?.full_name,
      Email: singleProfileData?.email,
      Mobile: singleProfileData?.mobile ? singleProfileData?.mobile : '-',
      'Coverage Vertical': singleProfileData?.product_segment?.label
        ? singleProfileData?.product_segment?.label
        : '-',
      Designation: singleProfileData?.designation,
    };
    let buyerPersonalDetails = {
      'Full name': singleProfileData?.full_name,
      Email: singleProfileData?.email,
      Mobile: singleProfileData?.mobile ? singleProfileData?.mobile : '-',
      Designation: singleProfileData?.designation,
    };
    let sellerCompanyDetails = {
      'Company name': singleProfileData?.company_name,
      Country: singleProfileData?.country?.label,
      City: singleProfileData?.city?.label,
      'Company Turnover': `${singleProfileData?.currency_type?.label} ${singleProfileData?.range?.label}`,
    };
    let sellerCompanyLocationDetails = {
      'Company Address':
        singleProfileData?.additional_company_details?.company_address,
      'Company Website':
        singleProfileData?.additional_company_details?.company_website,
      'Company Description': singleProfileData?.additional_company_details
        ?.company_description
        ? singleProfileData?.additional_company_details?.company_description
        : '-',
    };
    let buyerCompanyDetails = {
      'Company name': singleProfileData?.company_name
        ? singleProfileData?.company_name
        : '-',
      Country: singleProfileData?.country?.label
        ? singleProfileData?.country?.label
        : '-',
      City: singleProfileData?.city?.label
        ? singleProfileData?.city?.label
        : '-',
      'Company Turnover': `${
        singleProfileData?.currency_type?.label
          ? singleProfileData?.currency_type?.label
          : '-'
      } ${
        singleProfileData?.range?.label ? singleProfileData?.range?.label : ''
      }`,
      'Privacy Preference':
        singleProfileData?.privacy_preference === true ? 'true' : 'false',
    };
    let buyerCompanyLocationDetails = {
      'Company Address': singleProfileData?.additional_company_details
        ?.company_address
        ? singleProfileData?.additional_company_details?.company_address
        : '-',
      'Company Website': singleProfileData?.additional_company_details
        ?.company_website
        ? singleProfileData?.additional_company_details?.company_website
        : '-',
      'Company Description': singleProfileData?.additional_company_details
        ?.company_description
        ? singleProfileData?.additional_company_details?.company_description
        : '-',
      'Entity Legal Status': singleProfileData?.additional_company_details
        ?.entity_status?.label
        ? singleProfileData?.additional_company_details?.entity_status?.label
        : '-',
      'Buyer Segment': singleProfileData?.additional_company_details
        ?.buyer_segment?.label
        ? singleProfileData?.additional_company_details?.buyer_segment?.label
        : '-',
    };
    let fxLiveDetails = {
      'User Name':
        singleProfileData?.name ||
        singleProfileData?.username ||
        singleProfileData?.full_name,
      Email: singleProfileData?.email ? singleProfileData?.email : '-',
      'Company Name': singleProfileData?.company_name
        ? singleProfileData?.company_name
        : '-',
      Mobile:
        singleProfileData?.mobile || singleProfileData?.mobile_number
          ? singleProfileData?.mobile || singleProfileData?.mobile_number
          : '-',
    };
    setSellerPersonalDetails(sellerPersonalDetails);
    setBuyerPersonalDetails(buyerPersonalDetails);
    setSellerCompanyDetails(sellerCompanyDetails);
    setBuyerCompanyDetails(buyerCompanyDetails);
    setSellerCompanyLocationDetails(sellerCompanyLocationDetails);
    setBuyerCompanyLocationDetails(buyerCompanyLocationDetails);
    setFxLiveDetails(fxLiveDetails);
  }, []);

  return (
    <>
      <div className="border-1 border-1 border-template-border flex w-full justify-between border bg-white p-5">
        <div className="flex gap-x-3">
          <UserIcon />
          <p className="text-blue-600">
            {responseDetails.type === 'fm-buyer'
              ? `Fm-Buyer`
              : responseDetails.type === 'fm-seller'
              ? `Fm-Seller`
              : responseDetails.type === 'fx-live'
              ? `Fx-Live`
              : ''}
          </p>
        </div>

        <div className="cursor-pointer" onClick={() => navigate('/profiles')}>
          <CloseIcon />
        </div>
      </div>
      {responseDetails.type === 'fm-seller' && (
        <DataPaper
          heading={`Personal Details`}
          details={sellerPersonalDetails}
          profilePicUrl={singleProfileData?.profile_picture}
        />
      )}
      {responseDetails.type === 'fm-buyer' && (
        <DataPaper
          heading={`Personal Details`}
          details={buyerPersonalDetails}
          profilePicUrl={singleProfileData?.profile_picture}
        />
      )}
      {responseDetails.type === 'fx-live' && (
        <DataPaper
          heading={`Fx Live Details`}
          details={fxLiveDetails}
          profilePicUrl={singleProfileData?.profile_picture}
        />
      )}

      {responseDetails.type === 'fm-seller' && (
        <SellerCompanyDetails
          details={sellerCompanyDetails}
          locationDetails={sellerCompanyLocationDetails}
          topBuyerData={
            singleProfileData?.additional_company_details?.top_buyer_name
              ? singleProfileData?.additional_company_details?.top_buyer_name
              : []
          }
          financialPartnersData={
            singleProfileData?.additional_company_details?.financial_Patners
              ? singleProfileData?.additional_company_details?.financial_Patners
              : []
          }
          profilePicUrl={singleProfileData?.logo}
        />
      )}
      {responseDetails.type === 'fm-buyer' && (
        <BuyerCompanyDetails
          details={buyerCompanyDetails}
          locationDetails={buyerCompanyLocationDetails}
          topBuyerData={
            singleProfileData?.additional_company_details?.top_buyer_name
              ? singleProfileData?.additional_company_details?.top_buyer_name
              : []
          }
          financialPartnersData={
            singleProfileData?.additional_company_details?.financial_Patners
              ? singleProfileData?.additional_company_details?.financial_Patners
              : []
          }
          countries={
            singleProfileData?.additional_company_details?.global_presence
              ? singleProfileData?.additional_company_details?.global_presence
              : []
          }
          profilePicUrl={singleProfileData?.logo}
          longTermCreditRating={
            singleProfileData?.additional_company_details
              ?.credit_rating_long_term
              ? singleProfileData?.additional_company_details
                  ?.credit_rating_long_term
              : []
          }
          shortTermCreditRating={
            singleProfileData?.additional_company_details
              ?.credit_rating_short_term
              ? singleProfileData?.additional_company_details
                  ?.credit_rating_short_term
              : []
          }
          categories={
            singleProfileData?.additional_company_details?.choosen_categories
              ? singleProfileData?.additional_company_details
                  ?.choosen_categories
              : []
          }
        />
      )}

      {responseDetails.type === 'fm-seller' ? (
        <OfferedProducts
          heading={'Offered Products'}
          data={singleProfileData?.offered_products}
          emptyMessage={'No Products Found'}
        />
      ) : null}
      {responseDetails.type === 'fm-seller' &&
      singleProfileData?.certificates?.length > 0 ? (
        <CertificatesList
          heading={'Certificates'}
          data={singleProfileData?.certificates}
          emptyMessage={'No Certifictes Found'}
        />
      ) : null}
    </>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function () {
  const orchestratorRef = useRef(null);
  const location = useLocation();

  const handler = async () => {
    try {
      let singleProfileUuid = location?.state?.itemUuid;

      let result = await getProfileById(singleProfileUuid);

      if (Object.keys(result.data).length === 0) {
        return {
          state: 'empty',
          data: result.data,
        };
      }

      return {
        state: 'data',
        data: result.data,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  return (
    <div>
      <DataLoadOrchestrator
        handler={handler}
        ref={orchestratorRef}
        components={{
          data: Data,
          loading: SectionLoader,
          empty: EmptyState,
          error: ErrorState,
        }}
      />
    </div>
  );
}
