import baseValidator from '../../baseValidator';
import { axiosObject } from '../../setup';
import getCompanyConstrains from '../Constraints/companyDetailsConstraints';
import sanitizeDetails from './sanitizeAdditionalDetails';
/**
{
	company_name: 'Betalectic',
	country: 'India',
	city: 'Hyderabad',
	entity_status: '',

}
 */

function addCompanyDetails({
  companyDetails = {},
  additionalCompanyDetails = {},
  status = '',
  type = '',
}) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;
    const constraints = getCompanyConstrains(type);
    jsValidationErrors = baseValidator(companyDetails, constraints);

    if (jsValidationErrors === true) {
      const uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/profiles`;
      const profileData = { ...companyDetails };

      const sanitizedAdditionalDetails = sanitizeDetails(
        additionalCompanyDetails
      );
      profileData['additional_company_details'] = {
        ...sanitizedAdditionalDetails,
      };

      const payload = {
        type: type,
        data: profileData,
        status: status,
      };

      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}
export { addCompanyDetails };
