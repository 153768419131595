import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const refinanceFormconstraints = {
  loan_currency: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Loan Currency',
    },
  },
  outstanding_loan_amount: {
    presence: {
      allowEmpty: false,
      message: '^Please Enter Loan Amount',
    },
  },

  loan_type: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Loan Type',
    },
  },

  loan_taken_date: {
    presence: {
      allowEmpty: false,
      message: '^Please Enter Loan Taken Date',
    },
  },

  maturity_date: {
    presence: {
      allowEmpty: false,
      message: '^Please Enter Maturity Date',
    },
  },

  interest_rate: {
    presence: {
      allowEmpty: false,
      message: '^Please Enter Interest Rate',
    },
  },
};

const corporateFinanceconstraints = {
  request_description: {
    presence: {
      allowEmpty: false,
      message: '^Please Enter Request Description',
    },
  },
  response_restriction: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Response Restriction',
    },
  },

  query_validity: {
    presence: {
      allowEmpty: false,
      message: '^Please Select Query Validity',
    },
  },
};
function validateRefinanceForm(payload) {
  return new Promise((resolve, reject) => {
    let jsValidationErrors;

    jsValidationErrors = baseValidator(payload, refinanceFormconstraints);

    if (jsValidationErrors === true) {
      resolve(jsValidationErrors);
    } else {
      reject(jsValidationErrors);
    }
  });
}

function createQuery({
  type = '',
  queriesData = {},
  status = '',
  sellers = {},
}) {
  return new Promise((resolve, reject) => {
    var jsValidationErrors;

    if (type === 'refinance_existing_loan') {
      jsValidationErrors = baseValidator(queriesData, refinanceFormconstraints);
    }
    if (type === 'corporate_finance_product') {
      jsValidationErrors = baseValidator(
        queriesData,
        corporateFinanceconstraints
      );
    }
    if (jsValidationErrors === true) {
      let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/queries`;

      const payload = {
        type: type,
        data: queriesData,
        status: status,

        sellers: sellers,
      };

      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function getQueries({ type = '', limit = 0 }) {
  return new Promise((resolve, reject) => {
    let uri =
      limit > 0
        ? `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/queries?type=${type}&limit=${limit}`
        : `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/queries?type=${type}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getRespondedSellers(query_uuid = '') {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/response?query_uuid=${query_uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

function updateResponse({ response_uuid = '', status = '' }) {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/response/${response_uuid}/quote`;
    const payload = {
      status: status,
    };

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export {
  createQuery,
  validateRefinanceForm,
  getQueries,
  getRespondedSellers,
  updateResponse,
};
