import React from 'react';
import {
  PageTitle,
  FormHeading,
  Paragraph,
} from '@reusejs/react-display-texts';

export default function AuthFormsHeadingSection({
  sectionHeading,
  formHeading,
  formSubHeading,
}) {
  return (
    <div className="mb-6">
      <PageTitle titleStyles="text-3xl font-semibold mb-8 text-blue-500">
        {sectionHeading}
      </PageTitle>
      <FormHeading textAndFontStyles="text-2xl font-semibold mb-1 text-blue-800">
        {formHeading}
      </FormHeading>
      <Paragraph fontSize="text-sm">{formSubHeading}</Paragraph>
    </div>
  );
}
