import React, { useState, useEffect, useRef } from 'react';
import useBetaForm from '@reusejs/react-form-hook';
import { BaseInput } from '@reusejs/react-text-inputs';
import { BlueButton } from '@reusejs/react-buttons';
import {
  addInterestRates,
  getInterestRates,
  updateInterestRates,
} from '@nx-monorepo/services';
import { TrashIcon } from '@heroicons/react/solid';
import { classNames, uuidv4 } from '@reusejs/react-utils';
import { Base as DataTable } from '@reusejs/react-tables';
import useDynamicItems from '../Hooks/useDynamicItems';
import formatDate from '../utils/formatDate';
import { Simple as SimpleAlert } from '@reusejs/react-alerts';

export default function ({
  submitOnRefresh = (initialState = false) => initialState,
  onEditSubmit = () => {},
  onClosePopup = () => {},
  mode,
  data,
  ...props
}) {
  const [prefilledTableRows, setPrefilledTableRows] = useState(null);
  const loanHedgedSpecsForm = useBetaForm({
    date: formatDate(new Date(), 'ymd'),
    data: [],
    remarks: '',
  });

  const tableRef = useRef(null);

  const fetchLoadHedgedSpecsFnRef = useRef(() => {});

  fetchLoadHedgedSpecsFnRef.current = async () => {
    if (mode === 'edit' && data) {
      try {
        const loadHedgedSpecs = await getInterestRates(data?.uuid);
        let mappedLoanHedgedSpecs = [];

        loanHedgedSpecsForm.setField('date', loadHedgedSpecs?.date);
        loanHedgedSpecsForm.setField('remarks', loadHedgedSpecs?.remarks);

        loadHedgedSpecs.data.map((lhs) => {
          let loanHedgedSpecsRow = {};
          loanHedgedSpecsRow['tenor'] = lhs?.tenor;
          loanHedgedSpecsRow['usd_interest_rates'] = lhs?.usd_interest_rates;
          loanHedgedSpecsRow['spread'] = lhs?.spread;
          loanHedgedSpecsRow['usd_net_rate'] = lhs?.usd_net_rate;
          loanHedgedSpecsRow['corresponding_inr_rate'] =
            lhs?.corresponding_inr_rate;

          loanHedgedSpecsRow['uuid'] = uuidv4();

          return mappedLoanHedgedSpecs.push(loanHedgedSpecsRow);
        });

        setPrefilledTableRows(mappedLoanHedgedSpecs);
      } catch (error) {
        SimpleAlert({
          message: 'Something went wrong',
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      }
    }
  };

  useEffect(() => {
    if (prefilledTableRows) {
      prefilledTableRows.map((loanHedgedSpecsRow) => {
        addNewItem(loanHedgedSpecsRow);
      });
    }
  }, [prefilledTableRows]);

  useEffect(() => {
    if (data && mode === 'edit') {
      fetchLoadHedgedSpecsFnRef.current();
    }
  }, [mode, data]);

  useEffect(() => {
    if (!props.showModal) {
      onClosePopup(true);
    }
  }, [props.showModal]);

  const eventListener = (event, data) => {
    tableRef.current.refresh();

    if (event === 'removed.item') {
      items.filter((item) => item.uuid !== data);
    }
  };

  const { addNewItem, removeItem, updateItem, items } = useDynamicItems(
    prefilledTableRows ? prefilledTableRows : [],
    {
      tenor: '',
      usd_interest_rates: '',
      spread: '',
      usd_net_rate: '',
    },
    eventListener
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    loanHedgedSpecsForm.startProcessing();
    try {
      if (mode === 'edit') {
        items.map((item) => {
          const { uuid, ...rest } = item;
          loanHedgedSpecsForm.value.data.push(rest);
        });
        await updateInterestRates(loanHedgedSpecsForm.value, data?.uuid);
        submitOnRefresh(true);
        submitOnRefresh(false);
        props.resolveModal(false);
      } else {
        items.map((item) => {
          const { uuid, ...rest } = item;
          loanHedgedSpecsForm.value.data.push(rest);
        });

        await addInterestRates(loanHedgedSpecsForm.value);
        submitOnRefresh(true);
        submitOnRefresh(false);
        props.resolveModal(false);
      }

      loanHedgedSpecsForm.finishProcessing();
    } catch (error) {
      loanHedgedSpecsForm.setErrors(error);
      return error;
    }
  };

  const config = {
    tableWrapperClasses: 'w-full h-96 border rounded-lg',
    tableClasses: 'w-full divide-y divide-white',
    headerClasses: 'relative px-6 py-4',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Tenor',
        identifier: 'p_s',
        resolver: (d) => {
          return (
            <div className={classNames('flex flex-col')}>
              <input
                value={d.tenor}
                className={classNames('rounded-md border p-2 outline-none')}
                onChange={(e) => {
                  updateItem(d.uuid, 'tenor', e.target.value);
                }}
              />
            </div>
          );
        },
        sortable: false,
      },
      {
        label: 'USD interest rates',
        identifier: 'p_s',
        resolver: (d) => {
          return (
            <input
              value={d.usd_interest_rates}
              className="rounded-md border p-2 outline-none"
              onChange={(e) => {
                updateItem(d.uuid, 'usd_interest_rates', e.target.value);
              }}
            />
          );
        },
        sortable: false,
      },
      {
        label: 'Spread',
        identifier: 'p_s',
        resolver: (d) => {
          return (
            <input
              value={d.spread}
              className="rounded-md border p-2 outline-none"
              onChange={(e) => {
                updateItem(d.uuid, 'spread', e.target.value);
              }}
            />
          );
        },
        sortable: false,
      },
      {
        label: 'USD Net rate',
        identifier: 'p_s',
        resolver: (d) => {
          return (
            <input
              className="rounded-md border p-2 outline-none"
              value={d.usd_net_rate}
              onChange={(e) => {
                updateItem(d.uuid, 'usd_net_rate', e.target.value);
              }}
            />
          );
        },
        sortable: false,
      },
      {
        label: 'Corresponding Fully Hedged INR Rate',
        identifier: 'p_s',
        resolver: (d) => {
          return (
            <input
              className="rounded-md border p-2 outline-none"
              value={d.corresponding_inr_rate}
              onChange={(e) => {
                updateItem(d.uuid, 'corresponding_inr_rate', e.target.value);
              }}
            />
          );
        },
        sortable: false,
      },
      {
        label: '',
        identifier: 'actions',
        resolver: (d) => {
          return (
            <div className="flex rounded-md">
              <div className="cursor-pointer px-2">
                <TrashIcon
                  className={classNames('h-4 w-4 text-red-400')}
                  onClick={() => {
                    removeItem(d.uuid);
                  }}
                />
              </div>
            </div>
          );
        },
        sortable: false,
      },
    ],
  };

  return (
    <form className="relative inline-block w-10/12 transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:p-6 sm:align-middle">
      <div>
        <h1 className="ml-2 mb-6 text-xl font-medium">
          {mode === 'edit'
            ? `Update Loan Hedged Specs`
            : `Create Loan Hedged Specs`}
        </h1>
        <div className="mt-0 ml-0 flex items-center border-b-2 pl-5 pb-5">
          <div className="flex-1">
            <div className="h-20 w-60">
              <BaseInput
                label="Select Date"
                type="date"
                placeholder="Enter date"
                defaultValue={loanHedgedSpecsForm.getField('date')}
                onChange={(val) => {
                  loanHedgedSpecsForm.setField('date', val);
                }}
                // max={formatDate(new Date(), 'ymd')}
                errorText={loanHedgedSpecsForm.errors.get('date')}
              />
            </div>
          </div>
          <div className="mt-5 flex-1 justify-between px-4 py-2">
            <BlueButton
              widthClasses="w-30 ml-auto"
              fontClasses="text-sm font-semibold"
              borderClasses="rounded-lg"
              onClick={() => {
                addNewItem();
              }}
            >
              Add Row +
            </BlueButton>
          </div>
        </div>

        <div className="my-6 w-full overflow-hidden border-b-2">
          <DataTable
            ref={tableRef}
            config={config}
            defaultSortColumn={0}
            perPage={5}
            noDataComponent={<div>Please add a row</div>}
            dataSource={async () => {
              return {
                data: items,
                pagination: {
                  total: 0,
                },
              };
            }}
          />
        </div>

        <div className="mt-5 flex flex-col">
          <p
            className={classNames(
              'text-sm',
              loanHedgedSpecsForm.errors.get('remarks') ? 'text-red-600' : ''
            )}
          >
            Remarks
          </p>
          <textarea
            value={loanHedgedSpecsForm.getField('remarks')}
            rows="6"
            placeholder="Enter remarks...."
            onChange={(e) =>
              loanHedgedSpecsForm.setField('remarks', e.target.value)
            }
            className={classNames(
              'mt-1 rounded-md border border-gray-300 p-3 text-sm shadow-lg focus:outline-none focus:ring-1 focus:ring-blue-400',
              loanHedgedSpecsForm.errors.get('remarks')
                ? 'border-none placeholder-red-300 ring-1 ring-red-600'
                : ''
            )}
          ></textarea>
          {loanHedgedSpecsForm.errors.get('remarks') ? (
            <p className="mt-2 text-sm text-red-600">
              {loanHedgedSpecsForm.errors.get('remarks')}
            </p>
          ) : null}
        </div>
        <div className="flex w-full">
          <div className="ml-auto flex space-x-5">
            <div className="mt-3">
              <BlueButton
                type="submit"
                widthClasses="w-30"
                fontClasses="text-sm font-semibold"
                borderClasses="rounded-lg"
                busyText="Processing"
                busy={loanHedgedSpecsForm.busy}
                onClick={(e) => handleSubmit(e)}
              >
                {mode === 'edit' ? `Update` : `Save`}
              </BlueButton>
            </div>
            <div className="mt-3">
              <BlueButton
                type="button"
                widthClasses="w-30 bg-red-500 hover:bg-red-600"
                fontClasses="text-sm font-semibold"
                borderClasses="rounded-lg"
                busyText="Processing"
                onClick={(e) => props.resolveModal(false)}
              >
                Cancel
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
