import { axiosObject } from '../setup';

function getFile(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/backend-files/show/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log('errr', errors);
        reject(errors);
      });
  });
}

export { getFile };
