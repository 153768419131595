import React, { useRef, useEffect, useState } from 'react';
import { DataLoadOrchestrator, classNames } from '@reusejs/react-utils';
import { useNavigate, useParams } from 'react-router-dom';
import { getMarketView } from '@nx-monorepo/services';
import moment from 'moment';
import { getPostFixDateString } from '../utils/getPostFixDateString';
import { updateMarketView } from '@nx-monorepo/services';
import formatDate from '../utils/formatDate';
import { BlueButton } from '@reusejs/react-buttons';
import SectionLoader from '../components/reusables/SectionLoader';
import EmptyState from '../components/reusables/EmptyState';
import { ArrowLeftIcon } from '@heroicons/react/solid';

const Data = ({ response, setRefreshOnPublish = () => {} }) => {
  const navigate = useNavigate();

  const handlePublish = async (payload) => {
    try {
      payload.status = 'published';
      await updateMarketView(payload, payload.uuid);
      setRefreshOnPublish(true);
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex items-center justify-between">
        <div
          className="ml-2 mt-5 h-5 w-5 cursor-pointer"
          onClick={() => navigate('/marketing-views')}
        >
          <ArrowLeftIcon />
        </div>

        {response?.data?.status === 'drafted' && (
          <div className="mr-3 mt-5 cursor-pointer hover:text-blue-600 md:w-auto">
            <BlueButton
              type="submit"
              widthClasses="w-full"
              fontClasses="text-sm font-semibold"
              borderClasses="rounded-lg"
              onClick={() => {
                handlePublish(response.data);
              }}
            >
              Publish
            </BlueButton>
          </div>
        )}
      </div>

      <div className="container mt-7 border border-[#eeeeee] bg-white p-10 md:container">
        <div className="flex">
          <h2 className="mb-5 ml-auto flex-1 text-right text-xl">
            Marketing View Details
          </h2>
          <div className="flex-1 justify-end text-right">
            <p>
              {response?.data?.status === 'drafted'
                ? `Draft saved on: ${formatDate(
                    new Date(response?.data?.created_at),
                    'ymd'
                  )}`
                : `Post published on: ${formatDate(
                    new Date(response?.data?.created_at),
                    'ymd'
                  )}`}
            </p>
            <p>
              Status:{' '}
              {response?.data?.status === 'drafted' ? (
                <span
                  className={classNames(
                    response?.data?.status === 'drafted'
                      ? 'text-orange-500'
                      : 'text-green-500'
                  )}
                >
                  Draft
                </span>
              ) : (
                <span
                  className={classNames(
                    response?.data?.status === 'drafted'
                      ? 'text-orange-500'
                      : 'text-green-500'
                  )}
                >
                  Published
                </span>
              )}
            </p>
          </div>
        </div>
        <div>
          <p className="text-sm text-[#6f6D75]">Title :</p>
          <p className="mt-2 text-base font-medium text-[#1F1D2A]">
            {' '}
            {`${response?.data?.title} - ${new Date(
              response?.data?.created_at
            ).getDate()}${getPostFixDateString(response?.data)} ${moment(
              new Date(response?.data?.created_at),
              'DD-MM-YYYY'
            ).format('MMMM')}`}
          </p>
        </div>
        <div className="mt-5">
          <p className="text-sm text-[#6f6D75]">Content :</p>
          <p className="mt-2 break-all text-base font-medium text-[#1F1D2A]">
            {response?.data?.description}
          </p>
        </div>
      </div>
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function () {
  const orchestratorRef = useRef(null);
  const { id } = useParams();
  const [reloadAfterPublish, setReloadAfterPublish] = useState(false);

  const handler = async () => {
    try {
      let result = await getMarketView(id);

      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }

      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  useEffect(() => {
    orchestratorRef.current.reload();
  }, [reloadAfterPublish]);

  return (
    <div>
      <DataLoadOrchestrator
        handler={handler}
        ref={orchestratorRef}
        components={{
          data: React.forwardRef((props, ref) => (
            <span ref={ref}>
              <Data
                setRefreshOnPublish={(val) => setReloadAfterPublish(val)}
                {...props}
              />
            </span>
          )),
          loading: SectionLoader,
          empty: EmptyState,
          error: ErrorState,
        }}
      />
    </div>
  );
}
