/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useRef, useState, useEffect } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import SectionLoader from '../components/reusables/SectionLoader';
import EmptyState from '../components/reusables/EmptyState';
import { Select } from '@reusejs/react-pickers';
import { BlueButton } from '@reusejs/react-buttons';
import { Simple } from '@reusejs/react-modals';
import { Simple as SimpleAlert } from '@reusejs/react-alerts';
import DeletePrompt from '../components/reusables/DeletePrompt';
import {
  getProductCategories,
  getProductRequests,
  addProduct,
  updateProductRequest,
} from '@nx-monorepo/services';
import { useNavigate } from 'react-router-dom';
import useBetaForm from '@reusejs/react-form-hook';

const Data = ({ response, reloadAfterReject = () => {} }) => {
  const navigate = useNavigate();
  const tableRef = useRef(null);

  const openModal = async (item) => {
    await Simple({
      ModalContent: item,
    });
  };

  const acceptProductRequestForm = useBetaForm({
    status: 'pending',
  });

  const addProductToMasterList = useBetaForm({
    name: '',
    categories: [],
  });

  const fetchCategories = async () => {
    try {
      const data = await getProductCategories();
      let categoriesArray = [];
      data.map((item) => {
        let obj = {};
        obj.label = item.name;
        obj.value = item.uuid;
        return categoriesArray.push(obj);
      });
      return categoriesArray;
    } catch (error) {
      console.log('error in fetching product categories', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchProductRequests = async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let _response = response.data;
        resolve(_response);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleAcceptProduct = async (e, product) => {
    addProductToMasterList.value.name = product.product_name;
    acceptProductRequestForm.value.status = 'accepted';
    e.preventDefault();
    try {
      if (addProductToMasterList.value.categories.length > 0) {
        let acceptRequest = await updateProductRequest(
          product.uuid,
          acceptProductRequestForm
        );
        let response = await addProduct(addProductToMasterList);
        navigate('/products');
      } else {
        SimpleAlert({
          message: 'Please select category',
          borderClasses: 'border-b-2 border-red-400',
          wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
          alignmentClasses: 'w-full items-center',
        });
      }
    } catch (error) {
      console.log('error in adding product to master list', error);
    }
  };

  const handleRejectProduct = async (e, product) => {
    acceptProductRequestForm.value.status = 'rejected';
    e.preventDefault();
    try {
      let acceptRequest = await updateProductRequest(
        product.uuid,
        acceptProductRequestForm
      );
      reloadAfterReject(true);
    } catch (error) {
      console.log('error in adding product to master list', error);
    }
  };

  const openRejectPopUp = (e, d) => {
    openModal(
      React.forwardRef((props, ref) => (
        <span ref={ref} className="flex h-screen w-full items-center">
          <DeletePrompt
            message={'Are you sure you want to reject this Product?'}
            deleteCallback={() => handleRejectProduct(e, d)}
            {...props}
          />
        </span>
      ))
    );
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden mx-5 border rounded-lg pb-10',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Product Name',
        identifier: 'productname',
        resolver: (d) => {
          return <div>{d.product_name}</div>;
        },
        sortable: false,
      },
      {
        label: 'Category',
        identifier: 'categoryname',
        resolver: (d) => {
          return d.status === 'pending' ? (
            <div className="w-96">
              <Select
                labelWrapperClasses="hidden"
                pickerWrapperClasses={'mt-0'}
                label=""
                dataSource={async () => await fetchCategories()}
                defaultSelected={[]}
                onChange={(v) => {
                  addProductToMasterList.value.categories[0] = v.value;
                }}
                errorText={addProductToMasterList.errors.get('categories')}
              />
            </div>
          ) : d.status === 'rejected' ? (
            <div>Cannot add category to rejected Product</div>
          ) : (
            <div>Category added already to this Product</div>
          );
        },
        sortable: false,
      },
      {
        label: 'Action',
        identifier: '',
        resolver: (d) => {
          return d.status === 'pending' ? (
            <div className="flex justify-center">
              <BlueButton
                type="submit"
                widthClasses="w-40"
                fontClasses="text-sm font-semibold"
                borderClasses="rounded-lg"
                busyText="Processing"
                busy={addProductToMasterList.busy}
                onClick={(e) => handleAcceptProduct(e, d)}
              >
                Accept
              </BlueButton>
              <div className="ml-2">
                <BlueButton
                  type="submit"
                  widthClasses="w-40 bg-red-500 hover:bg-red-600"
                  fontClasses="text-sm font-semibold"
                  borderClasses="rounded-lg"
                  busyText="Processing"
                  busy={acceptProductRequestForm.busy}
                  onClick={(e) => openRejectPopUp(e, d)}
                >
                  Reject
                </BlueButton>
              </div>
            </div>
          ) : d.status === 'rejected' ? (
            <div className="flex justify-center text-red-600">
              Product Rejected
            </div>
          ) : (
            <div className="flex justify-center text-blue-600">
              Product Accepted
            </div>
          );
        },
        sortable: false,
      },
    ],
  };

  return (
    <div className="mt-10">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async (params) => {
          try {
            let response = await fetchProductRequests();
            return {
              data: response,
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function ({ reloadAfterReject }) {
  const orchestratorRef = useRef(null);
  const [reloadAgain, setReloadAgain] = useState(false);

  const handler = async () => {
    try {
      let result = await getProductRequests();

      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }
      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  useEffect(() => {
    orchestratorRef.current.reload();
  }, [reloadAgain]);

  const emptyConfigHeaderCols = [
    {
      label: 'Product Name',
      identifier: 'productname',
      resolver: () => {},
      sortable: false,
    },
    {
      label: 'Category',
      identifier: 'categoryname',
      resolver: () => {},
      sortable: false,
    },
    {
      label: 'Action',
      identifier: '',
      resolver: () => {},
      sortable: false,
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data reloadAfterReject={(val) => setReloadAgain(val)} {...props} />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
}
