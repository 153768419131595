import React, { useRef } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import SectionLoader from '../../components/reusables/SectionLoader';
import EmptyState from '../../components/reusables/EmptyState';
import { getAllEvents } from '@nx-monorepo/services';
import formatDate from '../../utils/formatDate';

const Data = ({ response, callAction }) => {
  const tableRef = useRef(null);

  const hostedSessions = () => {
    return response.data;
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden mx-5 border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Event Topic',
        identifier: 'name',
        resolver: (d) => {
          return (
            <div
              className="text-column-blue cursor-pointer"
              onClick={() => callAction(d, 'view')}
            >
              {d?.data?.name}
            </div>
          );
        },
        sortable: false,
      },
      {
        label: 'Event Type',
        identifier: 'type',
        resolver: (d) => {
          return <div>{d?.type}</div>;
        },
        sortable: false,
      },
      {
        label: 'Created By',
        identifier: 'username',
        resolver: (d) => {
          return <div>{d?.data?.speaker_name}</div>;
        },
        sortable: false,
      },
      {
        label: 'Created On',
        identifier: 'createdAt',
        resolver: (d) => {
          return <div>{formatDate(new Date(d.createdAt), 'dmy')}</div>;
        },
        sortable: false,
      },
      {
        label: 'Registrations',
        identifier: 'registrations',
        resolver: (d) => {
          return <div>{d.registration_count ? d.registration_count : 0}</div>;
        },
        sortable: false,
      },
    ],
  };

  return (
    <div className="mt-10">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async (params) => {
          try {
            let response = await hostedSessions();
            return {
              data: response,
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function ({ callAction }) {
  const orchestratorRef = useRef(null);

  const handler = async () => {
    try {
      let result = await getAllEvents();
      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }
      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  const emptyConfigHeaderCols = [
    {
      label: 'Event Topic',
      identifier: 'event_topic',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Event Type',
      identifier: 'event_type',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Created By',
      identifier: 'username',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Created On',
      identifier: 'created_at',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Registrations',
      identifier: 'registrations',
      resolver: (d) => {},
      sortable: false,
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data callAction={callAction} {...props} />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
}
