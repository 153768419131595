import { axiosObject } from '../setup';

function publishBorrowingRates() {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/borrowing-cost-comparison-rates`;
    axiosObject
      .post(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

function publishBorrowingRatesStaging() {
  return new Promise((resolve, reject) => {
    let uri = `${process.env.NX_PUBLIC_RATES_API_URL}/borrowing-cost-comparison-rates`;
    axiosObject
      .post(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

export { publishBorrowingRates, publishBorrowingRatesStaging };
