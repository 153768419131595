import { useState } from 'react';
import Wrapper from './ProfileHeaders/Wrapper';
import Table from './ProfilesTable';
import ViewProfile from './ViewProfile';
import { useNavigate, useParams } from 'react-router-dom';

export default function () {
  let { uuid } = useParams();
  const [mode, setMode] = useState(uuid === undefined ? 'list' : 'single');
  const [action, setAction] = useState('view');
  const [itemId, setItemId] = useState(uuid === undefined ? null : uuid);
  let navigate = useNavigate();

  return (
    <Wrapper topBarActiveOption={'Profiles'}>
      <div className="flex">
        <div className="w-full overflow-hidden">
          <Table
            mode={mode}
            callAction={(d, action) => {
              setMode('single');
              setAction(action);
              setItemId(d.uuid);
              navigate(`/profiles/${d.uuid}`, {
                state: {
                  itemUuid: d?.uuid,
                },
              });
            }}
          />
        </div>

        {mode === 'single' && (
          <div className="h-96 w-2/3 bg-gray-50">
            {action === 'view' && <ViewProfile itemId={itemId} />}
          </div>
        )}
      </div>
    </Wrapper>
  );
}
