import React, { useState } from 'react';
import { BlueButton } from '@reusejs/react-buttons';
import { BaseInput } from '@reusejs/react-text-inputs';
import CloseIcon from '../assets/CloseIcon';
import useBetaForm from '@reusejs/react-form-hook';
import formatDate from '../utils/formatDate';
import { Simple as SimpleAlert } from '@reusejs/react-alerts';

import { updateTrailPeriod } from '@nx-monorepo/services';

const PopupForm = ({
  submitOnRefresh = (initialState = false) => initialState,
  subscriptionId,
  mode,
  data,
  ...props
}) => {
  const [defaultDate, setDefaultDate] = useState(formatDate(new Date(), 'ymd'));
  const [selectedPlan, setSelectedPlan] = React.useState('');
  const manageTrailPeriodForm = useBetaForm({
    status: '',
    ends_at: null,
  });

  const PlanData = {
    1: {
      plan_duration: '1 Month',
      plan_cost: '₹20,999',
      discount: '₹6,000',
      gst: `₹2,699`,
      total: `₹17,698`,
    },
    3: {
      plan_duration: '3 Months',
      plan_cost: '₹50,999',
      discount: '₹11,000',
      gst: `₹7,199`,
      total: `₹47,198`,
    },
    6: {
      plan_duration: '6 Months',
      plan_cost: '₹80,999',
      discount: '₹16,000',
      gst: `₹11,699`,
      total: `₹76,698`,
    },
    12: {
      plan_duration: '12 Months',
      plan_cost: '₹159,999',
      discount: '₹25,000',
      gst: `₹22,500`,
      total: `₹147,500`,
    },
  };

  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  const handlePlanSelect = (plan_value) => {
    setSelectedPlan(plan_value);
    const end_date = addMonths(new Date(), parseInt(plan_value));
    setDefaultDate(formatDate(new Date(end_date), 'ymd'));
    manageTrailPeriodForm.value.ends_at = end_date;
  };

  const handleSubmit = async (e) => {
    manageTrailPeriodForm.startProcessing();
    e.preventDefault();
    manageTrailPeriodForm.value.status = 'active';
    let formattedTimeStamp = Date.parse(manageTrailPeriodForm.value.ends_at);
    manageTrailPeriodForm.value.ends_at = formattedTimeStamp;
    const payload = manageTrailPeriodForm.value;
    if (selectedPlan !== '') {
      payload['data'] = PlanData[selectedPlan];
      payload['starts_at'] = Date.parse(formatDate(new Date(), 'ymd'));
    }

    try {
      await updateTrailPeriod(subscriptionId, payload);
      submitOnRefresh(true);
      submitOnRefresh(false);
      props.resolveModal(false);
      SimpleAlert({
        message: 'Trail Period Updated Successfully',
        borderClasses: 'border-b-2 border-green-400',
        wrapperClasses: 'inset-x-0 top-0 flex w-full z-[1000]',
        alignmentClasses: 'w-full items-center',
      });
      manageTrailPeriodForm.finishProcessing();
    } catch (error) {
      manageTrailPeriodForm.setErrors(error);
      return error;
    }
  };

  return (
    <form
      className="min-w-screen inline-block transform overflow-hidden rounded-lg px-4 pt-5 text-left text-[0.625rem] shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
      style={{
        fontSize: '10px',
        maxHeight: '685px',
        padding: '0',
        backgroundColor: '#FCFCFC',
        position: 'relative',
        zIndex: '0',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          width: '35px',
          height: '35px',
          borderRadius: '10px',
          backgroundColor: '#ececec',
        }}
        className="flex items-center justify-center"
        onClick={() => props.resolveModal(false)}
      >
        <CloseIcon />
      </div>

      <div style={{ padding: '40px' }}>
        <h3 className="text-base">Manage Trail Period</h3>
        <div className="mt-5">
          <div>
            <select
              className="block w-full rounded border border-gray-400 bg-white px-3 py-2 text-sm text-black shadow outline-none focus:border-blue-400 focus:ring-blue-400 dark:border-gray-50 dark:bg-black dark:text-white"
              name="Select Pricing Module"
              id="pet-select"
              onChange={(event) => {
                handlePlanSelect(event.target.value);
              }}
            >
              <option value="">Please Select a Plan</option>
              <option value="1">1 Month</option>
              <option value="3">3 Months</option>
              <option value="6">6 Months</option>
              <option value="12">12 Months</option>
            </select>
          </div>
        </div>
        <div className="flex w-full justify-center">
          <label className="py-3 text-lg">OR</label>
        </div>
        <div className="">
          <div>
            <BaseInput
              label="Select Date"
              type="date"
              defaultValue={defaultDate}
              onChange={(val) => {
                setDefaultDate(formatDate(new Date(val), 'ymd'));
                manageTrailPeriodForm.setField('ends_at', val);
              }}
              errorText={manageTrailPeriodForm.errors.get('ends_at')}
            />
          </div>
        </div>
        {selectedPlan !== '' && (
          <div className="mt-5 flex flex-col">
            <label className="text-base font-bold">Plan Details</label>
            <div className="flex w-full justify-between text-sm">
              <label>Plan Duration:</label>
              <label>{PlanData[selectedPlan]['plan_duration']}</label>
            </div>
            <div className="flex w-full justify-between text-sm">
              <label>Plan Cost:</label>
              <label>{PlanData[selectedPlan]['plan_cost']}</label>
            </div>
            <div className="flex w-full justify-between text-sm">
              <label>Plan discount:</label>
              <label className="text-green-500">
                {`-${PlanData[selectedPlan]['discount']}`}
              </label>
            </div>
            <div className="flex w-full justify-between text-sm">
              <label>GST:</label>
              <label>{PlanData[selectedPlan]['gst']}</label>
            </div>
            <div className="flex w-full justify-between text-sm">
              <label>Total:</label>
              <label>{PlanData[selectedPlan]['total']}</label>
            </div>
          </div>
        )}
      </div>

      <div
        className="flex items-center justify-end"
        style={{
          height: '60px',
          marginTop: '',
          borderTop: '1px solid #EDEDED',
          backgroundColor: '#FFF',
        }}
      >
        <div className="mr-3 cursor-pointer hover:text-blue-600 md:w-auto">
          <BlueButton
            type="submit"
            widthClasses="w-30"
            fontClasses="text-sm font-semibold"
            borderClasses="rounded-lg"
            onClick={(e) => {
              console.log(e);
              handleSubmit(e);
            }}
          >
            Save
          </BlueButton>
        </div>
      </div>
    </form>
  );
};

export default PopupForm;
