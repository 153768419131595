import { Loader } from '@reusejs/react-loaders';

function SectionLoader() {
  return (
    <div className="flex items-center justify-center h-96">
      <Loader />
    </div>
  );
}

export default SectionLoader;
