import React, { useRef, useState, memo, useMemo } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { PencilIcon, TrashIcon, EyeIcon } from '@heroicons/react/solid';
import { classNames } from '@reusejs/react-utils';
import DeletePrompt from '../components/reusables/DeletePrompt';
import { Simple } from '@reusejs/react-modals';
import PopupForm from './PopupForm';
import { useNavigate } from 'react-router-dom';
import SectionLoader from '../components/reusables/SectionLoader';
import EmptyState from '../components/reusables/EmptyState';
import moment from 'moment';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import { deleteMarketView, getMarketViews } from '@nx-monorepo/services';
import { getPostFixDateString } from '../utils/getPostFixDateString';

const Data = ({ response, onDelete, submitOnRefresh = () => {} }) => {
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const openModal = async (item) => {
    await Simple({
      ModalContent: item,
    });
  };

  const marketViews = () => {
    return response.data;
  };

  const handleDelete = async (id) => {
    try {
      const { message } = await deleteMarketView(id);
      if (message === 'success') {
        onDelete(true);
        onDelete(false);
      }
    } catch (error) {
      return error;
    }
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Title',
        identifier: 'title',
        resolver: (d) => {
          return (
            <div>
              {' '}
              {`${d?.title} - ${new Date(
                d?.created_at
              ).getDate()}${getPostFixDateString(d)} ${moment(
                new Date(d?.created_at),
                'DD-MM-YYYY'
              ).format('MMMM')}`}
            </div>
          );
        },
        sortable: false,
      },
      {
        label: 'Status',
        identifier: 'status',
        resolver: (d) => {
          return <div>{d.status}</div>;
        },
        sortable: false,
      },
      {
        label: 'Actions',
        identifier: '',
        resolver: (d) => {
          return (
            <div className=" flex justify-center rounded-md py-1 pr-2">
              <div className="cursor-pointer border-r px-2">
                <PencilIcon
                  className={classNames('h-4 w-4 text-gray-400')}
                  onClick={() =>
                    openModal(
                      React.forwardRef((props, ref) => {
                        return (
                          <span
                            ref={ref}
                            className="flex h-screen w-full items-center justify-center"
                          >
                            <PopupForm
                              submitOnRefresh={(cond) => submitOnRefresh(cond)}
                              mode={'edit'}
                              data={d}
                              {...props}
                            />
                          </span>
                        );
                      })
                    )
                  }
                />
              </div>
              <div className="cursor-pointer border-r px-2">
                <EyeIcon
                  className={classNames('h-4 w-4 text-gray-400')}
                  onClick={() => navigate(`/marketing-views/${d?.uuid}`)}
                />
              </div>
              <div className="cursor-pointer px-2">
                <TrashIcon
                  onClick={() => {
                    openModal(
                      React.forwardRef((props, ref) => (
                        <span
                          ref={ref}
                          className="flex h-screen w-full items-center"
                        >
                          <DeletePrompt
                            message={
                              'Are you sure you want to delete this post?'
                            }
                            deleteCallback={() => handleDelete(d?.uuid)}
                            {...props}
                          />
                        </span>
                      ))
                    );
                  }}
                  className={classNames('h-4 w-4 text-red-400')}
                />
              </div>
            </div>
          );
        },
        sortable: false,
      },
    ],
  };

  return (
    <DataTable
      ref={tableRef}
      config={config}
      defaultSortColumn={0}
      perPage={5}
      dataSource={async () => {
        const response = await marketViews();
        return {
          data: response,
          pagination: {
            total: 0,
          },
        };
      }}
    />
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

const Table = ({ mode, callAction, submitOnRefresh }) => {
  const orchestratorRef = useRef(null);
  const [reload, setReload] = useState(false);

  const handler = async () => {
    try {
      let result = await getMarketViews();

      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }

      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  useMemo(() => {
    if (reload || submitOnRefresh) {
      orchestratorRef.current.reload();
    }
  }, [reload, submitOnRefresh]);

  const emptyConfigHeaderCols = [
    {
      label: 'Title',
      identifier: 'title',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Status',
      identifier: 'status',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Actions',
      identifier: '',
      resolver: (d) => {},
      sortable: false,
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data
              mode={mode}
              callAction={callAction}
              onDelete={(cond) => setReload(cond)}
              submitOnRefresh={(cond) => setReload(cond)}
              {...props}
            />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
};

export default memo(Table);
