import { axiosObject } from '../../setup';

function getProductRequests() {
    return new Promise((resolve, reject) => {
        let uri = `${process.env.NX_PUBLIC_ONBOARDING_API_URL}/product-request`;

        axiosObject
            .get(uri)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export { getProductRequests };
