import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const constraints = {
  tags: {
    presence: {
      allowEmpty: false,
      message: '^Please enter query category',
    },
  },
  title: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your question',
    },
  },

  country: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your country',
    },
  },
  period: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your period',
    },
  },
};

function askAnything(payload) {
  let apiPayload = {};
  apiPayload['anonymous'] = payload.anonymous;
  apiPayload['title'] = payload.title;
  apiPayload['tags'] = payload.tags;
  apiPayload['meta'] = { country: payload.country, period: payload.period };
  return new Promise((resolve, reject) => {
    let jsValidationErrors;
    jsValidationErrors = baseValidator(payload, constraints);
    if (jsValidationErrors === true) {
      let uri = '/backend-discuss/queries';
      axiosObject
        .post(uri, apiPayload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          console.log('Errors', JSON.stringify(errors));
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function askQuestion({ payload }) {
  return new Promise((resolve, reject) => {
    var jsValidationErrors;
    jsValidationErrors = baseValidator(payload, constraints);

    if (jsValidationErrors === true) {
      let uri = '/backend-discuss/queries';

      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function getQueriesByTags(tags = '') {
  return new Promise((resolve, reject) => {
    let uri = `/backend-discuss/queries?tags=${tags}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function myPostedQueries() {
  return new Promise((resolve, reject) => {
    let uri = `/backend-discuss/posted-queries`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getQueryCategories() {
  return new Promise((resolve, reject) => {
    let uri = `/backend-discuss/tags`;

    axiosObject
      .get(uri)
      .then((response) => {
        let categories = response.data.map((item) => {
          return {
            label: item.name,
            value: item.uuid,
          };
        });

        resolve(categories);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function myAnsweredQueries() {
  return new Promise((resolve, reject) => {
    let uri = `/backend-discuss/answered-queries`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function getAllLatestQueries(tags = '') {
  return new Promise((resolve, reject) => {
    let uri = `/backend-discuss/queries`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function answerAQuery(payload) {
  console.log('PAYLOADDDD', payload);
  return new Promise((resolve, reject) => {
    let jsValidationErrors;
    jsValidationErrors = true;
    if (jsValidationErrors === true) {
      let uri = '/backend-discuss/responses';
      axiosObject
        .post(uri, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          console.log('Errors', JSON.stringify(errors));
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export {
  askAnything,
  askQuestion,
  getQueriesByTags,
  myPostedQueries,
  getQueryCategories,
  myAnsweredQueries,
  getAllLatestQueries,
  answerAQuery,
};
