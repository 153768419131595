import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={18}
      height={19}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0C6.243 0 4 2.243 4 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 8C7.346 8 6 6.654 6 5s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm9 11v-1c0-3.859-3.141-7-7-7H7c-3.86 0-7 3.141-7 7v1h2v-1c0-2.757 2.243-5 5-5h4c2.757 0 5 2.243 5 5v1h2z"
        fill="#1E56FF"
      />
    </svg>
  );
}

export default SvgComponent;
