import { useEffect, useState } from 'react';
import { getFile } from '@nx-monorepo/services';
import { classNames } from '@reusejs/react-utils';

export default function SingleEvent({
  eventDetails,
  eventProfilePic,
  dateAndTime,
}) {
  const [profilePicURL, setProfilePicURL] = useState(null);

  const getProfilePicURL = async () => {
    if (
      eventProfilePic &&
      (eventProfilePic !== '' || undefined || null || 'link')
    ) {
      let response = await getFile(eventProfilePic);
      setProfilePicURL(response?.download_url);
    } else {
      setProfilePicURL('https://saltadorarchitects.com/images/team/01.jpg');
    }
  };

  useEffect(() => {
    getProfilePicURL();
  }, []);

  return (
    <div className="mx-5 mt-10 overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="rounded-lg border-t border-gray-200 px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Event Details
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Session and speaker details.
        </p>
      </div>

      <div className="border-t border-gray-200">
        <dl>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              <img
                class="inline-block h-28 w-28 rounded-full ring-2 ring-white"
                src={profilePicURL}
                alt={profilePicURL}
              ></img>
            </dt>
          </div>
        </dl>
      </div>

      {Object.entries(eventDetails).map((arr, index) => {
        return (
          <div
            key={index}
            className={classNames(
              index === 0 ? 'border-t border-gray-200' : ''
            )}
          >
            <dl>
              <div
                className={classNames(
                  index % 2 !== 0
                    ? 'bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'
                    : 'bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'
                )}
              >
                <dt className="text-sm font-medium text-gray-500">{arr[0]}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {arr[1]}
                </dd>
              </div>
            </dl>
          </div>
        );
      })}

      <div className="border-t border-gray-200">
        <dl>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Session Timings
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {dateAndTime?.length > 0 ? (
                dateAndTime.map((item) => {
                  return (
                    <div className="flex flex-col pb-3">
                      <p>Date: {item.date}</p>
                      <p>Time: {item.time}</p>
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col pb-3">
                  <p>Date: N/A</p>
                  <p>Time: N/A</p>
                </div>
              )}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
