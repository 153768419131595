import React, { useRef } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import SectionLoader from '../../components/reusables/SectionLoader';
import EmptyState from '../../components/reusables/EmptyState';
import { getEnqueries } from '@nx-monorepo/services';

const Data = ({ response, callAction }) => {
  const tableRef = useRef(null);

  const corporateEnqueries = () => {
    return response.data;
  };

  const config = {
    tableWrapperClasses: 'overflow-scroll mx-5 border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Registered User',
        identifier: 'name',
        resolver: (d) => {
          return (
            <div
              className="text-column-blue cursor-pointer"
              onClick={() => callAction(d, 'view')}
            >
              {d?.data?.registered_user ? d?.data?.registered_user : '-'}
            </div>
          );
        },
        sortable: false,
      },
      {
        label: 'Email',
        identifier: 'email',
        resolver: (d) => {
          return (
            <div
              className="cursor-pointer"
              onClick={() => callAction(d, 'view')}
            >
              {d?.data?.email ? d?.data?.email : '-'}
            </div>
          );
        },
        sortable: false,
      },
      {
        label: 'Mobile',
        identifier: 'mobile',
        resolver: (d) => {
          return <div>{d?.data?.mobile ? d?.data?.mobile : '-'}</div>;
        },
        sortable: false,
      },
      {
        label: 'Event Topic',
        identifier: 'topic',
        resolver: (d) => {
          return (
            <div>{d?.data?.topic?.label ? d?.data?.topic?.label : '-'}</div>
          );
        },
        sortable: false,
      },
      {
        label: 'Date',
        identifier: 'date',
        resolver: (d) => {
          return (
            <div>{d?.data?.selected_date ? d?.data?.selected_date : 'N/A'}</div>
          );
        },
        sortable: false,
      },
      {
        label: 'Time',
        identifier: 'Time',
        resolver: (d) => {
          return (
            <div>{d?.data?.selected_time ? d?.data?.selected_time : 'N/A'}</div>
          );
        },
        sortable: false,
      },
      {
        label: 'Session Fee',
        identifier: 'fee',
        resolver: (d) => {
          return (
            <div>{d?.data?.session_fee ? d?.data?.session_fee : 'N/A'}</div>
          );
        },
        sortable: false,
      },
      {
        label: 'Payment Status',
        identifier: 'payment_status',
        resolver: (d) => {
          return (
            <div>
              {d?.data?.payment_status ? d?.data?.payment_status : 'N/A'}
            </div>
          );
        },
        sortable: false,
      },
    ],
  };

  return (
    <div className="mt-10">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async (params) => {
          try {
            let response = await corporateEnqueries();
            return {
              data: response,
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function ({ mode, callAction }) {
  const orchestratorRef = useRef(null);

  const handler = async () => {
    try {
      let result = await getEnqueries();
      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }
      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  const emptyConfigHeaderCols = [
    {
      label: 'Registered User',
      identifier: 'username',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Email',
      identifier: 'email',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Mobile',
      identifier: 'mobile',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Event Topic',
      identifier: 'topic',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Date',
      identifier: 'date',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Time',
      identifier: 'Time',
      resolver: (d) => {},
      sortable: false,
    },
    {
      label: 'Session Fee',
      identifier: 'fee',
      resolver: (d) => {},
      sortable: false,
    },
  ];

  return (
    <DataLoadOrchestrator
      handler={handler}
      ref={orchestratorRef}
      components={{
        data: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <Data mode={mode} callAction={callAction} {...props} />
          </span>
        )),
        loading: SectionLoader,
        empty: React.forwardRef((props, ref) => (
          <span ref={ref}>
            <EmptyState tableHeadColumns={emptyConfigHeaderCols} {...props} />
          </span>
        )),
        error: ErrorState,
      }}
    />
  );
}
