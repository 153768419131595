import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={15}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 1.41L13.09 0 7.5 5.59 1.91 0 .5 1.41 6.09 7 .5 12.59 1.91 14 7.5 8.41 13.09 14l1.41-1.41L8.91 7l5.59-5.59z"
        fill="#263C7E"
      />
    </svg>
  );
}

export default SvgComponent;
