import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import App from '@nx-monorepo/web-screens';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { webStore, webPersistor } from '@nx-monorepo/store';

ReactDOM.render(
  <StrictMode>
    <Provider store={webStore}>
      <PersistGate loading={null} persistor={webPersistor}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
