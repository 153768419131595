import baseValidator from '../baseValidator';
import { axiosObject } from '../setup';

const emailConstraints = {
  username: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your email address',
    },
    email: {
      message: '^Please enter a valid email address',
    },
  },
};

const mobileConstraints = {
  mobile_number: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your mobile number',
    },
  },
  username: {
    format: {
      pattern: '^\\+[1-9]\\d{1,14}$',
      message: '^Please enter mobile in +<isd><number> format',
    },
  },
};

function forgotPassword(payload) {
  return new Promise((resolve, reject) => {
    var jsValidationErrors;
    var apiPayload = {};

    if (payload['mode'] === 'email') {
      payload['username'] = payload['email'];

      jsValidationErrors = baseValidator(payload, emailConstraints);
      apiPayload = {
        mode: 'email',
        username: payload['email'],
      };
    }

    if (payload['mode'] === 'mobile') {
      payload['username'] = payload['mobile_code'] + payload['mobile_number'];

      jsValidationErrors = baseValidator(payload, mobileConstraints);
      apiPayload = {
        mode: 'mobile',
        username: payload['mobile_code'] + payload['mobile_number'],
      };
    }

    if (jsValidationErrors === true) {
      let uri = `${process.env.NX_PUBLIC_AUTH_API_URL}/request-reset-password`;
      axiosObject
        .post(uri, apiPayload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      if (Object.keys(jsValidationErrors).includes('mobile_number')) {
        jsValidationErrors['username'] = jsValidationErrors['mobile_number'];
      }
      reject(jsValidationErrors);
    }
  });
}
export { forgotPassword };
